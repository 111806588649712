import { useEffect, useState } from 'react';
import { useTableClick } from '../../../../components';
import { CategoryResponse } from '../../../types';
import { useViewSwitch } from '../../hooks';
import { RecipeInfinityView } from './recipe-infinity-view';
import { RecipesEnhancedTableView } from './recipes-enhanced-table-view';

const RecipesTable: React.FC = () => {
  const [category, setCategory] = useState<CategoryResponse | undefined>(undefined);

  const { view } = useViewSwitch();
  const { setSelected, handleClick, selected } = useTableClick();

  useEffect(() => {
    setSelected([]);
  }, [setSelected]);

  return view === 'table' ? (
    <RecipesEnhancedTableView
      category={category}
      selected={selected}
      setCategory={setCategory}
      handleOnSelect={handleClick}
    />
  ) : (
    <RecipeInfinityView category={category} setCategory={setCategory} />
  );
};

export default RecipesTable;
