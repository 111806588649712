import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoRow, KQNIconButton, Optional } from '../../../components';
import { NavigationPaths } from '../../../constants';
import { CategoryResponse } from '../../types';
import { DeleteCategoryModal, UpdateCategoriesModal } from '../components/modals';

type Props = {
  category: CategoryResponse;
};

const CategorySummary: React.FC<Props> = ({ category }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { name, nameDe, description } = category;
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);

  const handleOnEditClose = () => {
    setOpenEdit(false);
  };

  return (
    <Paper sx={{ minWidth: '500px', p: 3, height: 'fit-content' }}>
      <Stack direction='column' gap={3} width='100%'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography>CATEGORY PROFILE</Typography>
          <Stack direction='row' justifyContent='flex-end' justifyItems='center' gap={2}>
            <KQNIconButton onClick={() => setOpenEdit(true)}>
              <EditOutlinedIcon sx={{ color: 'kqn.darkGray', fontSize: '27px' }} />
            </KQNIconButton>
            <KQNIconButton onClick={() => setOpenDelete(true)}>
              <DeleteOutlineIcon sx={{ color: 'kqn.coral', fontSize: '27px' }} />
            </KQNIconButton>
          </Stack>
        </Stack>
        <Stack direction='column' gap={3} sx={{ color: 'kqn.darkGray' }}>
          <InfoRow label='Name (EN)' value={name} />
          <InfoRow label='Name (DE)' value={nameDe} />
          <InfoRow label='Description' value={description} />
        </Stack>
      </Stack>
      <Optional condition={openEdit}>
        <UpdateCategoriesModal category={category} isOpen={openEdit} onClose={handleOnEditClose} />
      </Optional>{' '}
      <Optional condition={openDelete}>
        <DeleteCategoryModal
          categoryId={category.id}
          isOpen={openDelete}
          onClose={() => setOpenDelete(false)}
          onSucess={() => {
            navigate(NavigationPaths.CATEGORIES);
          }}
        />
      </Optional>
    </Paper>
  );
};

export default CategorySummary;
