import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React from 'react';

type Props = {
  text: string;
  onClick: () => void;
};

const CreateButton: React.FC<Props> = ({ text, onClick }) => {
  return (
    <Button
      variant='contained'
      data-testid='kqn-create-button'
      startIcon={<AddIcon />}
      sx={{
        bgcolor: 'kqn.cooper',
        borderRadius: 2,
        whiteSpace: 'nowrap',
        color: '#fff',
        ':hover': { bgcolor: 'kqn.gray', color: 'kqn.lightCooper' },
        height: '40px',
        px: 2,
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default CreateButton;
