import { useCallback } from 'react';
import { useGroupStore, useRecipeActions } from '../../../store';

export const useResetState = () => {
  const { setInitialState: resetState, resetAccessories, resetNutrients } = useRecipeActions();
  const { setInitialState: resetGroupState } = useGroupStore.use.actions();

  const resetAllState = useCallback(() => {
    resetState();
    resetGroupState();
    resetAccessories();
    resetNutrients();
  }, [resetState, resetGroupState, resetAccessories, resetNutrients]);

  return { resetAllState };
};
