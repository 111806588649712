import { Grid, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Optional } from '../../../../../../../../../../../../components';
import { useMealState } from '../../../../../../../../../../../../store/meal-store';
import presets from '../../../../../preset/components/preset/presets';
import { MealSteps } from '../../../meal-steps';
import MealComposer from '../../meal-composer';
import { MealCard, NewMealCard } from '../meal-card';

type Props = {
  recipeId: string;
  isLoading?: boolean;
};

const MealCards: React.FC<Props> = ({ recipeId, isLoading }) => {
  const meals = useMealState();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);
  const meal = useMemo(() => meals.find((m) => m.id === selected), [meals, selected]);

  const handleStepClick = (stepId: string) => {
    setSelected((prev) => (prev === stepId ? null : stepId));
  };

  return (
    <Stack direction='column'>
      <Grid container spacing={2}>
        {meals?.map((m, idx) => {
          return (
            <Grid item key={m.id}>
              <MealCard
                isActive={selected === m.id}
                recipeId={recipeId}
                meal={m}
                ordinal={idx}
                onClick={handleStepClick}
              />
            </Grid>
          );
        })}
        <Grid item>
          <NewMealCard onClick={() => setIsOpen(true)} />
        </Grid>
      </Grid>
      <Optional condition={!presets.length}>
        <Typography variant='h6' align='center'>
          Please select a meal type step to add grill steps to it. You can add multiple grill steps
          to a meal type step.
        </Typography>
      </Optional>
      <Optional condition={meal}>
        <MealSteps meal={meal!} />
      </Optional>
      <MealComposer
        recipeId={recipeId}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </Stack>
  );
};
export default MealCards;
