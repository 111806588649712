import { Typography } from '@mui/material';
import { ConfirmModal } from '../../../../components';
import { useRemoveRecipe } from '../../mutations';

interface Props {
  isOpen: boolean;
  title?: string;
  onClose: (event?: any) => void;
  recipeId?: string;
  message?: string;
  isPermanent?: boolean;
}

const DeleteRecipeModal: React.FC<Props> = ({
  isOpen,
  onClose,
  recipeId,
  title,
  message,
  isPermanent,
}) => {
  const { handleRemoveRecipe, isLoading } = useRemoveRecipe();
  const text =
    message || isPermanent
      ? 'This action will permanently remove the recipe and cannot be undone. Confirm to delete permanently.'
      : 'Are you sure you want to delete this recipe? This action can be undone from the restore menu. Confirm to delete.';
  const titleText = title || isPermanent ? 'DELETE RECIPE PERMANENTLY' : 'DELETE RECIPE';

  const handleDelete = async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!recipeId) return;
    event?.stopPropagation();

    await handleRemoveRecipe({ recipeId, onSuccess: onClose, isPermanent });
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCofirm={handleDelete}
      title={titleText}
      isProcessing={isLoading}
    >
      <Typography sx={{ pb: 2 }}>{text}</Typography>
    </ConfirmModal>
  );
};

export default DeleteRecipeModal;
