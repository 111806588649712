import { Backdrop, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import React from 'react';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: '600px',
  transform: 'translateX(-50%) translateY(calc(-50% - .5px))',
  maxHeight: '90vh',
  borderRadius: '8px',

  '@media (max-width: 1700px)': {
    maxWidth: '80%',
  },

  '@media (width > 1700px)': {
    maxWidth: '90%',
  },

  '@media (width > 2200px)': {
    maxWidth: '70%',
  },
};

interface ModalProps {
  isOpen: boolean;
  onClose: (event: any) => void;
  children: React.ReactNode;
}

export default function ModalPopup({ children, isOpen, onClose }: ModalProps) {
  return (
    <Modal
      data-testid='popup-modal'
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      sx={{ '&  :focus': { outline: 'none' } }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Fade in={isOpen}>
        <Box
          sx={style}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}
