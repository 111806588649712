import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Theme,
  useTheme,
} from '@mui/material';
import { durations, useErrorContext } from '../../../../context';

type Props = {
  cookingTime: string[];
  setValue: (event: SelectChangeEvent<any>) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: '8px',
    },
  },
};

function getStyles(value: string, values: readonly string[], theme: Theme) {
  return {
    fontWeight:
      values.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const DurationFilter: React.FC<Props> = ({ cookingTime, setValue }) => {
  const theme = useTheme();
  const { error } = useErrorContext();

  return (
    <Stack gap={1} width='100%'>
      <InputLabel sx={{ color: 'kqn.darkerGray', borderColor: 'kqn.darkerGray' }}>
        Duration
      </InputLabel>
      <FormControl sx={{ width: '100%' }} error={!!error.get('cookingTime-cookingTime')}>
        <Select
          defaultValue={[]}
          multiple
          value={cookingTime}
          onChange={setValue}
          name='cookingTime'
          input={
            <OutlinedInput
              sx={{
                color: 'kqn.darkerGray',
                borderRadius: '8px',

                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  color: 'kqn.lightGray',
                  borderColor: 'kqn.cooper',
                },
                '& .MuiOutlinedInput-input': {
                  py: '8px !important',
                },
              }}
            />
          }
          renderValue={(selected) => (
            <Stack direction='row' flexWrap='wrap' gap={0.5}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  sx={{ bgcolor: 'kqn.cooper', color: 'kqn.white', height: '24px' }}
                />
              ))}
            </Stack>
          )}
          MenuProps={{
            ...MenuProps,
            sx: {
              '&& .Mui-selected': {
                backgroundColor: 'kqn.ultraLightCooper',
              },
            },
          }}
        >
          {durations.map((time) => (
            <MenuItem key={time.value} value={time.value} style={getStyles(time.value, [], theme)}>
              {time.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default DurationFilter;
