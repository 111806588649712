import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { koqoonApi } from '../../../../../../config';
import { Paths } from '../../../../../../constants';
import { useAuthentication } from '../../../../../../context';

export const useResetPassword = () => {
  const { signOut, user } = useAuthentication();
  const [searchParams, setSearchParams] = useSearchParams();

  const mutation = useMutation(({ password, token }: { password: string; token?: string }) =>
    koqoonApi.post('/auth/reset-password', { password, token }),
  );

  const handleResetPassword = async (password: string, token?: string) => {
    mutation.mutate(
      { password, token },
      {
        onSuccess: () => {
          if (user) {
            signOut({ redirectPath: Paths.LOGIN });
          }
          searchParams.set('isResetSuccesful', 'true');
          setSearchParams(searchParams);
        },
        onError: (err: any) => {
          toast.error(err);
        },
      },
    );
  };

  return { handleResetPassword, isLoading: mutation.isLoading, mutation };
};
