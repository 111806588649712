import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { KQNIconButton } from '../../../../../../../../../components';
import { GrillStep } from '../../../../../../../../types';
import BurnerTimerView from './burner-timer-view';
import PresetInfoView from './preset-info-view';

type Props = {
  preset: GrillStep;
  onEdit: () => void;
};

const InfoStepView: React.FC<Props> = ({ preset, onEdit }) => {
  const { ordinal } = preset;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: preset.id || '',
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      display='flex'
      flexDirection='column'
      sx={{
        overflowY: 'auto',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.40)',
        borderRadius: '2px',
        bgcolor: 'kqn.white',
        px: 3,
      }}
    >
      <Stack direction='row' gap={3} sx={{ width: '100%', py: 1.5 }}>
        <Stack direction='column' width='100%' gap={2}>
          <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>
            <Stack direction='row' gap={1} alignItems='center'>
              <DragIndicatorIcon {...listeners} sx={{ color: 'kqn.darkerGray', cursor: 'grab' }} />
              <Typography variant='body2' sx={{ fontWeight: 600, color: 'kqn.darkerGray' }}>
                Step {ordinal}
              </Typography>
            </Stack>
            <Stack direction='row' gap={1}>
              <KQNIconButton onClick={onEdit}>
                <ModeEditOutlineOutlinedIcon sx={{ color: 'kqn.cooper' }} />
              </KQNIconButton>
            </Stack>
          </Stack>
          <PresetInfoView preset={preset} />
          <Stack direction='row' justifyContent='space-between' gap={2}>
            <Stack sx={{ width: '100%' }} />
            <BurnerTimerView title='Duration, s' durations={preset.durations} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default InfoStepView;
