import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { categoriesService } from '../services/categories-service';

interface Data {
  categoryId: string;
  category: { name: string; nameDe: string; description?: string };
}
interface Props {
  onSuccess?: () => void;
}

export const useUpdateCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<{ id: string }, unknown, Data>(
    ({ category, categoryId }) => categoriesService.updateCategory({ categoryId, category }),
    {},
  );

  const handleUpdateCategory = async ({ category, categoryId, onSuccess }: Data & Props) => {
    mutation.mutate(
      { categoryId, category },
      {
        onSuccess: () => {
          queryClient.refetchQueries(queries.categories.filter._def);
          queryClient.refetchQueries(queries.categories.details._def);
          toast.success('Category updated successfully');
          onSuccess?.();
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    mutation,
    handleUpdateCategory,
    isLoading: mutation.isLoading,
  };
};
