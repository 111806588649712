import { Box, Divider, Typography } from '@mui/material';
import { ModalPopup } from '../../../../components';
import UpdateUserForm from '../../../../components/update-user-form/update-user-form';
import { PageableUser } from '../../../types';

interface Props {
  user: PageableUser;
  isOpen: boolean;
  onClose: () => void;
}

const UpdateUserModal: React.FC<Props> = ({ user, isOpen, onClose }) => {
  if (!user) return null;

  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <Box border={0} sx={{ minWidth: '600px', bgcolor: 'background.paper', p: 3, boxShadow: 24 }}>
        <Typography variant='h6' sx={{ color: 'kqn.cooper', pb: 2 }}>
          EDIT USER
        </Typography>
        <Divider sx={{ borderColor: 'kqn.lightCooper' }} />
        <UpdateUserForm user={user} onClose={onClose} />
      </Box>
    </ModalPopup>
  );
};

export default UpdateUserModal;
