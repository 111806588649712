import { Table, TableContainer } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EnhancedTableHead, Pagination, useTableSort } from '../../../../components';
import { useRecipesFilter } from '../../../../context';
import { fetchConfig, useDebounceValue, useHandleFilterParams } from '../../../../shared';
import { queries } from '../../../queries';
import { CategoryResponse, RecipeBaseModel, RecipeHeadCells } from '../../../types';
import { mapRecipeResponseToObject } from './mappers';
import RecipesEnhancedTableBody from './recipes-enhanched-table-body';
import recipesHeadCells from './recipes-head-cells';

interface Props {
  category?: CategoryResponse;
  selected: string[];
  onRecipeSelect: (recipe?: RecipeBaseModel) => void;
  handleOnSelect: (event: React.MouseEvent<unknown>, name: string) => void;
}

const RecipeEnhancedTableDataset: React.FC<Props> = ({
  category,
  onRecipeSelect,
  selected,
  handleOnSelect,
}) => {
  const { orderBy, page, perPage, sortBy } = useHandleFilterParams();
  const { handleRequestSort, order, orderBy: orderByHeadCell } = useTableSort<RecipeHeadCells>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { query: filterQuery, data: filterData } = useRecipesFilter();
  const {
    category: selectedCategory,
    complexity,
    cookingStart,
    cookingEnd,
    mealType,
    state,
  } = filterData;
  const debouncedQuery = useDebounceValue(filterQuery);

  const { data } = useQuery({
    ...queries.recipes.infinityFilter(
      {
        query: debouncedQuery,
        perPage,
        page,
        orderBy,
        sortBy,
        complexity,
        category: selectedCategory,
        mealType,
        state,
        cookingStart,
        cookingEnd,
      },
      category,
    ),
  });

  useEffect(() => {
    if (selected.length > 0) {
      onRecipeSelect(data?.data.find((r) => r.id === selected[0]));
    }
  }, [data?.data, onRecipeSelect, selected, selected.length]);

  useEffect(() => {
    if (debouncedQuery === '') return;

    searchParams.set('page', '1');
    searchParams.set('perPage', String(perPage));
    searchParams.set('orderBy', orderBy);
    setSearchParams(searchParams);
  }, [debouncedQuery, orderBy, perPage, searchParams, setSearchParams]);

  const handleChangePage = (_: unknown, newPage: number) => {
    searchParams.set('page', String(newPage + 1));
    searchParams.set('perPage', String(perPage));
    searchParams.set('orderBy', orderBy);
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fetchConfig.rowsPerPageOptions.includes(Number(event.target.value))) {
      searchParams.set('page', '1');
      searchParams.set('perPage', String(event.target.value));
      searchParams.set('orderBy', orderBy);
      setSearchParams(searchParams);
      return;
    }

    setSearchParams({ page: '1', perPage: '50', orderBy });
  };

  useEffect(() => {
    if (debouncedQuery === '') return;

    searchParams.set('page', '1');
    searchParams.set('perPage', String(perPage));
    searchParams.set('orderBy', orderBy);
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  if (!data) return null;
  const recipesResponse = mapRecipeResponseToObject(data);
  const {
    meta: { currentPage, total },
  } = recipesResponse;
  const pageProp = currentPage > 0 ? currentPage - 1 : 0;

  return (
    <>
      <TableContainer
        sx={{ maxHeight: 'calc(100vh - 290px)', borderRadius: '2px', width: 'fit-content', mt: 2 }}
      >
        <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
          <EnhancedTableHead
            headCells={recipesHeadCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <RecipesEnhancedTableBody
            data={recipesResponse}
            perPage={perPage}
            order={order}
            orderBy={orderByHeadCell}
            handleOnSelect={handleOnSelect}
            selected={selected}
          />
        </Table>
      </TableContainer>
      <Pagination
        total={total}
        perPage={perPage}
        page={pageProp}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default RecipeEnhancedTableDataset;
