import { useMemo } from 'react';
import { useGeneralValidation, useIngredientsValidation } from '../components';

export const useRecipeEditor = () => {
  const { hasGeneralChanges } = useGeneralValidation();
  const { hasIngredientsChanges } = useIngredientsValidation();

  const hasUnsavedChanges = useMemo(() => {
    if (hasGeneralChanges || hasIngredientsChanges) {
      return true;
    }

    return false;
  }, [hasGeneralChanges, hasIngredientsChanges]);

  return {
    hasUnsavedChanges,
  };
};
