import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ApronIcon, GrillLogo, IngredientsIcon } from '../../../../shared';
import { RecipeMetadata } from '../../../types';

type Props = { metadata: RecipeMetadata | null };

const Icon = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        p: 1,
        height: '32px',
        bgcolor: 'kqn.gray',
        color: 'kqn.darkerGray',
        borderRadius: '2px',
      }}
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      {children}
    </Box>
  );
};

const ImportStatus = ({ state }: { state: boolean }) => {
  if (state)
    return (
      <Stack
        direction='row'
        justifyContent='flex-end'
        gap={1}
        sx={{ color: 'kqn.green' }}
        alignItems='center'
        width='100%'
      >
        <Typography variant='caption'>Complete</Typography>
        <CheckCircleOutlineOutlinedIcon className='icon' />
      </Stack>
    );

  return (
    <Stack
      direction='row'
      justifyContent='flex-end'
      gap={1}
      sx={{ color: 'kqn.orange' }}
      alignItems='center'
      width='100%'
    >
      <Typography variant='caption'>No Data</Typography>
      <ReportProblemOutlinedIcon className='icon' />
    </Stack>
  );
};

const ImportAvailability: React.FC<Props> = ({ metadata }: Props) => {
  const [availableData, setAvailableData] = useState({
    general: false,
    ingredients: false,
    preparataions: false,
    meals: false,
  });

  useEffect(() => {
    if (metadata) {
      setAvailableData({
        general: !!metadata.general,
        ingredients: !!metadata.ingredients.groups.length,
        preparataions: !!metadata.preparations.length,
        meals: !!metadata.meals.length,
      });
    }
  }, [metadata]);

  if (!metadata) return null;

  return (
    <Stack direction='row' gap={3}>
      <Stack direction='column' gap={1}>
        <Typography variant='body2'>Image</Typography>
        <img
          src={metadata.general.imageUrl || metadata.general.imageUri}
          alt='recipe'
          style={{
            width: '250px',
            borderRadius: '2px',
            height: '150px',
          }}
        />
      </Stack>
      <Stack direction='column' gap={1} width='100%' justifyContent='space-between'>
        <Typography variant='body2'>Data Import Status</Typography>
        <Stack direction='row' gap={2} alignItems='center'>
          <Icon>
            <ErrorOutlineIcon className='icon' />
          </Icon>
          <Typography width='100%'>GENERAL</Typography>
          <ImportStatus state={availableData.general} />
        </Stack>
        <Stack direction='row' gap={2} alignItems='center'>
          <Icon>
            <IngredientsIcon className='' />
          </Icon>
          <Typography width='100%'>INGREDIENTS</Typography>
          <ImportStatus state={availableData.ingredients} />
        </Stack>
        <Stack direction='row' gap={2} alignItems='center'>
          <Icon>
            <ApronIcon className='icon' />
          </Icon>
          <Typography width='100%'>PREPARATION</Typography>
          <ImportStatus state={availableData.preparataions} />
        </Stack>
        <Stack direction='row' gap={2} alignItems='center'>
          <Icon>
            <GrillLogo className='icon' />
          </Icon>
          <Typography width='100%'>GRILLING</Typography>
          <ImportStatus state={availableData.meals} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ImportAvailability;
