import { Divider, Skeleton, Stack } from '@mui/material';
import { createId } from '@paralleldrive/cuid2';

interface Props {
  count?: number;
}

const GroupSkeleton: React.FC<Props> = ({ count = 1 }) => {
  return (
    <>
      {Array.from(new Array(count)).map(() => (
        <Stack direction='column' width='500px' p={0} key={createId()}>
          <Stack direction='column' p={0}>
            <Skeleton width='100%' height='60px' />
            <Stack direction='row' justifyContent='flex-start' py={1} gap={1} p={0}>
              <Skeleton width='10%' height='60px' />
              <Skeleton width='80%' height='60px' />
              <Skeleton width='40%' height='60px' />
              <Skeleton width='40%' height='60px' />
            </Stack>
            <Divider />
            <Skeleton variant='circular' width='30px' height='30px' sx={{ my: 1 }} />
            <Divider />
          </Stack>
        </Stack>
      ))}
    </>
  );
};

export default GroupSkeleton;
