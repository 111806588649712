import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button } from '@mui/material';
import { useState } from 'react';
import ImportRecipeModal from './import-recipe-modal';

const ImportRecipe = () => {
  const [openImport, setOpenImport] = useState<boolean>(false);

  const handleOpenImport = () => {
    setOpenImport((prev) => !prev);
  };

  return (
    <>
      <Button
        variant='outlined'
        color='info'
        data-testid='import button'
        startIcon={<FileDownloadIcon />}
        sx={{
          bgcolor: 'kqn.white',
          color: 'kqn.green',
          borderColor: 'kqn.green',
          ':hover': { bgcolor: 'kqn.gray', borderColor: 'kqn.cooper' },
        }}
        onClick={handleOpenImport}
      >
        IMPORT
      </Button>
      <ImportRecipeModal isOpen={openImport} onClose={handleOpenImport} />
    </>
  );
};

export default ImportRecipe;
