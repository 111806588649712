import { Stack } from '@mui/material';
import React from 'react';
import { CategoryResponse } from '../../../types';
import { useViewSwitch } from '../../hooks';
import { ViewSwitch } from '../view-switch';
import { RecipeCategoriesHandler } from './recipe-categories-handler';

type Props = {
  category?: CategoryResponse;
  setCategory: (category?: CategoryResponse) => void;
};

const RecipeTableHeaderActions: React.FC<Props> = ({ category, setCategory }) => {
  const { view, setView } = useViewSwitch();

  return (
    <Stack direction='row' gap={2.5}>
      <ViewSwitch
        handleView={(type) => {
          setView(type);
        }}
        view={view}
      />
      <RecipeCategoriesHandler category={category} setCategory={setCategory} />
    </Stack>
  );
};

export default RecipeTableHeaderActions;
