import { Skeleton, Stack } from '@mui/material';
import { createId } from '@paralleldrive/cuid2';
import React from 'react';

interface Props {
  count?: number;
}

const EventsListSkeleton: React.FC<Props> = ({ count = 5 }) => {
  return (
    <Stack direction='column' width='100%'>
      <Stack direction='row' justifyContent='space-between'>
        <Skeleton animation='wave' height={40} width={200} />
        <Skeleton animation='wave' height={40} width={200} />
      </Stack>
      {Array.from(new Array(count)).map(() => (
        <Skeleton key={createId()} animation='wave' width='100%' height={60} />
      ))}
    </Stack>
  );
};

export default EventsListSkeleton;
