import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'grey',
          },
          '&:valid .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
          },

          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: '#F3E9E4',
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: '#F3E9E4',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        style: {
          borderRadius: '2px',
          textTransform: 'none',
          padding: '2px 16px',
          minHeight: '40px',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            background: '#AF6B48',
            color: '#FFF',

            ':hover': { background: '#C39076' },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: { background: '#717174', color: '#FFF', ':hover': { background: '#B9B9B9' } },
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: { background: '#EEE', color: '#201D1D', ':hover': { background: '#EAEAEA' } },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: { color: '#201D1D', ':hover': { bgcolor: '#717174' } },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            border: '1px solid #AF6B48',
            color: '#AF6B48',
            ':hover': { border: '1px solid #C39076', color: '#C39076' },
          },
        },
      ],
    },
  },
  palette: {
    // @ts-ignore
    kqn: {
      green: '#587570',
      lightGreen: '#829794',
      ultraLightGreen: '#74ABA4',
      paleGreen: '#E6F2F0',
      darkGreen: '#2D3A3A',
      cooper: '#AF6B48',
      lightCooper: '#C39076',
      ultraLightCooper: '#F3E9E4',
      cooperSilver: '#EFECE9',
      brightCooper: '#E1C7BA',
      darkCooper: '#8F6A5B',
      gray: '#EEEEEE',
      lightGray: '#B9B9B9',
      ultraLightGray: '#EAEAEA',
      darkGray: '#717174',
      darkerGray: '#201D1D',
      black: '#1F1D1D',
      coral: '#FF6D6A',
      lightCoral: '#FFD7D6',
      white: '#FFFFFF',
      yellow: '#FFC839',
      orange: '#FFA500',
    },
  },
});
