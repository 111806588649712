import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Stack, SxProps, Tooltip, Typography } from '@mui/material';

interface Props {
  title: string;
  tooltipText?: string;
  sx?: SxProps;
}

const Title: React.FC<Props> = ({ title, tooltipText, sx }) => {
  return (
    <Box sx={{ width: 'fit-content', position: 'relative' }}>
      {tooltipText && (
        <Tooltip
          title={
            <Stack alignItems='center' direction='row' gap={1}>
              <InfoOutlinedIcon sx={{ color: 'kqn.coral' }} />
              {tooltipText}
            </Stack>
          }
          placement='top'
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                bgcolor: '#e4e4e4',
                color: 'kqn.darkerGray',
                fontSize: '14px',
                lineHeight: '20px',
                py: '8px',
                px: '12px',
                borderRadius: '8px',
                maxWidth: '500px',
              },
            },
          }}
          sx={{ bgcolor: 'kqn.gray' }}
        >
          <InfoOutlinedIcon
            sx={{
              position: 'absolute',
              top: '-5px',
              right: '-15px',
              color: 'kqn.darkGray',
              width: '15px',
              height: '15px',
            }}
          />
        </Tooltip>
      )}
      <Typography
        variant='body1'
        sx={{
          color: 'kqn.darkerGray',
          width: '100%',
          lineClamp: 1,
          transition: 'all ease-in-out 0.3s',
          ...sx,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Title;
