import styled from '@emotion/styled';
import { TableCell } from '@mui/material';

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableCellStyled = styled(TableCell)<{
  active?: number;
}>`
  padding-top: 8px;
  padding-bottom: 8px;
  color: '#717174';
`;
