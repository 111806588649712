import { Box, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AssignedRecipes, KQNCheckbox, Optional } from '../../../../components';
import { getComparator, Order, stableSort } from '../../../../shared';
import { IngredientHeadCells, PageableIngredient } from '../../../types';
import { mapIngredientsToCells } from '../../mapper';

type Props = {
  data: PageableIngredient[];
  selected: string[];
  perPage: number;
  order: Order;
  orderBy: keyof IngredientHeadCells;
  handleOnSelect: (event: React.MouseEvent<unknown, MouseEvent>, name: string) => void;
};

const IngredientsTableBody: React.FC<Props> = ({
  data,
  selected,
  perPage,
  order,
  orderBy,
  handleOnSelect,
}) => {
  const navigate = useNavigate();
  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const mappedIngredients = mapIngredientsToCells(data);
  const [groups, setGroups] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const records =
    orderBy !== 'name' && orderBy !== 'nameDe'
      ? stableSort(mappedIngredients, getComparator(order, orderBy))
      : mappedIngredients;

  const rows = useMemo(() => records.slice(0, perPage), [perPage, records]);

  const handleGroups = (id: string) => {
    const recipes = data.find((ingredient) => ingredient.id === id)?.groups;
    if (recipes && recipes.length > 0) {
      setIsOpen(true);
      setGroups(recipes);
    }
  };

  const handleOnClose = () => {
    setIsOpen(false);
    setGroups([]);
  };

  const handleOnClick = (id: string) => {
    navigate(`/recipes/ingredients/${id}`);
  };

  return (
    <>
      <TableBody>
        {rows.map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              role='checkbox'
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
              sx={{
                cursor: 'pointer',
                '&.Mui-selected': {
                  bgcolor: 'kqn.ultraLightCooper',
                },

                '&.Mui-selected:hover': {
                  bgcolor: 'kqn.ultraLightCooper',
                },
              }}
            >
              <TableCell padding='checkbox'>
                <KQNCheckbox
                  data-testid='ingredient-checkbox'
                  checked={isItemSelected}
                  onClick={(event) => handleOnSelect(event, row.id)}
                  labelId={labelId}
                />
              </TableCell>
              <TableCell
                component='th'
                id={labelId}
                scope='row'
                onClick={() => handleOnClick(row.id)}
              >
                {row.name}
              </TableCell>
              <TableCell onClick={() => handleOnClick(row.id)}>{row.nameDe}</TableCell>
              <TableCell
                onClick={() => handleGroups(row.id)}
                sx={{
                  fontWeight: 'bold',

                  '&:hover': {
                    cursor: 'pointer',
                    color: 'kqn.cooper',
                    textDecoration: 'underline',
                  },
                }}
              >
                <Tooltip
                  title='Click to display recipes'
                  placement='right'
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        bgcolor: '#e4e4e4',
                        color: 'kqn.darkerGray',
                        fontSize: '14px',
                        lineHeight: '20px',
                        py: '8px',
                        px: '12px',
                        borderRadius: '8px',
                      },
                    },
                  }}
                >
                  <Box component='span'>
                    {`${row.recipesCount}  ${
                      Number(row.recipesCount) === 1 ? 'recipe' : 'recipes'
                    }`}
                  </Box>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <Optional condition={isOpen}>
        <AssignedRecipes recipeIds={groups} isOpen={isOpen} onClose={handleOnClose} />
      </Optional>
    </>
  );
};

export default IngredientsTableBody;
