import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { CreateIngredientRequest } from '../../types';
import { ingredientsService } from '../services/ingredients-service';

interface Data {
  data: CreateIngredientRequest;
}

interface Props {
  onSuccess?: () => void;
}

export const useCreateIngredient = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, CreateIngredientRequest>(
    ingredientsService.createIngredient,
  );

  const handleCreateIngredient = async ({ data, onSuccess }: Data & Props) => {
    mutation.mutate(data, {
      onSuccess: () => {
        queryClient.refetchQueries(queries.ingredients.filter._def);
        toast.success('Ingredient created successfully');
        onSuccess?.();
      },
      onError: (e: any) => {
        toast.error(e.message || 'Something went wrong!');
      },
    });
  };

  return {
    mutation,
    handleCreateIngredient,
    isLoading: mutation.isLoading,
  };
};
