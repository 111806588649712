import { koqoonApi } from '../../../config';
import { PageableQueryFilter, PaginatedResult } from '../../../types';
import { AccessoryModel, CreateAcessoryRequest } from '../../types';

interface AccessoriesService {
  getAccessoryById(id?: string): Promise<AccessoryModel>;
  getAllData(req?: PageableQueryFilter): Promise<PaginatedResult<AccessoryModel>>;
  removeAccessory(id?: string): Promise<void>;
  upsertAccessory(data: CreateAcessoryRequest): Promise<AccessoryModel>;
}

const getAllData = async (req: PageableQueryFilter): Promise<PaginatedResult<AccessoryModel>> => {
  try {
    const response = await koqoonApi.get('/accessories', {
      params: req,
    });

    return response.data as PaginatedResult<AccessoryModel>;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getAccessoryById = async (id?: string): Promise<AccessoryModel> => {
  try {
    if (!id) throw new Error('Id is required');
    const response = await koqoonApi.get(`/accessories/${id}`);

    return response.data as AccessoryModel;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const removeAccessory = async (id?: string): Promise<void> => {
  try {
    if (!id) throw new Error('Id is required');
    const response = await koqoonApi.delete(`/accessories/${id}`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const upsertAccessory = async (data: CreateAcessoryRequest): Promise<AccessoryModel> => {
  try {
    const response = await koqoonApi.post('/accessories', data);

    return response.data as AccessoryModel;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const accessoriesService: AccessoriesService = {
  getAllData,
  removeAccessory,
  upsertAccessory,
  getAccessoryById,
};
