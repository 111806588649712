import { z } from 'zod';

export const mealTypeStepSchema = z.object({
  title: z.string().min(1, 'Meal name must be at least 2 characters.'),
  titleDe: z.string().min(1, 'Meal name must be at least 2 characters.'),
  description: z.string().min(1, 'Meal name must be at least 2 characters.'),
  descriptionDe: z.string().min(1, 'Meal name must be at least 2 characters.'),
  timeOffset: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'Time offset must be provided.',
  }),
  mealTypeId: z.string().min(1, 'Meal type must be selected.'),
});

export type MealTypeStepSchemaType = z.infer<typeof mealTypeStepSchema>;
