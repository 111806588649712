import { Skeleton, Stack } from '@mui/material';
import { createId } from '@paralleldrive/cuid2';
import React from 'react';

interface Props {
  count?: number;
}

const DashboardCardsSkeleton: React.FC<Props> = ({ count = 6 }) => {
  return (
    <Stack direction='row' width='100%' justifyContent='start' pt={3} gap={6}>
      {Array.from(new Array(count)).map(() => (
        <Skeleton key={createId()} variant='rounded' width={170} height={170} />
      ))}
    </Stack>
  );
};

export default DashboardCardsSkeleton;
