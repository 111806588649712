import { ModalComposer } from '../../../../components';
import { CategoryResponse } from '../../../types';
import { useUpdateCategory } from '../../mutations/use-update-category';
import { UpsertCategoryForm } from '../forms';
import { UpdateCategorySchemaType } from '../forms/schema';

interface Props {
  category: CategoryResponse;
  isOpen: boolean;
  onClose: () => void;
}

const UpdateCategoriesModal: React.FC<Props> = ({ category, isOpen, onClose }) => {
  const { handleUpdateCategory, isLoading } = useUpdateCategory();

  const handleOnSubmit = async (data: UpdateCategorySchemaType) => {
    await handleUpdateCategory({
      categoryId: category.id,
      category: data,
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <ModalComposer title='EDIT CATEGORY' isOpen={isOpen} onClose={onClose}>
      <UpsertCategoryForm
        onClose={onClose}
        onSubmit={handleOnSubmit}
        category={category}
        isSubmitting={isLoading}
      />
    </ModalComposer>
  );
};

export default UpdateCategoriesModal;
