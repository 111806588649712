import { z } from 'zod';

export const notificationSchema = z.object({
  title: z.string().optional(),
  titleDe: z.string().optional(),
  message: z.string().min(1, { message: 'Message is required' }),
  messageDe: z.string().min(1, { message: 'Message is required' }),
  inAppMessage: z.string().min(1, { message: 'Notification is required' }),
  inAppMessageDe: z.string().min(1, { message: 'Notification is required' }),
  triggers: z.array(
    z.object({
      id: z.string().optional(),
      type: z.string(),
      value: z.number(),
      name: z.string().optional(),
    }),
  ),
});

export type NotificationFormType = z.infer<typeof notificationSchema>;
