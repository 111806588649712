import { Box, MenuItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CreateButton, Dropdown, Optional, SearchFilter } from '../../../../components';
import { Role } from '../../../../constants';
import { useAuthentication } from '../../../../context';
import { PageableUnit } from '../../../types';
import { CreateUnitModal, RemoveUnitModal, UpdateUnitModal } from '../modals';

type Props = {
  unit?: PageableUnit;
  readonly selected: string[];
  setSelected: (selected: string[]) => void;
};

export const UnitEnhancedTableActions: React.FC<Props> = ({ unit, selected, setSelected }) => {
  const [params, setSearchParams] = useSearchParams();
  const isCreateModalOpen = params.get('modal') === 'true';
  const id = params.get('id');
  const { user: authUser } = useAuthentication();
  const [openDelete, setOpenDeleteModal] = useState<boolean>(false);
  const [openUnit, setOpenUnitModal] = useState<boolean>(isCreateModalOpen || false);
  const [openEdit, setOpenEditModal] = useState<boolean>(false);
  const isTester = authUser?.role === Role.TESTER;

  useEffect(() => {
    if (id && !!selected.length) {
      setOpenEditModal(true);
    }
  }, [id, selected]);

  const handleOnDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleOnEdit = () => {
    setOpenEditModal(true);
  };

  const handleOnEditClose = () => {
    params.delete('id');
    setSearchParams(params);
    setOpenEditModal(false);
  };

  const handleUnitCreateModal = () => {
    setOpenUnitModal(true);
  };

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pt: 1 }}
      >
        <SearchFilter />
        <Stack direction='row' gap={2}>
          <Optional condition={selected.length === 1 && !isTester}>
            <Dropdown label='ACTION'>
              <MenuItem
                onClick={handleOnEdit}
                data-testid='edit-button'
                sx={{ borderBottom: '1px solid #AF6B48' }}
              >
                Edit
              </MenuItem>
              <MenuItem
                data-testid='delete-button'
                onClick={handleOnDelete}
                sx={{ color: 'kqn.coral' }}
              >
                Delete
              </MenuItem>
            </Dropdown>
          </Optional>
          <Optional condition={!isTester}>
            <CreateButton text='ADD' onClick={handleUnitCreateModal} />
          </Optional>
        </Stack>
      </Box>
      <Optional condition={openDelete}>
        <RemoveUnitModal
          selected={unit}
          isOpen={openDelete}
          onClose={() => setOpenDeleteModal(false)}
          onSucess={() => setSelected([])}
        />
      </Optional>
      <Optional condition={openUnit}>
        <CreateUnitModal isOpen={openUnit} onClose={() => setOpenUnitModal(false)} />
      </Optional>
      <Optional condition={openEdit && unit}>
        <UpdateUnitModal unit={unit!} isOpen={openEdit} onClose={handleOnEditClose} />
      </Optional>
    </>
  );
};
