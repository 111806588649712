import { useMemo } from 'react';
import { useRecipeStore } from '../../../../../../../store';

export const useAccessoriesValidation = () => {
  const accessories = useRecipeStore.use.accessories();
  const initialAccessories = useRecipeStore.use.initialAccessories();

  const hasAccessoriesChanges = useMemo(() => {
    if (!initialAccessories || accessories.length !== initialAccessories.length) return true;
    return accessories.some((accessory) => {
      const initialAccessory = initialAccessories.find((ia) => ia.id === accessory.id);
      if (!initialAccessory || accessory.name !== initialAccessory.name) return true;

      return false;
    });
  }, [accessories, initialAccessories]);

  return hasAccessoriesChanges;
};
