import { createId } from '@paralleldrive/cuid2';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { koqoonApi } from '../../../config';
import { queries } from '../../../modules/queries';
import { ImageFile } from '../../../types';

interface Props {
  recipeId?: string | null;
  file?: ImageFile;
  setStatus: (status: 'preview' | 'loading' | 'completed' | 'failed') => void;
  onUpload: (imageUrl: string) => void;
}

export const useHandleUpload = ({ file, recipeId, setStatus, onUpload }: Props) => {
  const [progress, setProgress] = useState(0);
  const queryClient = useQueryClient();
  const [uploading, setUploading] = useState(false);

  const handleUpload = async () => {
    if (!recipeId) return;
    if (!file) return;

    setUploading(true);
    setStatus('loading');
    try {
      const formData = new FormData();
      /**
       * This is needed in order to have a unique name for the image
       * otherwise, the same images will not be uploaded, just reused.
       * So, if we delete one image, all recipes that use that image will
       * be affected.
       */
      const uniqueNameId = createId();

      formData.append('file', file, uniqueNameId);
      formData.append('recipeId', recipeId);

      const response = await koqoonApi.post(`recipes/${recipeId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / (total || 0));
          setProgress(percent);
        },
      });

      if (response.status === 201) {
        setStatus('completed');
        const { data } = response;
        if (data && data.imageUrl) {
          onUpload(data.imageUrl);
          queryClient.refetchQueries(queries.recipes.filter._def);
          queryClient.refetchQueries(queries.recipes.details._def);
          queryClient.refetchQueries(queries.recipes.infinityFilter._def);
          toast.success('Image uploaded successfully');
        }
        return;
      }

      setStatus('failed');
    } catch (err: any) {
      setStatus('failed');
    } finally {
      setUploading(false);
    }
  };

  return { handleUpload, uploading, progress };
};
