import { Stack } from '@mui/material';
import React from 'react';
import { useCalculateThreshold } from './hooks';
import Timestamp from './timestamp';

type Props = { leftOffsetCorrectionWidth: number; duration: number };

const FlowTimestamp: React.FC<Props> = ({ duration, leftOffsetCorrectionWidth }) => {
  const { arrayLength, threshold, px } = useCalculateThreshold(duration, leftOffsetCorrectionWidth);

  return (
    <Stack
      direction='row'
      sx={{
        position: 'relative',
        maxWidth: `calc(1200px - ${leftOffsetCorrectionWidth}px)`,
      }}
      ml={`${leftOffsetCorrectionWidth + 96}px`}
    >
      {Array.from({ length: arrayLength }, (_, i) => {
        return (
          <Stack
            key={i}
            alignItems='center'
            position='relative'
            sx={{
              width: '100%',
              height: '40px',
              mr: `${px}px`,
            }}
          >
            <Timestamp time={threshold * i} />
          </Stack>
        );
      })}
    </Stack>
  );
};

export default FlowTimestamp;
