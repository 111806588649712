/* eslint-disable no-underscore-dangle */
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecipeStore } from '../../../../../../store';
import { queries } from '../../../../../queries';

export const useStepCompleted = () => {
  const recipe = useRecipeStore.use.recipe();
  const { data } = useQuery({ ...queries.recipes.info(recipe.id), enabled: !!recipe.id });
  const [active, setActive] = useState({
    groups: false,
    preparations: false,
    meals: false,
  });

  const isGeneralCompleted =
    !!recipe.name &&
    !!recipe.nameDe &&
    recipe.categories.length > 0 &&
    !!recipe.complexity &&
    recipe.mealTypes.length > 0;

  useEffect(() => {
    if (data && recipe.id) {
      setActive({
        groups: data.groups > 0,
        meals: data.mealTypeSteps > 0,
        preparations: data.steps > 0,
      });
    }

    return () => {
      setActive({ groups: false, preparations: false, meals: false });
    };
  }, [data, recipe.id]);

  const hasCompleted = (label: string): boolean => {
    switch (label.toLowerCase()) {
      case 'general':
        return isGeneralCompleted;
      case 'ingredients':
        return active.groups;
      case 'preparation':
        return active.preparations;
      case 'grilling':
        return active.meals;
      default:
        return false;
    }
  };

  return hasCompleted;
};
