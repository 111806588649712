import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Chip, Divider, Stack, Typography } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths, QueryKeys } from '../../constants';
import { recipeService } from '../../modules/recipes';
import { ModalPopup } from '../modal-popup';

type Props = {
  recipeIds: string[];
  isOpen: boolean;
  onClose: () => void;
};

const AssignedRecipes: React.FC<Props> = ({ recipeIds, isOpen, onClose }) => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { data, error, fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: [QueryKeys.associatedRecipes, recipeIds],
    queryFn: async ({ pageParam }) => {
      const result = await recipeService.getRecipesByIds({
        recipeIds,
        pageParams: pageParam || 1,
      });
      return result;
    },
    getNextPageParam: (metadata) => {
      if (metadata.meta.currentPage === metadata.meta.lastPage) {
        return undefined;
      }

      return metadata.meta.currentPage + 1;
    },
    enabled: recipeIds.length > 0,
  });

  const handleNavigate = useCallback(
    (id: string) => {
      navigate(`${NavigationPaths.DETAILS}?recipeId=${id}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  if (error) {
    return <div>Error fetching recipes</div>;
  }

  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <Box
        sx={{
          width: '900px',
          borderRadius: '8px',
          bgcolor: 'kqn.white',
          p: 3,
          boxShadow: 24,
          overflow: 'hidden',
        }}
      >
        <Stack
          sx={{ color: 'kqn.cooper' }}
          pb={2}
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h6'>ASSOCIATED RECIPES</Typography>
          <CancelIcon onClick={onClose} sx={{ cursor: 'pointer', color: 'kqn.cooper' }} />
        </Stack>
        <Divider
          sx={{
            borderColor: 'kqn.cooper',
          }}
        />
        <Stack gap={2} direction='column' pt={2} sx={{ maxHeight: '60vh', overflow: 'auto' }}>
          <Stack justifyContent='space-between' alignItems='center' direction='row'>
            <Typography align='center' width='100%'>
              Recipe
            </Typography>
            <Typography align='center' width='100%'>
              Category
            </Typography>
          </Stack>
          {isLoading && <Typography>Loading...</Typography>}
          {data?.pages.map((pg) => {
            return pg?.data.map((recipe, idx) => {
              return (
                <Box ref={idx === pg.data.length - 1 ? ref : null} key={recipe.id}>
                  <Stack gap={2} justifyContent='space-between' direction='row'>
                    <Stack
                      direction='row'
                      width='100%'
                      gap={2}
                      sx={{
                        bgcolor: 'kqn.ultraLightGray',
                        p: 1,
                        pr: 2,
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.4)',
                        borderRadius: '8px',
                        '&:hover': { cursor: 'pointer' },
                      }}
                      onClick={() => handleNavigate(recipe.id)}
                    >
                      <img
                        src={recipe.imageUrl}
                        alt={recipe.name}
                        style={{
                          width: '80px',
                          height: '80px',
                          borderRadius: '8px',
                          objectFit: 'cover',
                        }}
                      />
                      <Stack direction='column' justifyContent='space-between' width='100%'>
                        <Typography variant='body2'>{recipe.name}</Typography>
                        <Stack
                          direction='row'
                          alignItems='center'
                          justifyContent='space-between'
                          gap={2}
                          sx={{ color: 'kqn.darkGray' }}
                        >
                          <Stack direction='row' alignItems='center' gap={0.5}>
                            <AccessTimeIcon />
                            <Typography variant='body2'>{recipe.cookingTime}min</Typography>
                          </Stack>
                          <Typography variant='body2' align='right'>
                            {recipe.complexity.name}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction='column' justifyContent='center' width='100%'>
                      <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                        flexWrap='wrap'
                        gap={2}
                      >
                        {recipe.categories?.map((category) => {
                          return (
                            <Chip
                              key={category.id}
                              label={category.name}
                              sx={{
                                color: 'kqn.darkerGray',
                                borderRadius: '8px',
                                bgcolor: 'kqn.lightGray',
                              }}
                            />
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Stack>
                  <Divider
                    sx={{
                      width: '50%',
                      mx: 'auto',
                      mt: 2,
                    }}
                  />
                </Box>
              );
            });
          })}
        </Stack>
      </Box>
    </ModalPopup>
  );
};

export default AssignedRecipes;
