import { Box } from '@mui/material';
import { ImagePlaceholder } from '../../../shared';
import { ImageFile } from '../../../types';

type Props = {
  file?: ImageFile;
  imageUrl?: string;
};

const ImagePreview: React.FC<Props> = ({ file, imageUrl }) => {
  const hasImage = !!file?.size || !!imageUrl;

  return (
    <Box
      data-testid='image-preview'
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{ height: '330px', bgcolor: 'kqn.gray', borderRadius: '8px' }}
    >
      {hasImage ? (
        <img
          src={file?.preview || imageUrl}
          alt={file?.name}
          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }}
        />
      ) : (
        <Box sx={{ width: '100%', height: 330, borderRadius: '8px' }}>
          <ImagePlaceholder style={{ borderRadius: '8px', height: 330, width: 700 }} />
        </Box>
      )}
    </Box>
  );
};

export default ImagePreview;
