import { Tooltip, Typography } from '@mui/material';
import React from 'react';

type Props = {
  displayValue: any;
  children: React.ReactNode;
};

const RecipeTooltip: React.FC<Props> = ({ displayValue, children }) => {
  return (
    <Tooltip
      title={
        children ?? (
          <Typography variant='caption' sx={{ px: 1 }}>
            No preview available
          </Typography>
        )
      }
      placement='top-end'
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            display: 'flex',
            bgcolor: '#e4e4e4',
            color: 'kqn.darkerGray',
            borderRadius: '8px',
            p: 1,
            maxWidth: '600px',
            alignItems: 'center',
          },
        },
      }}
    >
      {displayValue}
    </Tooltip>
  );
};

export default RecipeTooltip;
