import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment } from '@mui/material';
import React from 'react';
import { KQNTextField } from '..';
import { useSearch } from '../../context';

const SearchFilter: React.FC = () => {
  const { setQuery, query } = useSearch();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  return (
    <Box data-testid='search-filter' width='100%' sx={{ borderRadius: 1 }}>
      <KQNTextField
        size='small'
        borderRadius='8px'
        onChange={handleSearch}
        value={query}
        sx={{ width: '250px', bgcolor: 'kqn.gray', color: 'kqn.darkGray' }}
        InputProps={{
          sx: { color: 'kqn.darkerGray' },
          startAdornment: (
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder='Search'
        type='search'
        role='search'
        aria-label='search'
      />
    </Box>
  );
};

export default SearchFilter;
