import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useRecipeActions } from '../../../store';
import { queries } from '../../queries';
import { AccessoryModel, RecipeGroupModel } from '../../types';
import { groupService } from '../components';

interface GroupIngredientRequest {
  maxNumberOfPeople: number;
  nutrients: { energy: number; carbs: number; protein: number; fat: number };
  groups: RecipeGroupModel[];
  recipeId: string;
  accessories?: AccessoryModel[];
}

export const useCreateGroupIngredients = () => {
  const queryClient = useQueryClient();
  const { setMaxNumberOfPeople, setNutrients, setAccessories } = useRecipeActions();

  const mutation = useMutation<RecipeGroupModel[], unknown, GroupIngredientRequest>(
    ({ groups, nutrients, maxNumberOfPeople, recipeId, accessories }) =>
      groupService.updateGroupIngredients(
        recipeId,
        maxNumberOfPeople,
        nutrients,
        groups,
        accessories,
      ),
  );

  const handleSubmit = async (
    group: GroupIngredientRequest,
    onUpdate?: (groups: RecipeGroupModel[]) => void,
  ) => {
    if (!group.recipeId) return;

    mutation.mutate(group, {
      onSuccess: () => {
        setMaxNumberOfPeople(group.maxNumberOfPeople);
        setNutrients({
          energy: group.nutrients.energy,
          carbs: group.nutrients.carbs,
          protein: group.nutrients.protein,
          fat: group.nutrients.fat,
        });
        setAccessories(group?.accessories || []);

        queryClient.invalidateQueries(queries.groups.details._def);
        queryClient.refetchQueries(queries.recipes.info._def);
        toast.success('Ingredients updated successfully');
      },
      onError: (err: any) => {
        toast.error(err || 'Something went wrong');
      },
    });
  };

  return { handleSubmit, mutation, isLoading: mutation.isLoading };
};
