import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { NavigationPaths } from '../../../../constants';
import { CategoriesIcon } from '../../../../shared';
import { CategoryModel } from '../../../types';

type Props = {
  category: CategoryModel;
};

const CategoriesListItem: React.FC<Props> = ({ category }) => {
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      sx={{ borderRadius: '2px', border: '1px solid #e0e0e0', py: 1, px: 2 }}
      width='100%'
    >
      <Stack direction='row' gap={1} alignItems='center'>
        <ArrowForwardIosIcon className='icon' />
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ bgcolor: 'kqn.gray', width: '30px', height: '30px', borderRadius: '50%', p: 1 }}
        >
          <CategoriesIcon />
        </Stack>
        <Link to={`${NavigationPaths.CATEGORIES}/${category.id}`}>
          <Typography sx={{ fontWeight: 'bold', color: 'kqn.cooper' }}>#{category.name}</Typography>
        </Link>
      </Stack>
    </Stack>
  );
};

export default CategoriesListItem;
