/* eslint-disable no-param-reassign */
import { SelectChangeEvent } from '@mui/material';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { combineRanges } from '../shared';
import { FilterType, Range } from '../types';
import { storeSelectors } from './create-selectors';

interface Filters {
  categories: FilterType[];
  complexities: FilterType[];
  mealTypes: FilterType[];
}

export const durations: Range[] = [
  { start: 0, end: 10, value: '< 10 min' },
  { start: 10, end: 30, value: '10 - 30 min' },
  { start: 30, end: 60, value: '30 - 60 min' },
  { start: 0, end: 999, value: '> 60 min' },
];

interface Data {
  complexity: string[];
  category: string[];
  mealType: string[];
  cookingTime: string[];
  cookingStart?: number;
  cookingEnd?: number;
  state: string;
}

type State = {
  query: string;
  filters: Filters;
  data: Data;
};

type Actions = {
  actions: {
    setValue: (event: SelectChangeEvent<any>) => void;
    setQuery: (searchQuery: string) => void;
    resetFilter: () => void;
    setFilters: (filters: Filters) => void;
  };
};

const useFilters = create<Actions & State>()(
  immer((set) => ({
    query: '',
    filters: {
      categories: [],
      complexities: [],
      mealTypes: [],
    },
    data: {
      complexity: [],
      category: [],
      mealType: [],
      cookingTime: [],
      cookingStart: 0,
      cookingEnd: undefined,
      state: '',
    },
    actions: {
      setFilters(filters) {
        set((state) => {
          state.filters = filters;
        });
      },
      resetFilter: () =>
        set((state) => {
          state.query = '';
          state.data = {
            complexity: [],
            category: [],
            mealType: [],
            cookingTime: [],
            cookingStart: 0,
            cookingEnd: undefined,
            state: '',
          };
        }),
      setQuery: (searchQuery) =>
        set((state) => {
          state.query = searchQuery;
        }),
      setValue: (event) =>
        set((state) => {
          const { name, value } = event.target as { name: keyof Data; value: any };

          if (name === 'cookingTime') {
            const ranges = durations.filter((item) => value.includes(item.value));
            const combinedRanges = combineRanges(ranges);
            state.data[name] = value;
            state.data.cookingStart = combinedRanges?.start || 0;
            state.data.cookingEnd = combinedRanges?.end || undefined;

            return;
          }

          if (name === 'state') {
            state.data[name] = value as string;
            return;
          }

          state.data[name] = value;
        }),
    },
  })),
);

export const useFiltersStore = storeSelectors(useFilters);
