import StartIcon from '@mui/icons-material/Start';
import { FormControlLabel, Stack, Switch } from '@mui/material';
import React from 'react';
import { useMealActions } from '../../../../../../../../../../store/meal-store';

type Props = {
  mealId: string;
  stepId: string;
  isInstant?: boolean;
  isDisabled?: boolean;
};

const InstantStart: React.FC<Props> = ({
  isInstant = false,
  mealId,
  stepId,
  isDisabled = false,
}: Props) => {
  const { updateInstant } = useMealActions();

  const handleOnInstantStart = () => {
    if (isDisabled) return;

    updateInstant(mealId, stepId, !isInstant);
  };

  return (
    <Stack direction='row' alignItems='center'>
      <StartIcon />
      <FormControlLabel
        control={
          <Switch
            size='medium'
            checked={isInstant}
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
            onChange={handleOnInstantStart}
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': { color: 'kqn.cooper' },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                bgcolor: 'kqn.lightCooper',
              },
            }}
          />
        }
        sx={{
          marginLeft: '8px',
          '.MuiFormControlLabel-label': { color: 'kqn.darkerGray' },
        }}
        labelPlacement='start'
        label='Instant Start'
      />
    </Stack>
  );
};

export default InstantStart;
