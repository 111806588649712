import { Button, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { queries } from '../../../queries';
import { CategoryResponse } from '../../../types';

type Props = {
  category?: CategoryResponse;
  setCategory: (category?: CategoryResponse) => void;
};

export const RecipeCategoriesHandler: React.FC<Props> = ({ category, setCategory }) => {
  const { data } = useQuery({
    ...queries.recipeCategories.all(),
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return (
    <Stack
      direction='row'
      gap={1}
      justifyContent='flex-start'
      sx={{
        p: 0.5,
        borderRadius: '8px',
        border: '1px solid #E1C7BA',
        boxShadow: 'inset 0px 0px 25px -15px #E1C7BA',
      }}
    >
      <Button
        onClick={() => setCategory(undefined)}
        variant={!category ? 'contained' : 'text'}
        sx={
          !category
            ? {
                color: 'kqn.cooper',
                borderRadius: '8px !important',
                bgcolor: 'kqn.white',
                ':hover': { bgcolor: 'kqn.gray' },
              }
            : { color: 'kqn.darkerGray', bgcolor: 'unset' }
        }
      >
        ALL
      </Button>
      {data?.data.map((c) => {
        const isSelected = c.id === category?.id;

        return (
          <Button
            onClick={() => setCategory(c)}
            key={c.id}
            variant={isSelected ? 'contained' : 'text'}
            sx={
              isSelected
                ? {
                    color: 'kqn.cooper',
                    borderRadius: '8px !important',
                    bgcolor: 'kqn.white',
                    fontSize: '15px',
                    ':hover': { bgcolor: 'kqn.gray' },
                  }
                : { color: 'kqn.darkerGray', bgcolor: 'unset', fontSize: '15px' }
            }
          >
            {c.name}
          </Button>
        );
      })}
    </Stack>
  );
};
