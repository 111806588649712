import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { ModalPopup } from '../modal-popup';

type Props = {
  isOpen: boolean;
  title?: string;
  content?: string;
  onClose?: () => void;
  onSave?: () => void;
  onConfirm?: () => void;
  onDiscard?: () => void;
};

const DiscardChangesModal: React.FC<Props> = ({
  title,
  content,
  isOpen,
  onConfirm,
  onSave,
  onClose,
  onDiscard,
}) => {
  const handleOnSave = () => {
    onSave?.();
    onClose?.();
  };

  return (
    <ModalPopup isOpen={isOpen} onClose={() => onClose?.()}>
      <Stack
        direction='column'
        gap={2}
        sx={{ width: 'fit-content', bgcolor: 'background.paper', p: 3, boxShadow: 24 }}
      >
        <Typography variant='h6'>{title || 'Discard changes?'}</Typography>
        <Typography variant='body1'>
          {content || 'Are you sure you want to discard changes?'}
        </Typography>

        <Stack direction='row' gap={2} justifyContent='flex-end'>
          <Button variant='text' onClick={onClose}>
            CANCEL
          </Button>
          {onDiscard && (
            <Button variant='contained' color='secondary' onClick={onDiscard}>
              DISCARD
            </Button>
          )}
          {onSave && (
            <Button variant='contained' onClick={handleOnSave}>
              SAVE
            </Button>
          )}
          {onConfirm && (
            <Button variant='contained' onClick={onConfirm}>
              CONFIRM
            </Button>
          )}
        </Stack>
      </Stack>
    </ModalPopup>
  );
};

export default DiscardChangesModal;
