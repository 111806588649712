import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Chip, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoRow, KQNIconButton, Optional } from '../../../components';
import { NavigationPaths } from '../../../constants';
import { Ingredient } from '../../types';
import { DeleteIngredientModal, UpdateIngredientModal } from './modals';

type Props = {
  ingredient: Ingredient;
};

const IngredientSummary: React.FC<Props> = ({ ingredient }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { nutrients, units, name, nameDe } = ingredient;
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);

  const handleOnEditClose = () => {
    setOpenEdit(false);
  };

  return (
    <Paper sx={{ minWidth: '500px', p: 3, height: 'fit-content' }}>
      <Stack direction='column' gap={3} width='100%'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography>INGREDIENT PROFILE</Typography>
          <Stack direction='row' justifyContent='flex-end' justifyItems='center' gap={2}>
            <KQNIconButton onClick={() => setOpenEdit(true)}>
              <EditOutlinedIcon sx={{ color: 'kqn.darkGray', fontSize: '27px' }} />
            </KQNIconButton>
            <KQNIconButton onClick={() => setOpenDelete(true)}>
              <DeleteOutlineIcon sx={{ color: 'kqn.coral', fontSize: '27px' }} />
            </KQNIconButton>
          </Stack>
        </Stack>
        <Stack direction='column' gap={3} sx={{ color: 'kqn.darkGray' }}>
          <InfoRow label='Name (EN)' value={name} />
          <InfoRow label='Name (DE)' value={nameDe} />
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack width='100%' justifyContent='flex-start'>
              <Typography variant='body2'>Units</Typography>
            </Stack>
            <Stack
              justifyContent='start'
              width='100%'
              gap={1}
              direction='row'
              alignItems='center'
              flex-wrap='wrap'
            >
              {units.map((unit) => (
                <Chip
                  key={unit.id}
                  label={unit.name}
                  sx={{
                    color: 'kqn.cooper',
                    borderRadius: '30px',
                    bgcolor: 'kqn.ultraLightCooper',
                  }}
                />
              ))}
            </Stack>
          </Stack>
          {nutrients.map((nutrient) => (
            <InfoRow
              key={nutrient.id}
              label={`${nutrient.name}, ${nutrient.unit.name}`}
              value={String(nutrient.amount.toString().replace('.', ','))}
            />
          ))}
        </Stack>
      </Stack>
      <Optional condition={openEdit}>
        <UpdateIngredientModal
          ingredient={ingredient}
          isOpen={openEdit}
          onClose={handleOnEditClose}
        />
      </Optional>
      <Optional condition={openDelete}>
        <DeleteIngredientModal
          ingredients={[ingredient]}
          isOpen={openDelete}
          onClose={() => setOpenDelete(false)}
          onSucess={() => {
            navigate(NavigationPaths.INGREDIENTS);
          }}
        />
      </Optional>
    </Paper>
  );
};

export default IngredientSummary;
