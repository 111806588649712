import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  ComposerFooterButtons,
  KQNTextInputField,
  ModalComposer,
  TextInputAndormnet,
  Title,
} from '../../../components';
import { useUpsertAccessory } from '../mutations';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const createAccessorySchema = z.object({
  name: z.string().min(1, 'Name is required'),
  nameDe: z.string().min(1, 'Name (German) is required'),
});

type CreateAccessorySchemaType = z.infer<typeof createAccessorySchema>;

const CreateAccessoryModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { handleUpsertAccessory, isLoading } = useUpsertAccessory();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateAccessorySchemaType>({
    resolver: zodResolver(createAccessorySchema),
    defaultValues: {
      name: '',
      nameDe: '',
    },
  });

  const handleOnSubmit = async (data: CreateAccessorySchemaType) => {
    await handleUpsertAccessory({
      data,
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <ModalComposer
      icon={<AddIcon className='icon' />}
      title='ADD ACCESSORY'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack gap={4}>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Stack gap={1}>
              <Title title='Accessory' />
              <Stack direction='row' gap={2}>
                <KQNTextInputField
                  control={control}
                  placeholder='Accessory (EN)'
                  name='name'
                  error={errors.name}
                  fullWidth
                  InputProps={{
                    startAdornment: <TextInputAndormnet text='EN' position='start' />,
                  }}
                />
                <KQNTextInputField
                  control={control}
                  placeholder='Accessory (DE)'
                  name='nameDe'
                  error={errors.nameDe}
                  fullWidth
                  InputProps={{
                    startAdornment: <TextInputAndormnet text='DE' position='start' />,
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <ComposerFooterButtons onClose={onClose} isLoading={isLoading} />
        </Stack>
      </form>
    </ModalComposer>
  );
};

export default CreateAccessoryModal;
