import { Box } from '@mui/material';
import { Node } from '../chart-types';

type Props = {
  step: Node;
  isActive?: boolean;
};

const FlowPreheat: React.FC<Props> = ({ step, isActive }) => {
  return (
    <Box
      data-testid='flow-preheat'
      component='div'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#FF6D6A4D',
        border: '1px solid #FF6D6A',
        borderRadius: '4px',
        height: '100%',
        zIndex: 99,
        filter: isActive ? 'contrast(150%)' : 'none',
      }}
      style={{ width: `${step.preheatingWidth}px` }}
    />
  );
};

export default FlowPreheat;
