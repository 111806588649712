import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Stack } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { KQNTextField, TextInputAndormnet } from '../../../../../../../../../../components';
import { useDebounceValue } from '../../../../../../../../../../shared';
import { useGroupStore } from '../../../../../../../../../../store';
import { RecipeGroupModel } from '../../../../../../../../../types';

type Props = {
  group: RecipeGroupModel;
};

const GroupTitleEditor: React.FC<Props> = ({ group }) => {
  const { updateGroupName, removeGroup } = useGroupStore.use.actions();
  const [title, setTitle] = useState<{ name: string; nameDe: string }>({
    name: group.name,
    nameDe: group.nameDe || '',
  });

  const debouncedValue = useDebounceValue(title, 300);

  useEffect(() => {
    if (group.id) {
      updateGroupName(group.id, debouncedValue);
    }
  }, [debouncedValue, group.id, updateGroupName]);

  const handleGroupName = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      name: keyof RecipeGroupModel,
    ) => {
      setTitle((prev) => ({
        ...prev,
        [name]: event.target.value,
      }));
    },
    [],
  );

  const handleRemoveGroup = useCallback(() => {
    if (group.id) {
      removeGroup(group.id);
    }
  }, [group.id, removeGroup]);

  return (
    <Stack direction='row' gap={1.5} pb={1}>
      <KQNTextField
        type='text'
        size='small'
        value={title.name}
        onChange={(e) => handleGroupName(e, 'name')}
        sx={{ width: 'fit-content' }}
        InputProps={{
          sx: { '& .MuiInputBase-input': { padding: '5px 10px' } },
          startAdornment: <TextInputAndormnet position='start' text='EN' />,
        }}
      />
      <KQNTextField
        type='text'
        size='small'
        value={title.nameDe}
        onChange={(e) => handleGroupName(e, 'nameDe')}
        sx={{ width: 'fit-content' }}
        InputProps={{
          sx: { '& .MuiInputBase-input': { padding: '5px 10px' } },
          startAdornment: <TextInputAndormnet position='start' text='DE' />,
        }}
      />
      <Stack direction='row' gap={1} justifyContent='flex-start' alignItems='center'>
        <DeleteOutlineIcon
          onClick={handleRemoveGroup}
          sx={{ color: 'kqn.coral', cursor: 'pointer' }}
        />
      </Stack>
    </Stack>
  );
};

export default GroupTitleEditor;
