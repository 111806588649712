import { Chip, Stack } from '@mui/material';

type Props<T extends { id?: string; name?: string }> = {
  data: T[];
  onClick?: (d: T) => void;
  onDelete?: (d: T) => void;
};

const PeparationIngredientsPicker = <T extends { id?: string; name?: string }>({
  data,
  onClick,
  onDelete,
}: Props<T>) => {
  return (
    <Stack direction='row' alignItems='center' flexWrap='wrap' gap={1}>
      {data
        ?.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
        ?.map((i) => {
          return (
            <Chip
              data-testid='category-chip'
              key={i.id}
              onClick={() => onClick?.(i)}
              onDelete={onDelete ? () => onDelete(i) : undefined}
              label={i.name}
              sx={{
                color: 'kqn.darkerGray',

                borderRadius: '8px',
                bgcolor: onDelete ? 'kqn.ultraLightCooper' : 'kqn.gray',

                '& .MuiChip-deleteIcon': {
                  color: 'kqn.cooper',
                },
              }}
            />
          );
        })}
    </Stack>
  );
};

export default PeparationIngredientsPicker;
