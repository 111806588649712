import { Chip } from '@mui/material';
import React from 'react';
import { TableCellStyled } from '../../../../../components';
import { useAuthentication } from '../../../../../context';
import { RecipeHeadCells } from '../../../../types';

interface Props {
  row: RecipeHeadCells;
  onClick: (id: string) => void;
}

export const DisplayState: React.FC<Props> = ({ onClick, row }) => {
  const { user } = useAuthentication();
  const isAdmin = user?.role === 'ADMIN';
  const isPublished = row.isPublished === 'true' ? 1 : 0;
  const isRemoved = row.isRemoved === 'true' ? 1 : 0;

  if (isAdmin && !!isRemoved) {
    return (
      <TableCellStyled sx={{ width: '140px' }}>
        <Chip
          label='Deleted'
          sx={{
            color: 'kqn.coral',
            borderRadius: '30px',
            bgcolor: 'kqn.lightCoral',
          }}
        />
      </TableCellStyled>
    );
  }

  return (
    <TableCellStyled active={isPublished} onClick={() => onClick(row.id)} sx={{ width: '140px' }}>
      <Chip
        label={isPublished ? 'Published' : 'Unpublished'}
        sx={{
          color: isPublished ? 'kqn.green' : 'kqn.darkGray',
          borderRadius: '30px',
          bgcolor: isPublished ? 'kqn.paleGreen' : 'kqn.ultraLightGray',
        }}
      />
    </TableCellStyled>
  );
};
