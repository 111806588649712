/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
interface Props {
  language?: string;
}

export const useTranslations = ({ language = 'en' }: Props) => {
  const translations = ['en', 'de'].includes(language)
    ? require(`../../translations/${language}.json`)
    : require('../../translations/en.json');

  return translations;
};
