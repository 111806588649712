import { CategoriesHeadCells, CategoryResponse } from '../../types';

const mapIngredientsToCells = (ingredients: CategoryResponse[]): CategoriesHeadCells[] => {
  const mapped = ingredients.map((ingredient) => {
    const { id, description, entityStatus, nameDe, name, _count: recipeCount } = ingredient;
    return {
      id,
      name,
      nameDe,
      description,
      entityStatus: String(entityStatus),
      recipeCount: recipeCount.recipe,
    };
  });

  return mapped;
};

export default mapIngredientsToCells;
