import { z } from 'zod';

export const upsertGrillStepSchema = z.object({
  id: z.string().optional(),
  oridinal: z.number().optional(),
  preset: z.string(),
  type: z.literal('standard'),
  instant: z.boolean().optional(),
  description: z.string().min(1, 'Description is required'),
  descriptionDe: z.string().min(1, 'Description is required'),
  userInstruction: z.string().min(1, 'User instruction is required'),
  userInstructionDe: z.string().min(1, 'User instruction is required'),
  mode: z.object({
    id: z.string(),
    name: z.string(),
  }),
  durations: z
    .array(
      z.object({
        id: z.string(),
        duration: z.number(),
        name: z.string(),
      }),
    )
    .optional(),
  burners: z.array(
    z.object({
      burnerId: z.string(),
      temperature: z.number(),
      name: z.string(),
      index: z.number(),
    }),
  ),
  probes: z
    .array(
      z.object({
        burnerId: z.string(),
        name: z.string(),
        index: z.number(),
        isProbe: z.boolean(),
        temperature: z.number(),
      }),
    )
    .optional(),
  mealTypeStepId: z.string().optional(),
  method: z.object({
    id: z.string(),
    name: z.string(),
  }),
  prepInterval: z.number(),
});

export const upsertInfoGrillStepSchema = z.object({
  id: z.string().optional(),
  type: z.literal('info'),
  oridinal: z.number().optional(),
  userInstruction: z.string().min(1, 'User instruction is required'),
  userInstructionDe: z.string().min(1, 'User instruction is required'),
  durations: z
    .array(
      z.object({
        id: z.string(),
        duration: z.number(),
        name: z.string(),
      }),
    )
    .optional(),
  mealTypeStepId: z.string().optional(),
});
