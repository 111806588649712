import { useCallback, useRef } from 'react';

interface Props {
  behavior?: ScrollBehavior;
  block?: ScrollLogicalPosition;
}

export const useScrollIntoView = ({ behavior = 'smooth', block = 'end' }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const scrollIntoView = useCallback(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior, block });
    }
  }, [behavior, block]);

  return { ref, scrollIntoView };
};
