import { koqoonApi } from '../../../../../../../config';
import { Paths } from '../../../../../../../constants';
import { GrillStep, InfoGrillStep } from '../../../../../../types';

const basePath = Paths.PRESETS;

const getRecipeGrillingSteps = async (recipeId?: string): Promise<GrillStep[]> => {
  if (!recipeId) return [];

  try {
    const response = await koqoonApi.get(`${basePath}/recipes/${recipeId}`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const upsertGrillStep = async (mealId: string, preset: GrillStep) => {
  try {
    const response = await koqoonApi.post(`${basePath}`, { mealId, preset });

    return response.data as GrillStep;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const upsertInfoGrillStep = async (mealId: string, preset: InfoGrillStep) => {
  try {
    const response = await koqoonApi.post(`${basePath}/info`, { mealId, preset });

    return response.data as InfoGrillStep;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const updateGrillOrdinals = async (steps: GrillStep[]) => {
  try {
    const response = await koqoonApi.put(`${basePath}/ordinals`, { steps });

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const removeGrillStep = async (stepId: string) => {
  try {
    const response = await koqoonApi.delete(`${basePath}/${stepId}`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const grillService = {
  removeGrillStep,
  upsertGrillStep,
  updateGrillOrdinals,
  upsertInfoGrillStep,
  getRecipeGrillingSteps,
};
