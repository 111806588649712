import axios, { AxiosError } from 'axios';

const apiURL = process.env.REACT_APP_BASE_URL;

export const koqoonApi = axios.create({
  baseURL: apiURL,
  withCredentials: true,
});

async function handleError(error: AxiosError) {
  const originalRequest = error.config;
  const statusCode = error.response?.status;

  if (statusCode === 401) {
    if (!originalRequest) throw new Error('Failed to get original request');
    const path = `${process.env.REACT_APP_BASE_URL}/auth/refresh-token`;

    try {
      const response = await axios.post(path, {}, { withCredentials: true });
      if (response.status !== 200) return await Promise.reject(error);

      // eslint-disable-next-line @typescript-eslint/return-await
      return axios(originalRequest);
    } catch (err: any) {
      /* empty */
    }
  }

  return Promise.reject(error);
}

koqoonApi.interceptors.response.use((response) => response, handleError);
