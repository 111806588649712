import { Stack } from '@mui/system';
import React from 'react';
import { PresetMethod, PresetMode, PresetTemperature } from '..';
import { Burner, GrillStep, MethodType, ModeType } from '../../../../../../../../../types';
import { useGetValidPresetValues } from '../../../meals';

type Props = {
  step: GrillStep;
  mealId: string;
  preset: string;
  stepId: string;
  initialProbes?: Burner[];
  method?: MethodType;
  mode?: ModeType;
};

const Presets: React.FC<Props> = ({
  step,
  mealId,
  preset,
  stepId,
  mode,
  method,
  initialProbes,
}) => {
  const { burnerModes: modes, methods } = useGetValidPresetValues();

  return (
    <Stack direction='column' gap={2} sx={{ width: '100%' }} justifyContent='space-between'>
      <Stack direction='column' gap={1} sx={{ width: '100%' }}>
        <Stack direction='row' gap={3} alignItems='center'>
          <PresetTemperature
            disabled={!step.isDraft}
            mealId={mealId}
            preset={preset}
            stepId={stepId}
            probes={initialProbes}
            method={method}
          />
          <PresetMode
            disabled={!step.isDraft}
            mealId={mealId}
            mode={mode}
            stepId={stepId}
            modes={modes}
          />
          <PresetMethod
            disabled={!step.isDraft}
            mealId={mealId}
            method={method}
            preset={preset}
            stepId={stepId}
            probes={initialProbes}
            methods={methods}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Presets;
