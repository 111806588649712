import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { KQNTextInputField } from '../../../../../components';
import { useTranslations } from '../../../../../shared';
import { useResetPassword } from './mutations/use-reset-password';
import { ForgotPasswordSchemaType, resetPasswordSchema } from './schema';

type Props = {
  token?: string | null;
  language?: string;
};

const ResetPasswordForm: React.FC<Props> = ({ token, language }) => {
  const { handleResetPassword } = useResetPassword();
  const translations = useTranslations({ language });

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const handleOnSubmit = async (data: ForgotPasswordSchemaType) => {
    if (!token) return;

    const { password, confirmPassword } = data;

    if (!password || !confirmPassword) {
      toast.error('Password and confirm password are required');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    await handleResetPassword(password, token);
  };

  return (
    <Box component='form' onSubmit={handleSubmit(handleOnSubmit)} width='100%'>
      <Stack direction='column' alignItems='center' gap={2}>
        <KQNTextInputField
          fullWidth
          type='password'
          name='password'
          control={control}
          label={translations?.newPasswordLabelText || 'New Password'}
          borderColor='white'
          borderRadius='5px'
          InputProps={{ sx: { color: 'white' } }}
          required
          height='52px'
        />
        <KQNTextInputField
          type='password'
          name='confirmPassword'
          control={control}
          borderRadius='5px'
          label={translations?.confirmPasswordLabelText || 'Confirm Password'}
          required
          height='52px'
          borderColor='white'
          InputProps={{ sx: { color: 'white' } }}
        />
      </Stack>
      <Stack justifyContent='flex-end' alignContent='center' direction='row' gap={2} mt={2}>
        <Button type='submit' variant='contained' sx={{ textTransform: 'uppercase' }}>
          {translations?.passwordResetButton || 'submit'}
        </Button>
      </Stack>
    </Box>
  );
};

export default ResetPasswordForm;
