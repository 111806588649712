import { percentageOfTotalDuration, pixelsOfMaxWidth } from '../helpers';

export const useCalculateThreshold = (duration: number, leftOffsetCorrectionWidth: number) => {
  let threshold = 60;

  if (duration < 300) {
    threshold = 1;
  } else if (duration >= 300 && duration <= 1859) {
    threshold = 5;
  } else if (duration >= 1860 && duration <= 5459) {
    threshold = 10;
  } else if (duration >= 5460 && duration <= 10800) {
    threshold = 15;
  }

  const arrayLength = Math.ceil(duration / 60 / threshold);

  return {
    threshold,
    arrayLength,
    px: pixelsOfMaxWidth(
      percentageOfTotalDuration(threshold, duration / 60),
      1200 - leftOffsetCorrectionWidth,
    ),
  };
};
