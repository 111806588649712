import HomeIcon from '@mui/icons-material/Home';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { SlashIcon, usePathname } from '../../shared';

interface Props {
  children?: React.ReactNode;
}

const Navigation: React.FC<Props> = ({ children }) => {
  const { route, basePath } = usePathname();

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      alignItems='flex-start'
      sx={{
        minHeight: '100px',
        py: 2,
        gap: 2,
        px: 4,
        bgcolor: 'kqn.cooperSilver',
        color: 'kqn.darkGray',
      }}
    >
      <Stack direction='row' gap={1} alignItems='center'>
        <Stack
          sx={{
            border: '2px solid #B9B9B9',
            borderRadius: '4px',
            w: 3,
            h: 3,
            color: 'kqn.lightGray',
          }}
        >
          <HomeIcon fontSize='small' />
        </Stack>
        <Link to='/'>
          <Typography variant='body2'>{basePath}</Typography>
        </Link>
        {route.map(({ pathname, title }) => (
          <Stack justifyContent='center' gap={1} direction='row' key={title} alignItems='center'>
            <SlashIcon />
            {pathname ? (
              <Link to={pathname || ''}>
                <Typography
                  variant='body2'
                  fontWeight='900'
                  fontSize='20px'
                  sx={{ color: 'kqn.darkerGray' }}
                >
                  {title}
                </Typography>
              </Link>
            ) : (
              <Typography
                variant='body2'
                fontWeight='900'
                fontSize='20px'
                sx={{ color: 'kqn.darkerGray' }}
              >
                {title}
              </Typography>
            )}
          </Stack>
        ))}
      </Stack>
      {children}
    </Stack>
  );
};

export default Navigation;
