import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { KQNCheckbox, TableCellStyled } from '../../../../components';
import { getComparator, ImagePlaceholder, Order, stableSort } from '../../../../shared';
import { PaginatedResult } from '../../../../types';
import { PageableRecipe, RecipeHeadCells } from '../../../types';
import { DisplayState } from './components';
import { mapRecipesToCells } from './mappers';

type Props = {
  data: PaginatedResult<PageableRecipe>;
  selected: string[];
  perPage: number;
  order: Order;
  orderBy: keyof RecipeHeadCells;
  handleOnSelect: (event: React.MouseEvent<unknown, MouseEvent>, name: string) => void;
};

const RecipesEnhancedTableBody: React.FC<Props> = ({
  data,
  perPage,
  order,
  orderBy,
  handleOnSelect,
  selected,
}) => {
  const { data: recipes } = data;
  const mappedRecipes = mapRecipesToCells(recipes);
  const navigate = useNavigate();
  const isSelected = (id: string) => selected.includes(id);

  const records =
    orderBy !== 'name' ? stableSort(mappedRecipes, getComparator(order, orderBy)) : mappedRecipes;

  const rows = useMemo(() => records.slice(0, perPage), [perPage, records]);

  const hanldeRecipeClick = (id: string) => {
    const recipe = recipes.find((rec) => rec.id === id);

    if (recipe) {
      navigate(`/recipes/details?recipeId=${recipe.id}`);
    }
  };

  return (
    <TableBody>
      {rows.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        const isPublished = row.isPublished === 'true' ? 1 : 0;

        return (
          <TableRow
            hover
            role='checkbox'
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            sx={{
              cursor: 'pointer',
              '&.Mui-selected': { bgcolor: 'kqn.ultraLightCooper' },
              '&.Mui-selected:hover': { bgcolor: 'kqn.ultraLightCooper' },
            }}
          >
            <TableCell padding='checkbox'>
              <KQNCheckbox
                data-testid='recipe-checkbox'
                onClick={(event) => handleOnSelect(event, row.id)}
                checked={isItemSelected}
                labelId={labelId}
              />
            </TableCell>
            <TableCellStyled
              active={isPublished}
              onClick={() => hanldeRecipeClick(row.id)}
              sx={{ width: '340px' }}
            >
              <Stack direction='row' gap={1} alignItems='center'>
                {row.thumbnailUrl ? (
                  <img
                    src={row.thumbnailUrl}
                    alt='thumbnail'
                    style={{ borderRadius: '10px', width: '40px' }}
                  />
                ) : (
                  <ImagePlaceholder style={{ borderRadius: '10px', width: '40px' }} />
                )}
                {row.name}
              </Stack>
            </TableCellStyled>
            <TableCellStyled
              active={isPublished}
              onClick={() => hanldeRecipeClick(row.id)}
              sx={{ width: '260px' }}
            >
              {row.categories}
            </TableCellStyled>
            <TableCellStyled
              active={isPublished}
              onClick={() => hanldeRecipeClick(row.id)}
              sx={{ width: '140px' }}
            >
              {row.complexity}
            </TableCellStyled>
            <DisplayState row={row} onClick={hanldeRecipeClick} />
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default RecipesEnhancedTableBody;
