import { Box } from '@mui/material';
import { memo } from 'react';
import { Ingredient, RecipeGroupModel } from '../../../../../../../../../types';
import { GroupIngredientsTable } from '../group-ingredient-table';
import { GroupTitleEditor } from '../group-title-editor';

type Props = {
  group: RecipeGroupModel;
  ingredients: Ingredient[];
};

const Group: React.FC<Props> = ({ group, ingredients }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <GroupTitleEditor group={group} />
      <GroupIngredientsTable group={group} ingredients={ingredients} />
    </Box>
  );
};

export default memo(Group);
