import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { useRecipesFilter, useSearch } from '../../context';
import { usePathname } from '../../shared';
import { ListItemType } from './sidebar.types';

type Props = {
  data: ListItemType[];
  open: boolean;
};

const MenuListItems: React.FC<Props> = ({ data, open }) => {
  const { paths } = usePathname();
  const { resetSearchQuery, query } = useSearch();
  const { resetFilter, query: recipesQuery } = useRecipesFilter();

  const handleOnClick = () => {
    if (query !== '') resetSearchQuery();
    if (recipesQuery !== '') resetFilter();
  };
  return (
    <List>
      {data.map(({ text, icon, url, testId, path }) => {
        const isActive = path.includes(paths[1]);

        return (
          <Link to={url} key={text} onClick={handleOnClick}>
            <ListItem
              disablePadding
              sx={{ display: 'block', color: isActive ? 'kqn.darkerGray' : 'kqn.white' }}
            >
              <ListItemButton
                data-testid={testId}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  '&.Mui-selected': { backgroundColor: 'kqn.white' },
                  '&.MuiButtonBase-root:hover': { bgcolor: 'rgba(255, 255, 255, 0.40)' },
                }}
                selected={isActive}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: isActive ? 'kqn.darkerGray' : 'kqn.white',
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ fontWeight: isActive ? 'bold' : '400' }}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
};

export default MenuListItems;
