import { Node } from '../chart-types';
import { percentageOfTotalDuration, pixelsOfMaxWidth } from './chart-percentages';

const MAX_WIDTH = 1200;

export function calculateNodePercentages(
  startOffset: number,
  prepInterval: number,
  duration: number,
  cookingTime: number,
  totalDuration: number,
) {
  const node: Node = {
    leftOffset: 0,
    width: 0,
    endOffset: 0,
    percentage: 0,
    offsetPercentage: 0,
    preheatingWidth: 0,
  };

  node.leftOffset = pixelsOfMaxWidth(
    percentageOfTotalDuration(startOffset, totalDuration),
    MAX_WIDTH,
  );

  node.preheatingPercentage = percentageOfTotalDuration(prepInterval, totalDuration);
  node.preheatingWidth = pixelsOfMaxWidth(node.preheatingPercentage, MAX_WIDTH);

  node.percentage = percentageOfTotalDuration(duration, totalDuration);
  node.offsetPercentage = percentageOfTotalDuration(startOffset, totalDuration);
  node.endOffset = duration + startOffset > totalDuration ? totalDuration : duration + startOffset;
  node.cookingWidth = pixelsOfMaxWidth(
    percentageOfTotalDuration(cookingTime, totalDuration),
    MAX_WIDTH,
  );

  node.width = pixelsOfMaxWidth(node.percentage, MAX_WIDTH);
  return node;
}
