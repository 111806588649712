import { koqoonApi } from '../../../config';
import { CategoryModel, DashboardData } from '../../types';

interface DashboardService {
  getAllData(): Promise<DashboardData[]>;
  getPopularCategories(): Promise<CategoryModel[]>;
}

const getAllData = async (): Promise<DashboardData[]> => {
  try {
    const response = await koqoonApi.get('/dashboard');

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getPopularCategories = async (): Promise<CategoryModel[]> => {
  try {
    const response = await koqoonApi.get('/dashboard/categories');

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const dashboardService: DashboardService = {
  getAllData,
  getPopularCategories,
};
