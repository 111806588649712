import { koqoonApi } from '../../../../../../../../../../../config';
import { Paths } from '../../../../../../../../../../../constants';
import { GrillStep, Meal, MealRequest } from '../../../../../../../../../../types';

const basePath = Paths.MEALS;

const createMealTypeStep = async (meal: MealRequest): Promise<Meal> => {
  try {
    const response = await koqoonApi.post(basePath, {
      ...meal,
      timeOffset: parseInt(meal.timeOffset, 10),
    });

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getAllRecipeMealTypeSteps = async (recipeId: string | null): Promise<Meal[]> => {
  if (!recipeId) return [];

  try {
    const response = await koqoonApi.get(`${basePath}/recipes/${recipeId}`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getMealTypeStepGrillSteps = async (stepId?: string | null): Promise<GrillStep[]> => {
  if (!stepId) return [];

  try {
    const response = await koqoonApi.get(`${basePath}/${stepId}/grill-steps`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const removeMealTypeStep = async (id: string): Promise<void> => {
  try {
    await koqoonApi.delete(`${basePath}/${id}`);
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const mealTypeStepService = {
  removeMealTypeStep,
  createMealTypeStep,
  getAllRecipeMealTypeSteps,
  getMealTypeStepGrillSteps,
};
