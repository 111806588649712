import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useAuthentication } from '../../../context';
import { useTranslations } from '../../../shared';

interface Props {
  language?: string;
}

const EmailChangedPage: React.FC<Props> = ({ language = 'en' }) => {
  const translations = useTranslations({ language });
  const { user, signOut } = useAuthentication();

  useEffect(() => {
    if (user) {
      signOut({});
    }
  }, [signOut, user]);

  return (
    <Stack direction='column' gap={1}>
      <Typography variant='h6' fontWeight='bold'>
        {translations.emailChangedSuccessTitle}
      </Typography>
      <Typography variant='body1'>{translations.emailChangedSuccessMessage}</Typography>
    </Stack>
  );
};

export default EmailChangedPage;
