import { Skeleton, Stack } from '@mui/material';

const RecipeInfoSkeleton: React.FC = () => {
  return (
    <Stack direction='column' gap={2} sx={{ width: '100%' }}>
      <Stack direction='column' gap={1}>
        <Skeleton variant='rounded' width={100} height={24} />
        <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
          <Skeleton variant='rounded' width={70} height={32} />
          <Skeleton variant='rounded' width={70} height={32} />
          <Skeleton variant='rounded' width={70} height={32} />
        </Stack>
      </Stack>
      <Stack direction='column' gap={1}>
        <Skeleton variant='rounded' width={100} height={24} />
        <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
          <Skeleton variant='rounded' width={54} height={57} />
          <Skeleton variant='rounded' width={54} height={57} />
          <Skeleton variant='rounded' width={54} height={57} />
          <Skeleton variant='rounded' width={54} height={57} />
          <Skeleton variant='rounded' width={54} height={57} />
          <Skeleton variant='rounded' width={54} height={57} />
          <Skeleton variant='rounded' width={54} height={57} />
          <Skeleton variant='rounded' width={54} height={57} />
          <Skeleton variant='rounded' width={54} height={57} />
        </Stack>
      </Stack>
      <Stack direction='column' gap={1}>
        <Skeleton variant='rounded' width={100} height={24} />
        <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
          <Skeleton variant='rounded' width={100} height={32} />
          <Skeleton variant='rounded' width={100} height={32} />
          <Skeleton variant='rounded' width={100} height={32} />
          <Skeleton variant='rounded' width={100} height={32} />
          <Skeleton variant='rounded' width={100} height={32} />
          <Skeleton variant='rounded' width={100} height={32} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RecipeInfoSkeleton;
