import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, FormControl, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { KQNBaseInput } from '../../../components';
import { Paths } from '../../../constants';
import { useAuthentication } from '../../../context';
import { KoqoonLogo } from '../../../shared';

const loginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
});

type LoginSchemaType = z.infer<typeof loginSchema>;

const LoginForm: React.FC = () => {
  const { signIn } = useAuthentication();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: { email: '', password: '' },
  });

  const handleOnSubmit = async (data: LoginSchemaType) => {
    const { email, password } = data;
    signIn({ credentials: { email, password }, redirectPath: Paths.BASE });
  };

  return (
    <Box
      sx={{ marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      gap={3}
      width='100%'
    >
      <KoqoonLogo color='#AF6B48' style={{ width: '100%', height: '35px' }} />
      <Stack
        direction='column'
        width='100%'
        gap={3}
        alignItems='start'
        justifyContent='center'
        sx={{ bgcolor: '#eee', p: 5, borderRadius: '2px' }}
      >
        <Typography component='h1' variant='h6'>
          Welcome to KOQOON Portal!
        </Typography>
        <Box component='form' onSubmit={handleSubmit(handleOnSubmit)} sx={{ width: '100%' }}>
          <Stack direction='column' gap={2} width='100%'>
            <FormControl variant='standard'>
              <KQNBaseInput
                id='email'
                {...register('email')}
                name='email'
                fullWidth
                type='email'
                placeholder='Email'
                autoComplete='username'
              />
              {errors.email && (
                <Typography variant='caption' color='error'>
                  {errors.email?.message}
                </Typography>
              )}
            </FormControl>
            <FormControl variant='standard'>
              <KQNBaseInput
                autoComplete='current-password'
                id='password'
                {...register('password')}
                name='password'
                fullWidth
                type='password'
                placeholder='Password'
              />
            </FormControl>
            <Stack direction='row' justifyContent='flex-end' sx={{ color: '#AF6B48' }}>
              <Link to='/forgot-password'>Forgot password?</Link>
            </Stack>
          </Stack>
          <Button
            data-testid='login-button'
            type='submit'
            fullWidth
            variant='contained'
            sx={{ my: 2, borderRadius: '8px !important' }}
          >
            Sign In
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default LoginForm;
