import { IngredientHeadCells, PageableIngredient } from '../../types';

const mapIngredientsToCells = (ingredients: PageableIngredient[]): IngredientHeadCells[] => {
  const mapped = ingredients.map((ingredient) => {
    const { id, name, nameDe, carbs, fat, protein, energy, groups } = ingredient;
    const carbsAmount = carbs?.amount || 0;
    const fatAmount = fat?.amount || 0;
    const proteinAmount = protein?.amount || 0;
    const energyAmount = energy?.amount || 0;
    return {
      id,
      name,
      nameDe,
      energy: energyAmount,
      carbs: carbsAmount,
      protein: proteinAmount,
      fat: fatAmount,
      recipesCount: groups?.length || 0,
    };
  });

  return mapped;
};

export default mapIngredientsToCells;
