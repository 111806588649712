import { MealCards } from './components';

type Props = {
  recipeId: string;
};

const Meals: React.FC<Props> = ({ recipeId }) => {
  return <MealCards recipeId={recipeId} />;
};

export default Meals;
