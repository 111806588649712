import { format } from 'date-fns';

export const formatDate = (timestamp: string): string => {
  const date = new Date(timestamp);
  date.setDate(date.getDate());
  const formattedDate = format(date, 'yyyy-MM-dd');
  const formattedTime = format(date, 'hh:mm aa');

  return `${formattedDate} at ${formattedTime}`;
};
