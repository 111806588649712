import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../../../queries';
import { RecipeMetadata } from '../../../../types';
import { recipeService } from '../../../services';

export const useCreateRecipeFromMetadata = () => {
  const queryClient = useQueryClient();
  return useMutation<{ recipeId: string }, unknown, RecipeMetadata>({
    mutationFn: (data) => recipeService.createRecipeGeneralPhase(data),
    onSuccess: () => {
      queryClient.refetchQueries(queries.recipes.infinityFilter._def);
      queryClient.refetchQueries(queries.recipes.filter._def);
      queryClient.invalidateQueries(queries.recipes.details._def);
    },
    onError(error) {
      toast.error(`Error while trying to import recipe. ${error}`);
    },
  });
};
