import { Box, Stack, Typography } from '@mui/material';
import { useAuthentication } from '../../../context';
import { Events } from '../../events';
import { CategoriesList, QuickAction } from '../components';

const DashboardPage = () => {
  const { user } = useAuthentication();

  return (
    <Box sx={{ px: 3, width: '100%' }}>
      <Box py={2} gap={5} display='flex' flexDirection='column'>
        <Stack direction='column' justifyContent='space-between'>
          <Typography align='left' variant='h3' sx={{ color: 'kqn.darkerGray' }}>
            Welcome back, {user?.firstName}!
          </Typography>
          <QuickAction />
        </Stack>
        <Stack direction='row' justifyContent='space-between' width='100%' gap={5} flex='2 1'>
          <Events />
          <CategoriesList />
        </Stack>
      </Box>
    </Box>
  );
};

export default DashboardPage;
