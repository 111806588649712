import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

export const recipeGroups = [
  {
    id: 'main',
    name: 'Main Dish',
    nameDe: 'Hauptspeise',
  },
  {
    id: 'side',
    name: 'Side Dish',
    nameDe: 'Beilage',
  },
  {
    id: 'salad',
    name: 'Salad',
    nameDe: 'Salat',
  },
  {
    id: 'dressing',
    name: 'Dressing',
    nameDe: 'Dressing',
  },
  {
    id: 'custom',
    name: 'Custom',
    nameDe: 'Benutzerdefinierte',
    isCustom: true,
    icon: <CreateOutlinedIcon sx={{ color: 'kqn.darkGray', width: '1.2rem' }} />,
  },
];
