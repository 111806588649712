import { createId } from '@paralleldrive/cuid2';
import { GrillStep, GrillStepType } from '../../../../../../../../../../types';

export function createStep(steps: GrillStep[], type: GrillStepType): GrillStep {
  const ordinal = steps.length < 1 ? 1 : Math.max(...steps.map((g) => g.ordinal || 0)) + 1;

  if (type === 'info') {
    return {
      id: createId(),
      durations: [{ id: createId(), name: 'Default', duration: 0 }],
      userInstruction: '',
      userInstructionDe: '',
      ordinal,
      description: '',
      descriptionDe: '',
      type,
      isDraft: true,
    };
  }

  return {
    id: createId(),
    description: '',
    descriptionDe: '',
    isDraft: true,
    burners: [],
    lockDurations: false,
    probes: [],
    ordinal,
    type,
  };
}
