import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useMealActions } from '../../../../../../../store/meal-store';
import { queries } from '../../../../../../queries';
import { grillService } from '../service';

interface Data {
  mealId: string;
  presetId: string;
}

export const useRemovePreset = () => {
  const queryClient = useQueryClient();
  const { removePreset } = useMealActions();
  const mutation = useMutation((stepId: string) => grillService.removeGrillStep(stepId));

  const handleOnRemove = async ({ mealId, presetId }: Data) => {
    mutation.mutate(presetId, {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.meals.filter._def);
        toast.success('Step removed successfully');
        removePreset(mealId, presetId);
      },
      onError: () => {
        toast.error('Failed to remove step');
      },
    });
  };

  return { handleOnRemove, isLoading: mutation.isLoading };
};
