import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { RecipePreparation } from '../../types';
import { preparationService } from '../components';

export const useUpdatePreparationsOrdinals = () => {
  const mutation = useMutation<unknown, unknown, { preparations: RecipePreparation[] }>(
    ({ preparations }) => preparationService.updatePreparationsOrdinals(preparations),
  );

  const handleUpdatePreparationsOrdinals = async (preparations: RecipePreparation[]) => {
    mutation.mutate(
      { preparations },
      {
        onSuccess: () => {
          toast.success('Preparation ordinals updated successfully');
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    handleUpdatePreparationsOrdinals,
    isLoading: mutation.isLoading,
  };
};
