import { Icon } from '@iconify/react';
import { NavigationPaths, Paths } from '../../../constants';
import { CategoriesIcon, IngredientsIcon, RecipesIcon, UsersIcon } from '../../../shared';
import { Event } from '../../types';

const items = [
  {
    topic: 'recipe',
    icon: <RecipesIcon className='icon' />,
    url: Paths.RECIPES,
    navigation: NavigationPaths.OVERVIEW,
  },
  {
    topic: 'category',
    icon: <CategoriesIcon className='icon' />,
    url: NavigationPaths.CATEGORIES,
    navigation: NavigationPaths.CATEGORIES,
  },
  {
    topic: 'ingredient',
    icon: <IngredientsIcon className='icon' />,
    url: NavigationPaths.INGREDIENTS,
    navigation: NavigationPaths.INGREDIENTS,
  },
  {
    topic: 'unit',
    icon: <Icon icon='ion:scale-outline' className='icon' />,
    url: NavigationPaths.UNITS,
    navigation: NavigationPaths.UNITS,
  },
  {
    topic: 'user',
    icon: <UsersIcon className='icon' />,
    url: NavigationPaths.USERS,
    navigation: NavigationPaths.USERS,
  },
];

export const useCreateEventProperties = (event: Event) => {
  const item = items.find((i) => i.topic === event.topic);

  const buildUrl = (topic: string, contentId?: string) => {
    if (!item) return '';

    switch (topic) {
      case 'recipe':
        return `${item.url}/details?recipeId=${contentId}`;
      case 'category':
      case 'ingredient':
        return `${item.url}/${contentId}`;
      case 'unit':
      case 'user':
        return `${item.url}?id=${contentId}`;
      default:
        return item.url;
    }
  };

  const url = event.contentId ? buildUrl(event.topic, event.contentId) : item?.navigation;

  const eventProps = {
    prefix: event.action === 'created' ? 'added new' : event.action,
    icon: item?.icon,
    url,
  };

  return eventProps;
};
