import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { createId } from '@paralleldrive/cuid2';
import { useCallback } from 'react';
import { useGroupStore } from '../../../../../../../../../../store';
import { Ingredient, RecipeGroupModel } from '../../../../../../../../../types';
import GroupIngredient from '../group-ingredient/group-ingredient';

type Props = {
  group: RecipeGroupModel;
  ingredients: Ingredient[];
};

const GroupIngredientsTable: React.FC<Props> = ({ ingredients, group }) => {
  const { addIngredient } = useGroupStore.use.actions();

  const handleAddIngredient = useCallback(() => {
    addIngredient(group.id, { id: createId(), name: '', unitId: '', unit: '', quantity: 0 });
  }, [addIngredient, group.id]);

  return (
    <>
      <Table sx={{ maxWidth: 900 }} aria-labelledby='tableTitle' size='small'>
        <TableHead
          sx={{
            bgcolor: 'kqn.gray',
            '& th': { color: 'kqn.darkerGray' },
            '& th:first-of-type': { borderRadius: '2px 0 0 2px' },
            '& th:last-of-type': { borderRadius: '0 2px 2px 0' },
          }}
        >
          <TableRow>
            <TableCell>
              <Typography>№</Typography>
            </TableCell>
            <TableCell>
              <Typography>Ingredient</Typography>
            </TableCell>
            <TableCell>
              <Typography>Amount</Typography>
            </TableCell>
            <TableCell>
              <Typography>Unit</Typography>
            </TableCell>
            <TableCell>
              <Typography align='center'>Energy, kcal/100g</Typography>
            </TableCell>
            <TableCell>
              <Typography align='center'>Carbs, g/100g</Typography>
            </TableCell>
            <TableCell>
              <Typography align='center'>Protein, g/100g</Typography>
            </TableCell>
            <TableCell>
              <Typography align='center'>Fat, g/100g</Typography>
            </TableCell>
            <TableCell>
              <Box />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {group.ingredients.map((ingredient, index) => (
            <GroupIngredient
              key={ingredient.id}
              group={group}
              ordinal={index + 1}
              initialState={ingredient}
              ingredients={ingredients}
            />
          ))}
        </TableBody>
      </Table>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={{ color: 'kqn.darkGray' }}
      >
        <span
          onClick={handleAddIngredient}
          aria-hidden='true'
          data-testid='add-group-ingredient-button'
        >
          <AddCircleOutlineIcon sx={{ cursor: 'pointer', width: '24px', height: '24px', mt: 1 }} />
        </span>
      </Stack>
      <Divider sx={{ bgcolor: 'kqn.lightGray' }} />
    </>
  );
};
export default GroupIngredientsTable;
