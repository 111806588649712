import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearch } from '../../../../context/search-context/search-context';
import { fetchConfig, useDebounceValue, useHandleFilterParams } from '../../../../shared';
import { queries } from '../../../queries';
import { mapIngredientsResponseToObject } from '../../mapper';
import IngredientEnhancedTable from './ingredient-enhanced-table';

const IngredientsTable: React.FC = () => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { query } = useSearch();
  const { page, perPage, orderBy } = useHandleFilterParams();
  const debouncedQuery = useDebounceValue(query);

  const { data, isError } = useQuery({
    ...queries.ingredients.filter({
      query: debouncedQuery,
      perPage,
      page,
      orderBy,
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    if (debouncedQuery === '') return;

    setSearchParams({ page: String(1), perPage: perPage.toString() });
  }, [debouncedQuery, perPage, setSearchParams]);

  if (!data) return null;
  if (isError) return <div>Error...</div>;

  const ingredients = mapIngredientsResponseToObject(data);

  const handleChangePage = (_: unknown, newPage: number) => {
    setSearchParams({ page: String(newPage + 1), perPage: perPage.toString(), orderBy });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fetchConfig.rowsPerPageOptions.includes(Number(event.target.value))) {
      setSearchParams({ page: '1', perPage: event.target.value, orderBy });
      return;
    }

    setSearchParams({ page: '1', perPage: '50', orderBy });
  };

  return (
    <IngredientEnhancedTable
      data={ingredients}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default IngredientsTable;
