import { Box, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { attachmentConfig } from '../../shared/config';
import { useRecipeStore } from '../../store';
import { ImageFile } from '../../types';
import { DropzoneArea, ImageIndicatorInfo, ImagePreview } from './components';

interface Props {
  recipeId: string | null;
  onUpload: (imageUrl: string) => void;
  onRemove: () => void;
  imageUrl: string;
}

const Dropzone: React.FC<Props> = ({ recipeId, imageUrl, onRemove, onUpload }) => {
  const { setFile } = useRecipeStore.use.recipeActions();
  const [files, setFiles] = useState<ImageFile[]>([]);
  const [status, setStatus] = useState<'preview' | 'loading' | 'completed' | 'failed'>('completed');

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setStatus('preview');
      if (acceptedFiles.length) {
        setFiles((prevFiles) => [
          ...prevFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              title: `${file.name}_${Date.now()}`,
            }),
          ),
        ]);
      }
      setFile(acceptedFiles[0]);
    },
    [setFile],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/jpeg': attachmentConfig.allowedFileTypes },
    maxFiles: attachmentConfig.maxFiles,
    maxSize: attachmentConfig.maxFileSize,
    multiple: false,
    onDropRejected: () => {
      toast.error('Invalid file type or size');
    },
  });

  return (
    <Box width='100%' data-testid='dropzone'>
      <Stack direction='column' justifyContent='center' alignItems='center'>
        <Box gap={2} display='flex' flexDirection='column' width='100%'>
          <ImagePreview file={files[0]} imageUrl={imageUrl} />
          <Stack direction='row' gap={2}>
            <DropzoneArea
              acceptedExtensions={['JPG', 'JPEG', 'PNG']}
              getInputProps={getInputProps}
              getRootProps={getRootProps}
              isDragActive={isDragActive}
            />
            <ImageIndicatorInfo
              recipeId={recipeId}
              file={files[0]}
              setFiles={setFiles}
              status={status}
              imageUrl={imageUrl}
              onUpload={onUpload}
              setStatus={setStatus}
              onRemove={onRemove}
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default Dropzone;
