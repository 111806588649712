import { Box, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  AssociatedRecipes,
  DetailsTableSkeleton,
  MainLayout,
  Navigation,
  TableWrapper,
} from '../../../components';
import { queries } from '../../queries';
import AccessoriesSummary from './accessories-summary';

const AccessoriesDetails = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useQuery({
    ...queries.accessories.details(id),
    enabled: !!id,
  });

  if (isLoading || isFetching) return <DetailsTableSkeleton title={data?.name} />;

  if (!data) return <div>Accessory not found</div>;

  return (
    <TableWrapper>
      <Box sx={{ width: '100%' }}>
        <Navigation />
        <MainLayout>
          <Stack direction='row' gap={4} justifyContent='flex-start'>
            <AccessoriesSummary accessory={data} />
            <AssociatedRecipes recipeIds={data?.recipes?.map((recipe) => recipe.id) || []} />
          </Stack>
        </MainLayout>
      </Box>
    </TableWrapper>
  );
};

export default AccessoriesDetails;
