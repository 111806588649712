import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Menu, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import React from 'react';

interface Props {
  label?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  menuSx?: SxProps<Theme>;
  startIcon?: React.ReactNode;
  children: React.ReactNode;
}

const Dropdown: React.FC<Props> = ({
  children,
  sx,
  menuSx,
  disabled,
  label = 'Open',
  startIcon,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        disabled={disabled}
        id='action-btn'
        sx={{
          ...sx,
          height: '40px',
          borderRadius: 2,
          px: 2,
          width: isOpen ? '19.5ch' : '110px',
        }}
        variant='outlined'
        onClick={handleOpenMenu}
        aria-controls={isOpen ? 'action-menu' : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        startIcon={startIcon}
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {label}
      </Button>
      {!disabled && (
        <Menu
          open={isOpen}
          id='action-menu'
          anchorEl={anchorEl}
          MenuListProps={{ 'aria-labelledby': 'action-btn', sx: { py: 0 } }}
          onClose={handleOnClose}
          onClick={handleOnClose}
          sx={{ ...menuSx }}
          PaperProps={{
            style: {
              border: '1px solid #AF6B48',
              width: '17ch',
              marginTop: '2px',
              overflowX: 'visible',
            },
          }}
        >
          {children}
        </Menu>
      )}
    </>
  );
};

export default Dropdown;
