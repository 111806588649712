import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { accessoriesService } from '../services';

interface Data {
  id?: string;
}

interface Props {
  onSuccess?: () => void;
}

export const useRemoveAccessory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, Data>(({ id }) =>
    accessoriesService.removeAccessory(id),
  );

  const handleRemoveAccessory = async ({ id, onSuccess }: Data & Props) => {
    mutation.mutate(
      { id },
      {
        onSuccess: () => {
          queryClient.refetchQueries(queries.accessories.filter._def);
          toast.success('Accessory removed successfully');
          onSuccess?.();
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    mutation,
    handleRemoveAccessory,
    isLoading: mutation.isLoading,
  };
};
