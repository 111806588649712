import { Box, Skeleton, Stack } from '@mui/material';
import { MainLayout } from '../../../../../../components';

const GeneralComposerSkeleton: React.FC = () => {
  return (
    <MainLayout>
      <Stack direction='row' justifyContent='space-between' gap={3}>
        <Box display='flex' flexDirection='column' gap={2} width='100%' height='100%'>
          <Stack direction='column' gap={1}>
            <Skeleton variant='rounded' width={100} height={24} />
            <Skeleton variant='rounded' width='100%' height={40} />
            <Skeleton variant='rounded' width='100%' height={40} />
          </Stack>
          <Stack direction='column' gap={1}>
            <Skeleton variant='rounded' width={100} height={24} />
            <Skeleton variant='rounded' width={80} height={40} />
          </Stack>
          <Stack direction='column' gap={2} sx={{ width: '100%' }}>
            <Stack direction='column' gap={1}>
              <Skeleton variant='rounded' width={100} height={24} />
              <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
                <Skeleton variant='rounded' width={70} height={32} />
                <Skeleton variant='rounded' width={70} height={32} />
                <Skeleton variant='rounded' width={70} height={32} />
              </Stack>
            </Stack>
            <Stack direction='column' gap={1}>
              <Skeleton variant='rounded' width={100} height={24} />
              <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
                <Skeleton variant='rounded' width={54} height={57} />
                <Skeleton variant='rounded' width={54} height={57} />
                <Skeleton variant='rounded' width={54} height={57} />
                <Skeleton variant='rounded' width={54} height={57} />
                <Skeleton variant='rounded' width={54} height={57} />
                <Skeleton variant='rounded' width={54} height={57} />
                <Skeleton variant='rounded' width={54} height={57} />
                <Skeleton variant='rounded' width={54} height={57} />
                <Skeleton variant='rounded' width={54} height={57} />
              </Stack>
            </Stack>
            <Stack direction='column' gap={1}>
              <Skeleton variant='rounded' width={100} height={24} />
              <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
                <Skeleton variant='rounded' width={100} height={32} />
                <Skeleton variant='rounded' width={100} height={32} />
                <Skeleton variant='rounded' width={100} height={32} />
                <Skeleton variant='rounded' width={100} height={32} />
                <Skeleton variant='rounded' width={100} height={32} />
                <Skeleton variant='rounded' width={100} height={32} />
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box width='100%' gap={1} display='flex' flexDirection='column'>
          <Skeleton variant='rounded' width={100} height={24} />
          <Skeleton variant='rounded' width='100%' height={330} />
          <Skeleton variant='rounded' width='100%' height={115} />
        </Box>
      </Stack>
      <Skeleton variant='rounded' width={100} height={24} />
      <Stack direction='row' mt={1} gap={3}>
        <Skeleton variant='rounded' width='100%' height={100} />
        <Skeleton variant='rounded' width='100%' height={100} />
      </Stack>
    </MainLayout>
  );
};

export default GeneralComposerSkeleton;
