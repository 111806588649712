import { HeadCell } from '../../../../types';
import { UsersHeadCells } from '../../../types';

const usersHeadCells: readonly HeadCell<UsersHeadCells>[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Role Assigned',
  },
  {
    id: 'providers',
    numeric: false,
    disablePadding: false,
    label: 'Providers',
  },
  {
    id: 'active',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

export default usersHeadCells;
