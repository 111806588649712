import { Stack } from '@mui/material';
import { GridIcon, ListIcon } from '../../../../shared';

type Props = {
  view?: 'table' | 'grid';
  handleView: (view: 'table' | 'grid') => void;
};

const ViewSwitch: React.FC<Props> = ({ handleView, view }) => {
  return (
    <Stack
      direction='row'
      justifyContent='center'
      alignItems='center'
      gap={2}
      sx={{
        p: 1,
        border: '1px solid #EAEAEA',
        borderRadius: '8px',
        transition: 'all 0.2s ease-in-out',
      }}
    >
      <ListIcon
        style={{
          color: view === 'table' ? '#AF6B48' : '#717174',
          cursor: 'pointer',
          background: view === 'table' ? '#EFECE9' : '',
          borderRadius: '8px',
        }}
        data-testid='list-view-button'
        onClick={() => handleView('table')}
      />
      <GridIcon
        style={{
          cursor: 'pointer',
          color: view === 'grid' ? '#AF6B48' : '#717174',
          background: view === 'grid' ? '#EFECE9' : '',
          borderRadius: '8px',
        }}
        data-testid='grid-view-button'
        onClick={() => handleView('grid')}
      />
    </Stack>
  );
};

export default ViewSwitch;
