/* eslint-disable no-param-reassign */
import { createId } from '@paralleldrive/cuid2';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { RecipeGroupModel, RecipeIngredient, Unit } from '../modules/types';
import { storeSelectors } from './create-selectors';

type State = {
  groups: Array<RecipeGroupModel>;
  initialGroups: Array<RecipeGroupModel>;
};

type Actions = {
  actions: {
    setGroups: (groups: Array<RecipeGroupModel>) => void;
    addIngredient: (groupId: string, ingredient: RecipeIngredient) => void;
    addGroup: ({ name, nameDe }: { name: string; nameDe: string }) => void;
    removeGroup: (groupId: string) => void;
    updateGroupName: (groupId: string, values: { name: string; nameDe: string }) => void;
    updateIngredient: (groupId: string, ingredient: RecipeIngredient, idx: string) => void;
    removeIngredient: (groupId: string, ingredientId: string) => void;
    updateIngredientUnit: (
      groupId: string,
      ingredient: RecipeIngredient,
      unitId: Pick<Unit, 'id' | 'name'>,
    ) => void;
    updateIngredientQuantity: (
      groupId: string,
      ingredient: RecipeIngredient,
      quantity: number,
    ) => void;
    setInitialState: () => void;
    resetState: () => void;
  };
};

const useGroups = create<State & Actions>()(
  immer((set) => ({
    groups: [],
    initialGroups: [],
    actions: {
      setGroups: (groups) =>
        set((state) => {
          state.groups = groups;
          state.initialGroups = groups;
        }),
      resetState: () =>
        set((state) => {
          state.groups = [];
          state.initialGroups = [];
        }),
      addIngredient: (groupId, ingredient) =>
        set((state) => {
          const group = state.groups.find((g) => g.id === groupId);
          if (group) {
            group.ingredients.push(ingredient);
          }
        }),
      addGroup: ({ name, nameDe }) =>
        set((state) => {
          state.groups.push({ id: createId(), name, nameDe, ingredients: [] });
        }),
      removeGroup: (groupId) =>
        set((state) => {
          state.groups = state.groups.filter((group) => group.id !== groupId);
        }),
      updateGroupName: (groupId, values) =>
        set((state) => {
          const group = state.groups.find((g) => g.id === groupId);
          if (group) {
            group.name = values.name;
            group.nameDe = values.nameDe;
          }
        }),
      updateIngredient: (groupId, ingredient, idx) =>
        set((state) => {
          const group = state.groups.find((g) => g.id === groupId);
          if (group) {
            const index = group.ingredients.findIndex((i) => i.id === idx);
            group.ingredients[index] = ingredient;
          }
        }),
      removeIngredient: (groupId, ingredientId) =>
        set((state) => {
          const group = state.groups.find((g) => g.id === groupId);
          if (group) {
            group.ingredients = group.ingredients.filter((i) => i.id !== ingredientId);
          }
        }),
      updateIngredientUnit: (groupId, ingredient, unit) =>
        set((state) => {
          const group = state.groups.find((g) => g.id === groupId);
          if (group) {
            const index = group.ingredients.findIndex((i) => i.id === ingredient.id);
            if (index !== -1) {
              group.ingredients[index].unit = unit.name;
              group.ingredients[index].unitId = unit.id;
            }
          }
        }),
      updateIngredientQuantity: (groupId, ingredient, quantity) =>
        set((state) => {
          const group = state.groups.find((g) => g.id === groupId);
          if (group) {
            const index = group.ingredients.findIndex((i) => i.id === ingredient.id);
            if (index !== -1) {
              group.ingredients[index].quantity = quantity;
            }
          }
        }),
      setInitialState: () =>
        set((state) => {
          state.groups = state.initialGroups;
        }),
    },
  })),
);

export const useGroupStore = storeSelectors(useGroups);
