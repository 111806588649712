import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { queries } from '../../queries';
import { categoriesService } from '../services/categories-service';

interface Data {
  name: string;
  nameDe: string;
  description?: string;
}

interface Props {
  onSuccess?: () => void;
}

export const useCreateCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, { data: Data }>(({ data }) =>
    categoriesService.createCategory(data),
  );

  const handleCreateCategory = async ({ onSuccess, name, nameDe, description }: Data & Props) => {
    mutation.mutate(
      { data: { name, nameDe, description } },
      {
        onSuccess: () => {
          queryClient.refetchQueries(queries.categories.filter._def);
          toast.success('Category created successfully');
          onSuccess?.();
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    mutation,
    handleCreateCategory,
    isLoading: mutation.isLoading,
  };
};
