import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { DetailsTableSkeleton, MainLayout, Navigation, TableWrapper } from '../../../components';
import { queries } from '../../queries';
import { UnitSummary } from '../components';

const UnitDetails = () => {
  const { id } = useParams();

  const { data, isLoading, isFetching } = useQuery({
    ...queries.units.details(id),
    enabled: !!id,
  });

  if (isLoading || isFetching) return <DetailsTableSkeleton type='single' title={data?.name} />;

  if (!data || !id) return <div>Unit not found</div>;

  return (
    <TableWrapper>
      <Box sx={{ width: '100%' }}>
        <Navigation />
        <MainLayout>
          <UnitSummary unit={data} />
        </MainLayout>
      </Box>
    </TableWrapper>
  );
};

export default UnitDetails;
