import { ChartData, ChartStep, Node } from '../chart-types';
import { getBurners } from './get-burners';
import { getDuration } from './get-duration';

interface Props {
  name: string;
  meal: ChartData;
  step: ChartStep;
  currentOrdinal: number;
  startOffsetCorrection: number;
  isFirst?: boolean;
}

export function generateNodeMetadata({
  name,
  meal,
  step,
  currentOrdinal,
  startOffsetCorrection,
  isFirst,
}: Props) {
  const prepInterval = step?.prepInterval || 0;
  const duration = getDuration(step) || 0;
  const fullDuration = prepInterval + duration;
  const startOffset = isFirst ? meal.timeOffset : meal.timeOffset + startOffsetCorrection;
  const burners = getBurners(step);

  const node: Node = {
    name,
    mealId: meal.id,
    isInfo: step.type === 'info',
    startOffset,
    prepInterval,
    currentOrdinal,
    duration: fullDuration,
    cookingTime: duration,
    endOffset: startOffset + fullDuration,
    stepId: step.id,
  };

  return { node, burners, fullDuration, startOffset, prepInterval, duration };
}
