import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import { useState } from 'react';
import { ActionMenuItem } from '../../types';

type Props = {
  options?: ActionMenuItem[];
  orientation?: 'vertical' | 'horizontal';
  menuColor?: string;
  dissableRipple?: boolean;
};

const ActionMenu: React.FC<Props> = ({
  orientation,
  options,
  menuColor = '#fff',
  dissableRipple = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handeItemClick = (event: React.MouseEvent<HTMLElement>, fn?: () => void) => {
    if (!fn) return;

    event.stopPropagation();
    setAnchorEl(null);
    fn();
  };

  if (!options) return null;

  return (
    <>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        disableRipple={dissableRipple}
      >
        {orientation === 'horizontal' ? (
          <MoreHorizIcon sx={{ color: menuColor }} />
        ) : (
          <MoreVertIcon sx={{ color: menuColor }} />
        )}
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
          sx: { pb: 0, pt: 0, borderRadius: '2px' },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxWidth: '17ch',
            marginTop: '4px',
            borderRadius: '2px',
            border: '1px solid #B9B9B9',
            overflowX: 'visible',
          },
        }}
      >
        {options.map((option) => {
          if (option.hidden) return null;

          return (
            <MenuItem
              disabled={option.isDisabled}
              key={option.name}
              onClick={(event) => {
                handeItemClick(event, option.fn);
              }}
              divider
              sx={{
                '&:hover': { bgcolor: '#B9B9B9', color: '#fff' },
                ...option.sx,
              }}
            >
              <Stack direction='row' gap={1} alignItems='center'>
                {option.icon || null}
                <ListItemText
                  primary={option.name}
                  primaryTypographyProps={{ whiteSpace: 'normal', lineHeight: '1' }}
                />
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ActionMenu;
