import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Stack, Typography } from '@mui/material';
import { Title } from '../../../../components';
import { ErrorKeys } from '../../../../constants';
import { useErrorContext } from '../../../../context';
import { MealTypeModel } from '../../../types';

type Props = {
  mealTypes?: MealTypeModel[];
  pickedMealTypes?: MealTypeModel[];
  updateMealType?: (mealType: MealTypeModel) => void;
};

const MealTypesPicker: React.FC<Props> = ({ mealTypes, pickedMealTypes, updateMealType }) => {
  const { error } = useErrorContext();

  return (
    <Stack direction='column' gap={1}>
      <Title title='Meal Type' />
      <Stack
        direction='row'
        justifyContent='flex-start'
        width='100%'
        alignItems='center'
        flexWrap='wrap'
        gap={2}
      >
        {mealTypes?.map((mealType) => {
          const isSelected = pickedMealTypes?.find(
            (pickedMealType) => pickedMealType.id === mealType.id,
          );

          return (
            <Stack
              data-testid='meal-type-chip'
              direction='column'
              alignItems='center'
              gap={0.5}
              key={mealType.id}
              sx={{ position: 'relative', cursor: 'pointer' }}
              onClick={() => updateMealType?.(mealType)}
            >
              <img src={mealType.iconUri} alt={mealType.name} className='meal-icon' />
              <Typography variant='caption' sx={{ textTransform: 'uppercase' }}>
                {mealType.name}
              </Typography>
              {isSelected && (
                <CheckBoxIcon
                  sx={{
                    color: 'kqn.cooper',
                    fontSize: '1.2rem',
                    position: 'absolute',
                    bottom: '15px',
                    right: '0',
                  }}
                />
              )}
            </Stack>
          );
        })}
      </Stack>
      {error.size ? <Typography color='error'>{error.get(ErrorKeys.MEAL_TYPES)}</Typography> : null}
    </Stack>
  );
};

export default MealTypesPicker;
