import { createContext, useCallback, useContext, useMemo, useState } from 'react';

type ErrorContextType = {
  error: Map<string, string>;
  setError: (error: Map<string, string>) => void;
  resetError: () => void;
};

const initialState = {
  error: new Map<string, string>(),
  setError: () => {},
  resetError: () => {},
};

const ErrorContext = createContext<ErrorContextType>(initialState);

type Props = {
  children: React.ReactNode;
};

export const ErrorContextProvider: React.FC<Props> = ({ children }) => {
  const [error, setError] = useState(initialState.error);

  const resetError = useCallback(() => setError(initialState.error), []);

  const value = useMemo(() => ({ error, setError, resetError }), [error, resetError]);

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};

export const useErrorContext = () => {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error('useErrorContext must be used within a ErrorProvider');
  }

  return context;
};
