import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box } from '@mui/material';

const ImageIcon = () => {
  return (
    <Box
      sx={{
        width: '40px',
        height: '40px',
        bgcolor: 'kqn.ultraLightGray',
        borderRadius: '50%',
        color: 'kqn.darkGray',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <UploadFileIcon />
    </Box>
  );
};

export default ImageIcon;
