import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { authService } from '../services';

interface Data {
  email: string;
}

interface Props {
  onSuccess?: () => void;
}

export const useForgotPassword = () => {
  const mutation = useMutation(({ email }: Data) => authService.forgotPassword(email));

  const handleForgotPassword = async ({ email, onSuccess }: Data & Props) => {
    mutation.mutate(
      { email },
      {
        onSuccess: () => {
          toast.success('Please check your email for further instructions');
          onSuccess?.();
        },
        onError: (e: any) => {
          toast.error(e.message);
        },
      },
    );
  };

  return { handleForgotPassword, isLoading: mutation.isLoading, mutation };
};
