import { CircularProgress, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { Optional } from '../../../components';
import { queries } from '../../queries';
import ActivateAccountPage from './activate-account-page';
import EmailChangedPage from './email-changed-page';
import PagesWrapper from './pages-wrapper';
import { ResetPasswordPage } from './reset-password';

export type EmailTemplateType = 'reset-password' | 'change-email' | 'activation-email';

const TokenVerificationPage = () => {
  const [params] = useSearchParams();
  const language = params.get('language') || 'en';
  const token = params.get('token');
  const activationType = params.get('type');

  const { isLoading, isFetching, isError, error } = useQuery({
    ...queries.users.activation(token, activationType, language),
    enabled: !!token,
  });

  if (!token)
    return (
      <PagesWrapper language={language}>
        <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
          Token not provided!
        </Typography>
      </PagesWrapper>
    );

  const render = () => {
    if (isError || isLoading || isFetching) return null;
    if (activationType === 'activation-email') return <ActivateAccountPage language={language} />;
    if (activationType === 'change-email') return <EmailChangedPage language={language} />;
    if (activationType === 'reset-password') return <ResetPasswordPage language={language} />;
  };

  return (
    <PagesWrapper language={language}>
      <Optional condition={isLoading || isFetching}>
        <Stack direction='row' gap={2} alignItems='center' justifyContent='center'>
          <CircularProgress size={20} sx={{ color: 'kqn.white' }} />
          <Typography variant='h5'>Checking the token..</Typography>
        </Stack>
      </Optional>
      <Optional condition={isError}>
        <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
          {!!error && (error as any).response.data.message}
        </Typography>
      </Optional>
      {render()}
    </PagesWrapper>
  );
};

export default TokenVerificationPage;
