import { useMemo } from 'react';
import { useMeals } from '../../../../../../../store/meal-store';
import { ChartData } from '../chart-types';
import { mapChartValues } from '../mappers';
import { parseData } from '../parsers';

export const useGetChartData = () => {
  const meals = useMeals((state) => state.meals).filter((meal) => meal.steps.length > 0);

  const chartData: ChartData[] = useMemo(
    () =>
      meals.map((meal) => ({
        id: meal.id,
        timeOffset: meal.timeOffset || 0,
        steps: meal.steps.map((step) => ({
          id: step.id,
          type: step.type,
          burners: step.burners,
          durations: step.durations,
          prepInterval: step.prepInterval,
        })),
      })),
    [meals],
  );

  const { sortedData, totalDuration } = useMemo(() => parseData(chartData), [chartData]);

  const { updatedMap } = useMemo(
    () => mapChartValues(sortedData, totalDuration.highestZoneDuration),
    [sortedData, totalDuration.highestZoneDuration],
  );

  return { updatedMap, totalDuration };
};
