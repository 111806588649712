import { Stack } from '@mui/material';
import React from 'react';
import { KQNTextField, Title } from '../../../../../../../../../../components';
import { ErrorKeys } from '../../../../../../../../../../constants';
import { useErrorContext } from '../../../../../../../../../../context';
import { useDebounceValue } from '../../../../../../../../../../shared';
import { useMealActions } from '../../../../../../../../../../store/meal-store';

type Props = {
  prepInterval?: number;
  mealId: string;
  stepId: string;
};

const PreparationInterval: React.FC<Props> = ({ mealId, stepId, prepInterval }) => {
  const { updatePrepInterval } = useMealActions();
  const [interval, setInterval] = React.useState<number | undefined>(prepInterval);
  const { error } = useErrorContext();
  const debouncedValue = useDebounceValue(interval, 300);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInterval(parseInt(e.target.value, 10));
  };

  React.useEffect(() => {
    if (debouncedValue !== prepInterval && debouncedValue !== undefined) {
      updatePrepInterval(mealId, stepId, debouncedValue);
    }
  }, [debouncedValue, mealId, prepInterval, stepId, updatePrepInterval]);

  return (
    <Stack direction='column' gap={1}>
      <Title title='Preparation, s' />
      <KQNTextField
        type='number'
        size='small'
        data-testid='preparation-input'
        InputProps={{
          sx: {
            bgcolor: 'kqn.white',
            width: '110px',
          },
        }}
        variant='outlined'
        placeholder='0'
        value={interval}
        onChange={handleChange}
        onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        error={!!error.get(`${stepId}-${ErrorKeys.PREP_INTERVAL}`)}
        helperText={error.get(`${stepId}-${ErrorKeys.PREP_INTERVAL}`)}
      />
    </Stack>
  );
};

export default PreparationInterval;
