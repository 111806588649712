import { Button, Stack } from '@mui/material';
import { ImageFile } from '../../../types';
import { Optional } from '../../optional';
import { useHandleUpload } from '../hoks/use-handle-upload';
import { FileInfoSection } from './file-info-section';

type Props = {
  recipeId?: string | null;
  file?: ImageFile;
  status?: 'preview' | 'loading' | 'completed' | 'failed';
  setStatus: (status: 'preview' | 'loading' | 'completed' | 'failed') => void;
  onUpload: (imageUrl: string) => void;
  onRemove: () => void;
  setFiles: (files: ImageFile[]) => void;
  imageUrl?: string;
};

const ImageIndicatorInfo: React.FC<Props> = ({
  recipeId,
  file,
  status,
  setStatus,
  imageUrl,
  setFiles,
  onUpload,
  onRemove,
}) => {
  const { handleUpload, uploading, progress } = useHandleUpload({
    file,
    recipeId,
    onUpload,
    setStatus,
  });

  const hasImage = !!imageUrl || !!file;
  const canDelete = !!imageUrl || status === 'completed';
  const hasFileChanged = file?.preview;

  const resetFiles = () => setFiles([]);
  const handleRemove = () => {
    resetFiles();
    onRemove();
  };

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      data-testid='image-indicator-info'
      sx={{ height: '100%', position: 'relative', width: '50%' }}
      gap={1}
    >
      <Optional condition={!imageUrl && !!file}>
        <FileInfoSection
          file={file}
          status={status}
          progress={progress}
          handleCancel={resetFiles}
        />
      </Optional>

      <Optional condition={hasImage && !!recipeId}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          width='100%'
          sx={{ m: 'auto', bottom: '0' }}
        >
          {canDelete && (
            <Button
              type='button'
              onClick={handleRemove}
              variant='outlined'
              color='error'
              sx={{ borderRadius: '8px' }}
            >
              REMOVE
            </Button>
          )}

          <Stack direction='row' width='100%' justifyContent='end' alignItems='center'>
            {status === 'preview' && (
              <Button
                type='button'
                onClick={resetFiles}
                variant='text'
                sx={{ color: 'kqn.darkerGray', '&:hover': { color: 'kqn.darkerGray' } }}
              >
                CANCEL
              </Button>
            )}
            {status === 'preview' && (
              <Button
                type='button'
                onClick={handleUpload}
                variant='contained'
                sx={{
                  backgroundColor: 'kqn.green',
                  color: 'kqn.white',
                  '&:hover': { backgroundColor: 'kqn.green' },
                }}
                disabled={uploading || !hasFileChanged || !recipeId}
              >
                SAVE
              </Button>
            )}
          </Stack>
        </Stack>
      </Optional>
    </Stack>
  );
};

export default ImageIndicatorInfo;
