import { Box } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { Node } from '../chart-types';
import FlowDuration from './flow-duration';
import FlowPreheat from './flow-preheat';

type Props = {
  step: Node;
};

const FlowStep: React.FC<Props> = ({ step }) => {
  const [activeNode, setActiveNode] = useState<Node | null>(() => null);

  const handleSetActiveNode = useCallback(
    (node: Node) => {
      if (node.name === activeNode?.name) return;
      setActiveNode(node);
    },
    [activeNode?.name],
  );

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: `${step.leftOffset}px`,
        width: `${step.width}px`,
        height: '30px',
        zIndex: 99,
      }}
      onMouseLeave={() => setActiveNode(null)}
      onMouseEnter={() => handleSetActiveNode(step)}
    >
      {(step?.prepInterval || 0) > 0 && (
        <FlowPreheat step={step} isActive={step.name === activeNode?.name} />
      )}
      <FlowDuration step={step} isActive={step.name === activeNode?.name} />
    </Box>
  );
};

export default memo(FlowStep);
