export const burnerNames = {
  Rare: 'Rare',
  'Med Rare': 'M.Rare',
  Medium: 'Med',
  'Med Well': 'M.Well',
  'Well Done': 'W.Done',
  '': 'Med',
  Default: 'Default',
  'Bottom Heat': 'B.Heat',
  Chamber: 'Chamb.',
  'Burner 1': '1',
  'Burner 2': '2',
  'Burner 3': '3',
  'Burner 4': '4',
};

export function shorterBurnerName(burnerName: keyof typeof burnerNames): string {
  return burnerNames[burnerName] || burnerName;
}
