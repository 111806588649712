import { ConfirmModal } from '../../../../components';
import { useRemoveUser } from '../../mutations';

interface DeleteUserModalProps {
  userId?: string;
  isOpen: boolean;
  onClose: () => void;
  onSucess?: () => void;
}

const DeleteUserModal = ({ userId, isOpen, onClose, onSucess }: DeleteUserModalProps) => {
  const { handleRemoveUser, isLoading } = useRemoveUser();

  const handleOnRemove = async () => {
    if (!userId) return;

    await handleRemoveUser({
      userId,
      onSuccess: () => {
        onSucess?.();
        onClose();
      },
    });
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCofirm={handleOnRemove}
      isProcessing={isLoading}
    />
  );
};

export default DeleteUserModal;
