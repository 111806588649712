import { ModalPopup } from '../../../../components';
import ImportDropzone from './import-dropzone';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ImportRecipeModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <ImportDropzone onClose={onClose} />
    </ModalPopup>
  );
};

export default ImportRecipeModal;
