import { Typography } from '@mui/material';
import { ConfirmModal } from '../../../../components';
import { useUpdateRecipeState } from '../../mutations/use-update-recipe-state';

interface Props {
  recipeId?: string;
  isOpen: boolean;
  onClose: (event?: any) => void;
  message?: string;
  title?: string;
}

const RestoreRecipeModal: React.FC<Props> = ({
  recipeId,
  isOpen,
  onClose,
  message = 'This will restore the selected recipe to your recipe list. Confirm to complete the restoration.',
  title = 'RESTORE RECIPE',
}) => {
  const { handleUpdateRecipeState: handleRemoveRecipe, isLoading } = useUpdateRecipeState();

  const handleOnStateUpdate = async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!recipeId) return;
    event?.stopPropagation();

    await handleRemoveRecipe({ recipeId, onSuccess: onClose });
  };

  if (!recipeId) return null;

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCofirm={handleOnStateUpdate}
      title={title}
      isProcessing={isLoading}
    >
      <Typography sx={{ mb: 2 }}>{message}</Typography>
    </ConfirmModal>
  );
};

export default RestoreRecipeModal;
