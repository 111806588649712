import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { QueryKeys } from '../../../../../../../../../../../constants';
import { queries } from '../../../../../../../../../../queries';
import { mealTypeStepService } from '../services';

interface Data {
  id: string;
}

interface Props {
  onSuccess?: () => void;
}

export const useRemoveMealTypeStep = () => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    const param = searchParams.get('mealTypeStepId');

    if (param) {
      searchParams.delete('mealTypeStepId');
      setSearchParams(searchParams);
    }
  };

  const mutation = useMutation<unknown, unknown, Data>(({ id }) =>
    mealTypeStepService.removeMealTypeStep(id),
  );

  const handleRemoveMealTypeStep = async ({ id, onSuccess }: Data & Props) => {
    mutation.mutate(
      { id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.meals]);
          queryClient.invalidateQueries(queries.recipes.info._def);
          removeQueryParams();
          onSuccess?.();
        },
        onError: (err: any) => {
          toast.error(err || 'Failed to remove meal type step');
        },
      },
    );
  };

  return { handleRemoveMealTypeStep, mutation, isLoading: mutation.isLoading };
};
