import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Menu, MenuItem, Stack } from '@mui/material';
import React from 'react';
import { GrillStepType } from '../../../../../../../../../../types';

interface Props {
  disabled?: boolean;
  handleAddStep: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, type: GrillStepType) => void;
}

const AddStepMenu: React.FC<Props> = ({ handleAddStep, disabled }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type: GrillStepType,
  ) => {
    handleAddStep(e, type);
    handleOnClose();
  };

  return (
    <Stack direction='column' data-testid='add-grill-step-dropdown'>
      <Button
        disabled={disabled}
        id='action-btn'
        sx={{
          width: 'fit-content',
          color: 'kqn.white',
          bgcolor: 'kqn.darkerGray',
          '&:hover': { bgcolor: 'kqn.darkGray' },
          '&:disabled': { bgcolor: 'kqn.darkGray', color: 'kqn.white' },
          height: '40px',
          borderRadius: 2,
          px: 2,
        }}
        variant='contained'
        startIcon={<AddIcon sx={{ color: 'kqn.white', width: '20px', height: '20px' }} />}
        onClick={handleOpenMenu}
        aria-controls={isOpen ? 'action-menu' : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        ADD STEP
      </Button>
      {!disabled && (
        <Menu
          open={isOpen}
          id='action-menu'
          anchorEl={anchorEl}
          MenuListProps={{ 'aria-labelledby': 'action-btn' }}
          onClose={handleOnClose}
          onClick={handleOnClose}
        >
          <MenuItem
            onClick={(e) => handleMenuItemClick(e, 'standard')}
            data-testid='add-grill-step-button'
          >
            STANDARD
          </MenuItem>
          <MenuItem onClick={(e) => handleMenuItemClick(e, 'info')} data-testid='non-grill-button'>
            NON-GRILL
          </MenuItem>
        </Menu>
      )}
    </Stack>
  );
};

export default AddStepMenu;
