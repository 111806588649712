import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionMenu } from '../../../../components';
import { useMapKeyToObject } from '../../helpers/map-key-to-object';

type Props = {
  idx: number;
  count: number;
  title: string;
};

const colors = ['#F3E9E4', '#58757040', '#FFB6B4', '#EAEAEA', '#EFD6AC', '#B9B9B9', '#FFB6B4'];

const QuickActionItem: React.FC<Props> = ({ count, title, idx }) => {
  const navigate = useNavigate();
  const actionObj = useMapKeyToObject(title.toLowerCase() as any);

  if (!actionObj) return null;

  const handleOnCardClick = () => {
    if (actionObj.link) navigate(actionObj.link);
  };

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      alignItems='start'
      sx={{
        borderRadius: '2px',
        bgcolor: colors[idx] || '#F3E9E4',
        borderBottom: '4px solid #201D1D',
        py: 2.5,
        pl: 2.5,
        pr: 1.5,
        position: 'relative',
        width: '170px',
        height: '170px',
        cursor: 'pointer',
      }}
      onClick={handleOnCardClick}
    >
      <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
        <Stack sx={{ minHeight: '40px' }} justifyContent='center' alignItems='center '>
          {actionObj?.icon}
        </Stack>
        <ActionMenu orientation='horizontal' menuColor='#000' options={actionObj.options} />
      </Stack>
      <Typography variant='h4'>{count}</Typography>
      <Typography variant='body1'>Total {title}</Typography>
    </Stack>
  );
};

export default QuickActionItem;
