import { PaginatedResult } from '../../../types';
import { Ingredient, PageableIngredient } from '../../types';

export const mapIngredientsResponseToObject = (ingredients: PaginatedResult<Ingredient>) => {
  const defaultMeta = {
    total: 10,
    lastPage: 1,
    currentPage: 1,
    perPage: 10,
    prev: null,
    next: null,
  };

  const mapped: PageableIngredient[] = (ingredients?.data || []).map((ingredient: Ingredient) => ({
    ...ingredient,
    // energy: ingredient.nutrients.find(({ name }) => name === 'Energy')!,
    // protein: ingredient.nutrients.find(({ name }) => name === 'Protein')!,
    // fat: ingredient.nutrients.find(({ name }) => name === 'Fat')!,
    // carbs: ingredient.nutrients.find(({ name }) => name === 'Carbs')!,
    nutrients: ingredient.nutrients,
    units: ingredient.units,
    recipes: ingredient.groups,
    groups: [...new Set(ingredient.groups.map((group) => group))],
  }));

  return {
    meta: ingredients?.meta || defaultMeta,
    data: mapped,
  };
};
