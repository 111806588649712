import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../../context';
import { MainWrapper } from '../../../shared';
import LoginForm from '../components/login-form';

const LoginPage: React.FC = () => {
  const { user } = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true });
    }
  }, [navigate, user]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        bgcolor: '#b9b9b9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MainWrapper component='main' maxWidth='xs'>
        <LoginForm />
      </MainWrapper>
    </Box>
  );
};

export default LoginPage;
