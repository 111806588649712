import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Order } from '../../../shared/helpers/get-comparator';

export const sortableParams = [
  'name',
  'nameDe',
  'firstName',
  'lastName',
  'email',
  'namePlural',
  'nameDePlural',
];

const useTableSort = <T extends { name: string }>() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof T>('name');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    if (sortableParams.includes(String(property))) {
      searchParams.set('orderBy', isAsc ? 'desc' : 'asc');
      searchParams.set('sortBy', String(property));
    } else {
      searchParams.delete('orderBy');
      searchParams.delete('sortBy');
    }

    setSearchParams(searchParams);
  };

  return { order, orderBy, handleRequestSort };
};

export default useTableSort;
