import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecipeActions } from '../../../store';
import { useMealActions } from '../../../store/meal-store';
import { queries } from '../../queries';
import { RecipeComposer } from '../components';

const RecipesCreatePage = () => {
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get('recipeId');
  const { setRecipe, resetState, setAccessories, setNutrients } = useRecipeActions();
  const { resetState: resetMealState } = useMealActions();

  const { data: recipe, isFetching } = useQuery({
    ...queries.recipes.details(recipeId || undefined),
    enabled: !!recipeId,
  });

  useEffect(() => {
    if (recipe) {
      setRecipe(recipe);
      setAccessories(recipe.accessories || []);
      setNutrients({
        energy: recipe.energy,
        protein: recipe.protein,
        fat: recipe.fat,
        carbs: recipe.carbs,
      });
    }
  }, [recipe, setRecipe, setAccessories, setNutrients]);

  if (!recipeId) {
    resetState();
    resetMealState();
  }

  return <RecipeComposer isLoading={isFetching} />;
};
export default RecipesCreatePage;
