import { Node } from '../chart-types';
import FlowStep from './flow-step';

type Props = {
  nodes: Node[];
};

const FlowSteps: React.FC<Props> = ({ nodes }) => {
  return (
    <>
      {nodes.map((node) => {
        return <FlowStep key={node.stepId} step={node} />;
      })}
    </>
  );
};
export default FlowSteps;
