import { Stack } from '@mui/material';
import React from 'react';
import { Title } from '../../../../../../../../../components';
import { InputView } from '../../../../../../../../../shared/components/input-view';
import { GrillStep } from '../../../../../../../../types';

type Props = {
  preset: GrillStep;
};

const PresetInfoView: React.FC<Props> = ({ preset }) => {
  const { userInstruction, userInstructionDe, description, descriptionDe, hint, hintDe } = preset;

  return (
    <Stack direction='column' gap={1}>
      <Stack direction='row' justifyContent='space-between' gap={2} sx={{ width: '100%' }}>
        <Stack direction='column' gap={1} width='100%'>
          <Title title='User Instructions' />
          <InputView placeholder='User instruction EN' value={userInstruction} adornment='EN' />
          <InputView placeholder='User instruction DE' value={userInstructionDe} adornment='DE' />
        </Stack>
        <Stack direction='column' gap={1} width='100%'>
          <Title title='Title' />
          <InputView placeholder='Description EN' value={description} adornment='EN' />
          <InputView placeholder='Description DE' value={descriptionDe} adornment='DE' />
        </Stack>
      </Stack>
      {(hint || hintDe) && (
        <Stack direction='row' justifyContent='space-between' gap={2} sx={{ width: '100%' }}>
          <Stack direction='column' gap={1} width='100%'>
            <Title title='Hints (Optional)' />
            <InputView placeholder='Hint EN' value={hint} adornment='EN' />
            <InputView placeholder='Hint DE' value={hintDe} adornment='DE' />
          </Stack>
          <Stack direction='column' gap={1} width='100%' />
        </Stack>
      )}
    </Stack>
  );
};

export default PresetInfoView;
