import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

interface Props<T> {
  data: T[];
  onUpdate?: (data: T[]) => void;
}

export const useHandleDragEnd = <T extends { id?: string }>({ data, onUpdate }: Props<T>) => {
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id === over?.id) return;

    const oldIndex = data?.findIndex((p) => p.id === active.id);
    const newIndex = data?.findIndex((p) => p.id === over?.id);
    const newArr = arrayMove(data || [], oldIndex || 0, newIndex || 0);
    const updated = newArr.map((item, index) => {
      return {
        ...item,
        ordinal: index + 1,
      };
    });

    onUpdate?.(updated);
  };

  return handleDragEnd;
};
