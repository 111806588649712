import { Stack, Typography } from '@mui/material';
import React from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { JsonIcon } from '../../../../shared/assets';

type Props = {
  isDragActive?: boolean;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
};

const ImportDropzoneArea: React.FC<Props> = ({ getInputProps, getRootProps, isDragActive }) => {
  return (
    <Stack
      sx={{
        border: '1px dashed #B9B9B9',
        py: 2,
        width: '350px',
        borderRadius: '8px',
        ':hover': { cursor: 'pointer' },
      }}
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} data-testid='dropzone-input' />
        <Stack direction='column' gap={2} justifyContent='center' alignItems='center'>
          {isDragActive ? (
            <p>Drag JSON here..</p>
          ) : (
            <Stack direction='column' gap={1} justifyContent='center' alignItems='center'>
              <JsonIcon style={{ width: '50px', height: '50px', color: '#717174' }} />
              <Typography variant='body1' sx={{ px: 2, fontWeight: 'bold' }}>
                Drag JSON here
              </Typography>
              <Typography variant='caption'>or click to browse</Typography>
            </Stack>
          )}
        </Stack>
      </div>
    </Stack>
  );
};

export default ImportDropzoneArea;
