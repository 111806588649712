import { Box, Stack, Typography } from '@mui/material';
import { useTranslations } from '../../../../shared';

type Props = {
  language?: string;
};

const ResetSuccessfulPage: React.FC<Props> = ({ language }) => {
  const translations = useTranslations({ language });

  return (
    <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant='h6' fontWeight='bold'>
        {translations.resetPasswordSuccessTitle}
      </Typography>
      <Stack direction='column' gap={2}>
        <Typography variant='h6'>{translations.resetPasswordSuccessMessage}</Typography>
      </Stack>
    </Box>
  );
};

export default ResetSuccessfulPage;
