import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

type ViewType = 'table' | 'grid';

export const useViewSwitch = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setView = useCallback(
    (type: ViewType) => {
      searchParams.set('view', type);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const getView = useCallback(() => {
    const view = searchParams.get('view') as ViewType;
    if (!view) return 'grid';

    return view;
  }, [searchParams]);

  const removeView = useCallback(() => {
    searchParams.delete('view');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return { setView, view: getView(), removeView };
};
