import { useQuery } from '@tanstack/react-query';
import { queries } from '../../../../../../queries';

export const useGetPreparations = (id?: string | null) => {
  const { data, isLoading, isFetching } = useQuery({
    ...queries.preparations.all(id!),
    enabled: !!id,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};
