import { Stack, Typography } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useRecipesFilter } from '../../../../context';
import { useDebounceValue } from '../../../../shared';
import { queries } from '../../../queries';
import { CategoryResponse, RecipeBaseModel } from '../../../types';
import { RecipeCard } from '../recipe-card';
import RecipeCardSkeleton from '../recipe-card/recipe-card-skeleton';

type Props = {
  category?: CategoryResponse;
};

export const RecipeInfinityDataset: React.FC<Props> = ({ category }) => {
  const { query: filterQuery, data: filterData } = useRecipesFilter();
  const { category: selectedCategory, state } = filterData;
  const debouncedQuery = useDebounceValue(filterQuery);
  const { ref, inView } = useInView({ threshold: 0 });

  const { data, error, isFetchingNextPage, hasNextPage, fetchNextPage, isFetching } =
    useInfiniteQuery({
      ...queries.recipes.infinityFilter(
        {
          query: debouncedQuery,
          ...filterData,
          category: selectedCategory,
          state,
        },
        category,
      ),
      getNextPageParam: (metadata) => {
        if (metadata.meta.currentPage === metadata.meta.lastPage) {
          return undefined;
        }
        return metadata.meta.currentPage + 1;
      },
      keepPreviousData: true,
    });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  if (!isFetching && !data) return <div>No data available.</div>;
  if (!data) return null;
  if (error) return <div>Error fetching recipes</div>;

  return (
    <Stack gap={2} data-testid='recipes-card-view' width='100%'>
      <Stack direction='column' gap={2} width='100%'>
        <Typography
          variant='h6'
          sx={{
            color: 'kqn.darkGray',
            pt: 2,
          }}
        >
          {category?.name || 'ALL'} Recipes
        </Typography>
        {data.pages[0].data.length < 1 && (
          <Typography variant='body1' align='center' fontWeight='bold'>
            This category contains no recipes
          </Typography>
        )}
      </Stack>
      <Stack direction='row' gap={1} flexWrap='wrap' sx={{ overflowY: 'auto', width: '100%' }}>
        {data?.pages.map((pg) =>
          pg.data.map((recipe: RecipeBaseModel) => {
            return <RecipeCard key={recipe.id} recipe={recipe} />;
          }),
        )}
        {isFetchingNextPage && <RecipeCardSkeleton />}
      </Stack>
      <div ref={ref} />
    </Stack>
  );
};
