import { useSearchParams } from 'react-router-dom';

export const useHandleFilterParams = () => {
  const [searchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1', 10);
  const perPage = parseInt(searchParams.get('perPage') || '50', 10);
  const sortBy = String(searchParams.get('sortBy') || 'name');
  const orderBy = String(searchParams.get('orderBy') || 'asc');

  return { page, perPage, sortBy, orderBy };
};
