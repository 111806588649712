import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { unitService } from '../services/unit-service';

interface Props {
  unitId: string;
  onSuccess?: () => void;
}

export const useRemoveUnit = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, { unitId: string }>(({ unitId }) =>
    unitService.removeUnit(unitId),
  );

  const handleRemoveUnit = async ({ unitId, onSuccess }: Props) => {
    mutation.mutate(
      { unitId },
      {
        onSuccess: () => {
          queryClient.refetchQueries(queries.units.filter._def);
          queryClient.invalidateQueries(queries.units.all._def);
          toast.success('Unit deleted successfully');
          onSuccess?.();
        },
        onError: () => {
          toast.error('Something went wrong');
        },
      },
    );
  };

  return {
    handleRemoveUnit,
    isLoading: mutation.isLoading,
    mutation,
  };
};
