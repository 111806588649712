import { Toaster } from 'react-hot-toast';
import { ErrorIcon, SuccessIcon } from '../../shared';

const ToastNotification: React.FC = () => {
  return (
    <Toaster
      reverseOrder={false}
      containerStyle={{
        top: '60%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        padding: '0',
      }}
      toastOptions={{
        success: { icon: <SuccessIcon /> },
        error: { icon: <ErrorIcon /> },
        duration: 2000,
        style: {
          background: '#201D1D',
          color: '#FFF',
          padding: '16px',
        },
      }}
    />
  );
};

export default ToastNotification;
