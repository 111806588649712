import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Dropzone } from '../../../components';
import { useMounted } from '../../../shared/hooks';
import { queries } from '../../queries';
import { recipeService } from '../../recipes';

interface Props {
  recipeId: string | null;
  imageUrl: string;
  onUpload: (imageUrl: string) => void;
}

export const ImageUpload: React.FC<Props> = ({ recipeId, imageUrl, onUpload }) => {
  const queryClient = useQueryClient();
  const mounted = useMounted();
  if (!mounted) return null;

  const handleRemove = async () => {
    if (!recipeId) return;

    try {
      const response = await recipeService.removeImage(recipeId);

      if (response.status === 204) {
        toast.success('Image removed successfully');
        queryClient.refetchQueries(queries.recipes.filter._def);
        queryClient.refetchQueries(queries.recipes.details._def);
        queryClient.refetchQueries(queries.recipes.infinityFilter._def);
        onUpload('');
      }
    } catch (err: any) {
      throw err.response.data.message;
    }
  };

  return (
    <Dropzone recipeId={recipeId} imageUrl={imageUrl} onRemove={handleRemove} onUpload={onUpload} />
  );
};
