import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { ActionMenu, DiscardChangesModal } from '../../../../../../../../../../components';
import { CopyIcon } from '../../../../../../../../../../shared';
import { Notification } from '../../../../../../../../../types';
import { useDuplicateNotification } from './mutations';

type Props = {
  mealId: string;
  idx: number;
  stepId: string;
  notificationId: string;
  isDraft?: boolean;
  isActive?: boolean;
  onClick: (idx: string) => void;
  onRemoveNotification: (notificationId: string) => void;
  onDuplicateNotification: (notification: Notification) => void;
};

const NotificationTab: React.FC<Props> = ({
  idx,
  stepId,
  mealId,
  isActive = false,
  isDraft = false,
  notificationId,
  onClick,
  onRemoveNotification,
  onDuplicateNotification,
}) => {
  const { handleDuplicateNotification } = useDuplicateNotification();
  const [isOpen, setIsOpen] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: notificationId || '',
    transition: {
      duration: 1000, // milliseconds
      easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleDuplicate = useCallback(async () => {
    if (!notificationId || !stepId) return;

    await handleDuplicateNotification(mealId, notificationId, stepId, onDuplicateNotification);
  }, [handleDuplicateNotification, mealId, notificationId, onDuplicateNotification, stepId]);

  const handleRemove = useCallback(() => {
    if (!notificationId || !onRemoveNotification) return;

    onRemoveNotification(notificationId);
    setIsOpen(false);
  }, [notificationId, onRemoveNotification]);

  return (
    <Stack
      ref={setNodeRef}
      style={style}
      {...attributes}
      direction='row'
      alignItems='center'
      px={1}
      gap={1}
      justifyContent='space-between'
      onClick={() => onClick(notificationId)}
      sx={{
        height: '40px',
        cursor: 'pointer',
        color: 'kqn.darkerGray',
        bgcolor: isActive ? 'kqn.ultraLightCooper' : 'kqn.gray',
        borderTopLeftRadius: '9px',
        borderTopRightRadius: '9px',
        borderBottom: isActive ? '4px solid #AF6B48' : 'none',

        '&:hover': { bgcolor: 'kqn.ultraLightCooper' },
      }}
    >
      <Stack alignItems='center' gap={1} direction='row'>
        {isDraft ? (
          <CreateOutlinedIcon className='icon' sx={{ ml: 1 }} />
        ) : (
          <DragIndicatorIcon {...listeners} sx={{ color: 'kqn.darkerGray', cursor: 'grab' }} />
        )}
        <Typography sx={{ fontStyle: isDraft ? 'italic' : 'normal' }}>Substep {idx + 1}</Typography>
      </Stack>
      <ActionMenu
        orientation='horizontal'
        menuColor='#000'
        dissableRipple
        options={[
          { name: 'Duplicate', fn: handleDuplicate, icon: <CopyIcon />, isDisabled: isDraft },
          {
            name: 'Remove',
            fn: () => setIsOpen(true),
            icon: <DeleteOutlineIcon sx={{ color: 'kqn.coral' }} />,
          },
        ]}
      />
      <DiscardChangesModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleRemove}
        title='Remove notification?'
        content='Are you sure you want to remove this notification?'
      />
    </Stack>
  );
};
export default NotificationTab;
