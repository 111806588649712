import { z } from 'zod';

export const createUserSchema = z.object({
  firstName: z.string().min(2, 'First name must be at least 2 characters.'),
  lastName: z.string().min(2, 'Last name must be at least 2 characters.'),
  email: z.string().email('Invalid email address.'),
  password: z
    .string()
    .min(8, 'Password must have at least 8 characters.')
    .refine(
      (password) =>
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!”§$%&\\/()=?_@#;:+*-]).{8,32}$/.test(password),
      'Password must have at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character.',
    ),
  role: z.enum(['ADMIN', 'CUSTOMER', 'CONTENT MANAGER', 'TESTER', 'TECHNICIAN']),
});

export type UserSchemaType = z.infer<typeof createUserSchema>;
