import CloseIcon from '@mui/icons-material/Close';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { ImageFile } from '../../../types';
import { FileDetails } from './file-details';

export const FileInfoSection: React.FC<{
  file?: ImageFile;
  status?: string;
  progress: number;
  handleCancel: () => void;
}> = ({ file, status, progress, handleCancel }) => (
  <>
    <Stack alignItems='center' direction='row' gap={2} width='100%'>
      <Stack direction='column' alignItems='center' width='100%'>
        <Typography
          sx={{
            m: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {file?.name}
        </Typography>
        <Stack direction='column' gap={1}>
          <FileDetails file={file} status={status} />
          {status === 'loading' && (
            <LinearProgress
              variant='determinate'
              value={progress}
              sx={{
                width: '100%',
                bgcolor: '#58757099',
                '& .MuiLinearProgress-bar': { bgcolor: 'kqn.green' },
              }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
    <Box
      sx={{
        position: 'absolute',
        top: '-5px',
        right: '-5px',
        ':hover': { cursor: 'pointer' },
      }}
      onClick={handleCancel}
    >
      <CloseIcon />
    </Box>
  </>
);
