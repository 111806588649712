import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { userService } from '../services';

interface Props {
  ids: string[];
  onSuccess?: () => void;
}

export const useDeactivateUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(userService.deactivateAccounts);

  const handleDeactivateUser = async ({ ids, onSuccess }: Props) => {
    mutation.mutate(ids, {
      onSuccess: () => {
        queryClient.refetchQueries(queries.users.filter._def);
        toast.success('User has been successfully deactivated.');
        onSuccess?.();
      },
      onError: (err: any) => {
        toast.error(err || 'Something went wrong.');
      },
    });
  };
  return { handleDeactivateUser, isLoading: mutation.isLoading, mutation };
};
