import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../../../../../../../../../queries';
import { Notification } from '../../../../../../../../../../types';
import { notificationService } from '../../../service';

export const useUpdateNotificationsOrdinals = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, { notifications: Notification[] }>(
    ({ notifications }) => notificationService.updateNotificationsOrdinals(notifications),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.meals.filter._def);
        toast.success('Order updated successfully');
      },
      onError: () => {
        toast.error('Something went wrong');
      },
    },
  );

  return {
    mutation,
  };
};
