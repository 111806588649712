import { Stack } from '@mui/material';
import { MainLayout } from '../../../../../../../components';
import { AccessoriesPicker } from '../../../../../../accessories';
import { GroupsComposer } from './groups';
import IngredientsHeader from './ingredients-header';
import NumberOfPeople from './number-of-people';

interface Props {
  recipeId: string | null;
}

const IngredientsComposer: React.FC<Props> = ({ recipeId }) => {
  if (!recipeId) return null;

  return (
    <MainLayout>
      <Stack direction='column' gap={2} width='100%'>
        <IngredientsHeader />
        <Stack direction='row' gap={2} justifyContent='start'>
          <NumberOfPeople />
          <AccessoriesPicker />
        </Stack>
        <GroupsComposer recipeId={recipeId} />
      </Stack>
    </MainLayout>
  );
};

export default IngredientsComposer;
