import { Range } from '../../types';

export function combineRanges(ranges: Range[]) {
  if (ranges.length === 0) {
    return null;
  }

  ranges.sort((a, b) => a.start - b.start);

  let combinedRange = { ...ranges[0] };

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ranges.length; i++) {
    const currentRange = ranges[i];

    if (currentRange.start <= combinedRange.end) {
      combinedRange.end = Math.max(combinedRange.end, currentRange.end);
    } else {
      combinedRange = { ...currentRange };
    }
  }

  return combinedRange;
}
