import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  ComposerFooterButtons,
  KQNTextInputField,
  ModalComposer,
  TextInputAndormnet,
  Title,
} from '../../../components';
import { AccessoryModel } from '../../types';
import { useUpsertAccessory } from '../mutations';

interface Props {
  accessory: AccessoryModel;
  isOpen: boolean;
  onClose: () => void;
}

const updateAccessorySchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, 'Name is required'),
  nameDe: z.string().min(1, 'Name (German) is required'),
  description: z.string().optional(),
});

type UpdateAccessorySchemaType = z.infer<typeof updateAccessorySchema>;

const UpdateAccessoryModal: React.FC<Props> = ({ accessory, isOpen, onClose }) => {
  const { handleUpsertAccessory, isLoading } = useUpsertAccessory();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<UpdateAccessorySchemaType>({
    resolver: zodResolver(updateAccessorySchema),
    defaultValues: {
      id: accessory.id,
      name: accessory.name,
      nameDe: accessory.nameDe,
      description: accessory.description,
    },
  });

  const handleOnSubmit = async (data: UpdateAccessorySchemaType) => {
    await handleUpsertAccessory({
      data,
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <ModalComposer title='EDIT ACCESSORY' isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack gap={4}>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Stack gap={1}>
              <Title title='Accessory' />
              <Stack direction='row' gap={2}>
                <KQNTextInputField
                  control={control}
                  name='name'
                  error={errors.name}
                  fullWidth
                  InputProps={{
                    startAdornment: <TextInputAndormnet text='EN' position='start' />,
                  }}
                />
                <KQNTextInputField
                  control={control}
                  name='nameDe'
                  error={errors.nameDe}
                  fullWidth
                  InputProps={{
                    startAdornment: <TextInputAndormnet text='DE' position='start' />,
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <ComposerFooterButtons onClose={onClose} isLoading={isLoading} />
        </Stack>
      </form>
    </ModalComposer>
  );
};

export default UpdateAccessoryModal;
