import { koqoonApi } from '../../../config';
import { Paths } from '../../../constants';
import { MealTypeModel } from '../../types';

const basePath = Paths.MEAL_TYPES;

const getAllMealTypes = async () => {
  try {
    const response = await koqoonApi.get(`${basePath}`);

    return response.data as MealTypeModel[];
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const mealTypeService = {
  getAllMealTypes,
};
