import { HeadCell } from '../../../../types';
import { CategoriesHeadCells } from '../../../types';

const categoriesHeadCells: readonly HeadCell<CategoriesHeadCells>[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'EN',
  },
  {
    id: 'nameDe',
    numeric: false,
    disablePadding: false,
    label: 'DE',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'DESCRIPTION',
  },
  {
    id: 'recipeCount',
    numeric: true,
    disablePadding: false,
    label: 'ASSOCIATED RECIPES',
  },
];

export default categoriesHeadCells;
