import { createContext, useCallback, useContext, useMemo, useState } from 'react';

type Props = {
  children?: React.ReactNode;
};

const SearchContext = createContext<{
  query: string;
  setQuery: (searchQuery: string) => void;
  resetSearchQuery: () => void;
}>({
  query: '',
  setQuery: () => {},
  resetSearchQuery: () => {},
});

export const SearchContextProvider: React.FC<Props> = ({ children }) => {
  const [search, setSearch] = useState<string>('');

  const handleSearch = (searchQuery: string) => {
    setSearch(searchQuery);
  };

  const resetSearchQuery = useCallback(() => {
    setSearch('');
  }, []);

  const value = useMemo(
    () => ({ query: search, setQuery: handleSearch, resetSearchQuery }),
    [resetSearchQuery, search],
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useUserSearch must be used within a SearchProvider');
  }

  return context;
};
