interface RouteInfo {
  pathname?: string;
  icon?: any;
  title: string;
  path: string;
}

export const routes: RouteInfo[] = [
  {
    title: 'Overview',
    pathname: '/recipes/overview',
    path: 'overview',
  },
  {
    title: 'Overview',
    pathname: '/recipes/overview',
    path: 'details',
  },
  {
    title: 'Create',
    pathname: '/recipes/create',
    path: 'create',
  },
  {
    title: 'Categories',
    pathname: '/recipes/categories',
    path: 'categories',
  },
  {
    title: 'Ingredients',
    pathname: '/recipes/ingredients',
    path: 'ingredients',
  },
  {
    title: 'Units',
    pathname: '/recipes/units',
    path: 'units',
  },
  {
    title: 'Users',
    pathname: '/users',
    path: 'users',
  },
  {
    title: 'Settings',
    pathname: '/settings',
    path: 'settings',
  },
  {
    title: 'Accessories',
    pathname: '/recipes/accessories',
    path: 'accessories',
  },
];
