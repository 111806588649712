import { Divider, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { RecipeMetadata } from '../../../types';
import ImportDropzoneArea from './import-dropzone-area';
import MetadataDetails from './metadata-details';
import { recipeMetadataSchema } from './zod';

interface Props {
  onClose?: () => void;
}

const ImportDropzone: React.FC<Props> = ({ onClose }) => {
  const [metadata, setMetadata] = useState<RecipeMetadata | null>(null);
  // const [invalidData, setInvalidData] = useState<string[]>([]);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const metadataFile = acceptedFiles[0];
    if (metadataFile) {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const recipeMetadata = JSON.parse(reader.result as string) as RecipeMetadata;
          recipeMetadataSchema.parse(recipeMetadata);
          setMetadata(recipeMetadata);
        } catch (error) {
          // if (error instanceof ZodError) {
          //   const errSet = new Set<string>();
          //   error.errors.map((e) => {
          //     return errSet.add(String(e.path[0]));
          //   });

          // setInvalidData([...errSet]);
          // }

          toast.error('Failed to parse');
        }
      };
      reader.readAsText(metadataFile);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'application/json': ['.json'] },
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024, // 5MB make it smaller
    multiple: false,
    onDropRejected: () => {
      toast.error('Invalid file type or size');
    },
  });

  return (
    <Stack direction='column' gap={2} sx={{ bgcolor: 'kqn.white', p: 3, minWidth: '650px' }}>
      <Stack
        gap={2}
        sx={{ color: 'kqn.cooper' }}
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
      >
        <Typography variant='h6'>IMPORT RECIPE</Typography>
      </Stack>
      <Divider sx={{ borderColor: 'kqn.lightCooper' }} />
      <Typography>
        {!metadata &&
          'Upload a JSON file to import a recipe, then confirm to proceed with the import.'}
      </Typography>
      <MetadataDetails metadata={metadata} onClose={onClose} />
      {!metadata && (
        <Stack justifyContent='center' alignItems='center'>
          <ImportDropzoneArea
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            isDragActive={isDragActive}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ImportDropzone;
