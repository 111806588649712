import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useMealStore } from '../../../../../../../../../../../store/meal-store';
import { queries } from '../../../../../../../../../../queries';
import { Duration, Notification } from '../../../../../../../../../../types';
import { notificationService } from '../../../service';

export const useDuplicateNotification = () => {
  const queryClient = useQueryClient();
  const { updateDurations } = useMealStore.use.actions();

  const mutation = useMutation<
    { notification: Notification; durations: Duration[] },
    unknown,
    { notificationId: string; stepId: string }
  >(({ notificationId, stepId }) =>
    notificationService.duplicateNotification(notificationId, stepId),
  );

  const handleDuplicateNotification = async (
    mealId: string,
    notificationId: string,
    stepId: string,
    onUpdate?: (notification: Notification) => void,
  ) => {
    mutation.mutate(
      { notificationId, stepId },
      {
        onSuccess: ({ durations, notification }) => {
          queryClient.invalidateQueries(queries.meals.filter._def);
          if (onUpdate) {
            onUpdate(notification);
          }
          updateDurations(mealId, stepId, durations);
          toast.success('Notification duplicated successfully');
        },
        onError: () => {
          toast.error('Something went wrong');
        },
      },
    );
  };

  return {
    handleDuplicateNotification,
  };
};
