import { Stack } from '@mui/material';
import React from 'react';
import { PreparationInterval } from '..';
import { grillConfig } from '../../../../../../../../../../shared';
import { Burner, GrillStep } from '../../../../../../../../../types';
import Burners from './burners';

interface Props {
  step: GrillStep;
  mealId: string;
  burners?: Burner[];
  probes?: Burner[];
}

const BurnerTemperature: React.FC<Props> = ({ mealId, burners, probes, step }) => {
  return (
    <Stack direction='column' alignContent='space-between' sx={{ width: '100%' }}>
      <Stack direction='row' gap={2} sx={{ width: '100%' }} justifyContent='space-between'>
        <Stack justifyContent='start' width='100%' direction='row' gap={4}>
          <Burners
            mealId={mealId}
            stepId={step.id}
            type='burners'
            title='Zone Temperature °C'
            burners={burners || []}
            minTemperature={grillConfig.MIN_ZONE_TEMP}
            maxTemperature={grillConfig.MAX_ZONE_TEMP}
            tooltipText={`Temparature range for zone is ${grillConfig.MIN_ZONE_TEMP} - ${grillConfig.MAX_ZONE_TEMP}°C`}
          />
          <PreparationInterval mealId={mealId} stepId={step.id} prepInterval={step.prepInterval} />
        </Stack>
        <Stack justifyContent='start' width='100%'>
          <Burners
            type='probes'
            mealId={mealId}
            stepId={step.id}
            title='Probe Temperature °C'
            burners={probes || []}
            minTemperature={0}
            maxTemperature={grillConfig.MAX_PROBE_TEMP}
            tooltipText={`Max temperature for probe is ${grillConfig.MAX_PROBE_TEMP}°C`}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BurnerTemperature;
