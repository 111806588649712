/* eslint-disable no-param-reassign */
import { create, StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  AccessoryModel,
  CategoryModel,
  MealTypeModel,
  RecipeBaseModel,
  RecipeComplexityModel,
} from '../modules/types';
import { storeSelectors } from './create-selectors';
import { recipeInitialState } from './state';

type ImmerStateCreator<T> = StateCreator<T, [['zustand/immer', never], never], [], T>;
type NutrientState = Pick<RecipeBaseModel, 'energy' | 'carbs' | 'protein' | 'fat'>;
type RecipeValues = Pick<
  RecipeBaseModel,
  'name' | 'nameDe' | 'description' | 'descriptionDe' | 'imageUrl'
>;
type RecipeState = Omit<RecipeBaseModel, 'accessories' | 'fat' | 'carbs' | 'protein' | 'energy'>;
interface AccessoriesSlice {
  accessories: AccessoryModel[];
  initialAccessories: AccessoryModel[];
  accessoriesActions: {
    setAccessories: (accessories: AccessoryModel[]) => void;
    updateAccessory: (accessory: AccessoryModel) => void;
    updateAccessories: (accessories: AccessoryModel[]) => void;
    resetAccessories: () => void;
  };
}

interface NutrientsSlice {
  nutrients: NutrientState;
  initialNutrients: NutrientState;
  nutrientActions: {
    updateNutrient: (nutrient: 'energy' | 'carbs' | 'protein' | 'fat', value: number) => void;
    setNutrients: (
      nutrients: Pick<RecipeBaseModel, 'energy' | 'carbs' | 'protein' | 'fat'>,
    ) => void;
    resetNutrients: () => void;
  };
}

const createNutrientSlice: ImmerStateCreator<NutrientsSlice> = (set) => ({
  nutrients: { energy: 0, carbs: 0, protein: 0, fat: 0 },
  initialNutrients: { energy: 0, carbs: 0, protein: 0, fat: 0 },
  nutrientActions: {
    updateNutrient: (nutrient, value) => {
      set((state) => {
        state.nutrients[nutrient] = value;
      });
    },
    setNutrients: (nutrients) => {
      set((state) => {
        state.nutrients = nutrients;
        state.initialNutrients = nutrients;
      });
    },
    resetNutrients: () => {
      set((state) => {
        state.nutrients = state.initialNutrients;
      });
    },
  },
});

const createAccessoriesSlice: ImmerStateCreator<AccessoriesSlice> = (set) => ({
  accessories: [],
  initialAccessories: [],
  accessoriesActions: {
    setAccessories: (accessories) => {
      set((state) => {
        state.accessories = accessories;
        state.initialAccessories = accessories;
      });
    },
    resetAccessories() {
      set((state) => {
        state.accessories = state.initialAccessories;
      });
    },
    updateAccessory(accessory) {
      set((state) => {
        const selected = state.accessories.find((acc) => acc.id === accessory.id);
        if (selected) {
          state.accessories = state.accessories.filter((acc) => acc.id !== accessory.id);
          return;
        }

        state.accessories.push(accessory);
      });
    },
    updateAccessories(accessories) {
      set((state) => {
        state.accessories = accessories;
      });
    },
  },
});

type State = {
  recipe: RecipeState;
  initialData: RecipeState;
};

type Actions = {
  recipeActions: {
    setRecipe: (recipes: RecipeBaseModel) => void;
    updateRecipeValue: (key: keyof RecipeValues, value: string) => void;
    updatePublished: (isPublished: boolean) => void;
    updateMaxNumberOfPeople: (maxNumberOfPeople: number) => void;
    setMaxNumberOfPeople: (maxNumberOfPeople: number) => void;
    increaseNumberOfPeople: () => void;
    decreaseNumberOfPeople: () => void;
    updateMealType: (mealType: MealTypeModel) => void;
    updateComplexity: (complexity: RecipeComplexityModel) => void;
    updateCookingTime: (cookingTime: number) => void;
    updateCategory: (category: CategoryModel) => void;
    setFile: (file: any) => void;
    setInitialState: () => void;
    resetState: () => void;
  };
};

type RecipesSlice = State & Actions;

const createRecipeSlice: ImmerStateCreator<RecipesSlice> = (set) => ({
  recipe: recipeInitialState,
  initialData: recipeInitialState,
  accessories: [],
  initialAccessories: [],
  recipeActions: {
    setMaxNumberOfPeople: (value) => {
      set((state) => {
        state.recipe.maxNumberOfPeople = value;
        state.initialData.maxNumberOfPeople = value;
      });
    },
    setRecipe: (recipe) => {
      set((state) => {
        state.recipe = recipe;
        state.initialData = recipe;
      });
    },
    updatePublished: (isPublished) => {
      set((state) => {
        if (state.recipe) {
          state.recipe.isPublished = isPublished;
        }
      });
    },
    setInitialState: () => {
      set((state) => {
        state.recipe = state.initialData;
        state.initialData = state.recipe;
      });
    },
    resetState: () => {
      set((state) => {
        state.recipe = recipeInitialState;
        state.initialData = recipeInitialState;
      });
    },
    updateRecipeValue: (
      key: keyof Pick<
        RecipeBaseModel,
        'name' | 'nameDe' | 'description' | 'descriptionDe' | 'imageUrl'
      >,
      value: string,
    ) => {
      set((state) => {
        if (state.recipe) {
          state.recipe[key] = value;
        }
      });
    },
    updateMaxNumberOfPeople: (maxNumberOfPeople: number) => {
      set((state) => {
        if (state.recipe) {
          state.recipe.maxNumberOfPeople = maxNumberOfPeople;
        }
      });
    },
    updateComplexity: (complexity: RecipeComplexityModel) => {
      set((state) => {
        if (state.recipe) {
          state.recipe.complexity = complexity;
        }
      });
    },
    updateCookingTime: (cookingTime: number) => {
      set((state) => {
        if (state.recipe) {
          state.recipe.cookingTime = cookingTime;
        }
      });
    },
    updateCategory: (category: CategoryModel) => {
      set((state) => {
        if (state.recipe) {
          const isSelected = state.recipe.categories.some((cat) => cat.id === category.id);
          if (isSelected) {
            state.recipe.categories = state.recipe.categories.filter(
              (cat) => cat.id !== category.id,
            );
            return;
          }

          state.recipe.categories.push(category);
        }
      });
    },
    increaseNumberOfPeople: () => {
      set((state) => {
        if (state.recipe) {
          state.recipe.maxNumberOfPeople += 1;
        }
      });
    },
    decreaseNumberOfPeople: () => {
      set((state) => {
        if (state.recipe) {
          state.recipe.maxNumberOfPeople -= 1;
        }
      });
    },
    updateMealType: (mealType: MealTypeModel) => {
      set((state) => {
        if (state.recipe) {
          const isSelected = state.recipe.mealTypes.some((type) => type.id === mealType.id);
          if (isSelected) {
            state.recipe.mealTypes = state.recipe.mealTypes.filter(
              (type) => type.id !== mealType.id,
            );
          } else {
            state.recipe.mealTypes.push(mealType);
          }
        }
      });
    },
    setFile: (file) => {
      set((state) => {
        if (state.recipe) {
          state.recipe.file = file;
        }
      });
    },
  },
});

const useRecipes = create<AccessoriesSlice & RecipesSlice & NutrientsSlice>()(
  immer((...a) => ({
    ...createAccessoriesSlice(...a),
    ...createRecipeSlice(...a),
    ...createNutrientSlice(...a),
  })),
);

export const useRecipeStore = storeSelectors(useRecipes);
export const useRecipeActions = () => ({
  ...useRecipeStore.use.recipeActions(),
  ...useRecipeStore.use.accessoriesActions(),
  ...useRecipeStore.use.nutrientActions(),
});
