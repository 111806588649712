import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from '../../../constants';
import {
  AccessoriesIcon,
  CategoriesIcon,
  IngredientsIcon,
  RecipesIcon,
  UsersIcon,
} from '../../../shared';
import { ActionMenuItem } from '../../../types';

type Key =
  | 'recipes'
  | 'ingredients'
  | 'categories'
  | 'customers'
  | 'users'
  | 'units'
  | 'accessories';

export const useMapKeyToObject = (key: Key) => {
  const navigate = useNavigate();

  switch (key) {
    case 'recipes':
      return {
        icon: <RecipesIcon className='icon' />,
        link: NavigationPaths.OVERVIEW,
        options: [
          { name: 'Add Recipe', fn: () => navigate('/recipes/create') },
        ] satisfies ActionMenuItem[],
      };
    case 'ingredients':
      return {
        icon: <IngredientsIcon className='icon' />,
        link: NavigationPaths.INGREDIENTS,
        options: [
          { name: 'Add Ingredient', fn: () => navigate('/recipes/ingredients?modal=true') },
        ] satisfies ActionMenuItem[],
      };
    case 'categories':
      return {
        icon: <CategoriesIcon className='icon' />,
        link: NavigationPaths.CATEGORIES,
        options: [
          { name: 'Add Category', fn: () => navigate('/recipes/categories?modal=true') },
        ] satisfies ActionMenuItem[],
      };
    case 'customers':
      return {
        icon: <UsersIcon className='icon' />,
        link: NavigationPaths.USERS,
        options: [
          { name: 'Add User', fn: () => navigate('/admin/users?modal=true') },
        ] satisfies ActionMenuItem[],
      };
    case 'users':
      return {
        icon: <UsersIcon className='icon' />,
        link: NavigationPaths.USERS,
        options: [
          { name: 'Add User', fn: () => navigate('/admin/users?modal=true') },
        ] satisfies ActionMenuItem[],
      };
    case 'units':
      return {
        icon: <UsersIcon className='icon' />,
        link: NavigationPaths.UNITS,
        options: [
          { name: 'Add Unit', fn: () => navigate('/recipes/units?modal=true') },
        ] satisfies ActionMenuItem[],
      };
    case 'accessories':
      return {
        icon: <AccessoriesIcon className='icon' />,
        link: NavigationPaths.ACCESSORIES,
        options: [
          { name: 'Add Accessory', fn: () => navigate('/recipes/accessories?modal=true') },
        ] satisfies ActionMenuItem[],
      };
    default:
      return null;
  }
};
