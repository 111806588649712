import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';
import { MainLayout } from '../layout';
import { Navigation } from '../navigation';
import { TableWrapper } from '../table';

interface Props {
  type?: 'single' | 'double';
  title?: string;
}

const DetailsTableSkeleton: React.FC<Props> = ({ title, type = 'double' }) => {
  return (
    <TableWrapper>
      <Box sx={{ width: '100%' }}>
        <Navigation />
        <MainLayout>
          <Stack direction='row' gap={4} justifyContent='flex-start'>
            <Skeleton variant='rounded' width={500} height={400} />
            {type === 'double' && <Skeleton variant='rounded' width={500} height={400} />}
          </Stack>
        </MainLayout>
      </Box>
    </TableWrapper>
  );
};

export default DetailsTableSkeleton;
