import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastNotification } from '../../components';
import { AuthenticationProvider } from '../../context';
import AppRoutes from './app-routes';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        staleTime: 1000 * 60 * 5, // 5 minutes
        retry: 1,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthenticationProvider>
          <AppRoutes />
          <ToastNotification />
        </AuthenticationProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
