import { Stack, Typography } from '@mui/material';
import MealTypeCardBase from './meal-type-card-base';

interface Props {
  onClick: () => void;
}

const NewMealCard: React.FC<Props> = ({ onClick }) => {
  return (
    <MealTypeCardBase>
      <Stack
        direction='row'
        sx={{ p: 1 }}
        justifyContent='center'
        alignItems='center'
        height='100%'
        onClick={onClick}
        data-testid='add-new-meal-type-button'
      >
        <Typography variant='body1'>+ Add new meal</Typography>
      </Stack>
    </MealTypeCardBase>
  );
};
export default NewMealCard;
