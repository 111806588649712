import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { categoriesService } from '../services/categories-service';

interface Data {
  categoryId: string;
}

interface Props {
  onSuccess?: () => void;
}

export const useRemoveCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, Data>(({ categoryId }) =>
    categoriesService.deleteCategory(categoryId),
  );

  const handleRemoveCategory = async ({ categoryId, onSuccess }: Data & Props) => {
    mutation.mutate(
      { categoryId },
      {
        onSuccess: () => {
          queryClient.refetchQueries(queries.categories.filter._def);
          queryClient.refetchQueries(queries.recipeCategories.all._def);
          toast.success('Category removed successfully');
          onSuccess?.();
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    mutation,
    handleRemoveCategory,
    isLoading: mutation.isLoading,
  };
};
