import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { queries } from '../../../../../../../../queries';

export const useGetBurners = () => {
  const { data, isError } = useQuery({
    ...queries.burners.all(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const probes = useMemo(
    () =>
      data
        ?.filter((b) => b.isProbe)
        .map((p) => {
          return { ...p, temperature: 0 };
        }),
    [data],
  );
  const burners = useMemo(
    () =>
      data
        ?.filter((b) => !b.isProbe)
        .map((p) => {
          return { ...p, temperature: 0 };
        }),
    [data],
  );

  if (isError) {
    return {
      probes: [],
      burners: [],
    };
  }

  return {
    probes,
    burners,
  };
};
