import { toast } from 'react-hot-toast';
import { ConfirmModal } from '../../../../components';
import { Ingredient } from '../../../types';
import { useRemoveIngredients } from '../../mutations';

type Props = {
  ingredients?: Ingredient[];
  isOpen: boolean;
  onClose: () => void;
  onSucess?: () => void;
};

function separateIngredients(ingredients?: Ingredient[]) {
  if (!ingredients) return { ingredientsWithGroups: [], ingredientsWithoutGroups: [] };
  const ingredientsWithGroups: Ingredient[] = [];
  const ingredientsWithoutGroups: Ingredient[] = [];

  ingredients.forEach((ingredient) => {
    if (ingredient.groups.length > 0) {
      ingredientsWithGroups.push(ingredient);
      return;
    }
    ingredientsWithoutGroups.push(ingredient);
  });

  return { ingredientsWithGroups, ingredientsWithoutGroups };
}

const DeleteIngredientModal: React.FC<Props> = ({ ingredients, isOpen, onClose, onSucess }) => {
  const { ingredientsWithGroups, ingredientsWithoutGroups } = separateIngredients(ingredients);
  const ingredientIds = ingredientsWithoutGroups?.map((ingredient) => ingredient.id);
  const { handleRemoveIngredients, isLoading } = useRemoveIngredients();

  const onConfirm = async () => {
    const names = ingredientsWithGroups.map((ingredient) => ingredient.name).join(', ');
    if (!ingredientIds.length) {
      toast.error(`The following ingredients cannot be deleted: ${names}.`);
      onClose();
      return;
    }

    await handleRemoveIngredients({
      names,
      ingredientIds,
      ingredientsWithGroups,
      ingredientsWithoutGroups,
      onSucess: () => {
        onSucess?.();
        onClose();
      },
    });
  };

  return (
    <ConfirmModal isOpen={isOpen} onClose={onClose} onCofirm={onConfirm} isProcessing={isLoading} />
  );
};

export default DeleteIngredientModal;
