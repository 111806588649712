import { TablePagination } from '@mui/material';
import { fetchConfig } from '../../shared';

type Props = {
  total: number;
  perPage: number;
  page: number;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Pagination: React.FC<Props> = ({
  onPageChange,
  onRowsPerPageChange,
  page,
  perPage,
  total,
}) => {
  return (
    <TablePagination
      data-testid='table-pagination'
      showFirstButton
      showLastButton
      rowsPerPageOptions={fetchConfig.rowsPerPageOptions}
      component='div'
      count={total}
      rowsPerPage={perPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
};
