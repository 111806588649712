import { useCallback, useState } from 'react';
import { useErrorContext } from '../../../context';
import { useResetState } from './use-reset-state';

export const useRecipeComposerActions = () => {
  const { resetAllState } = useResetState();
  const { resetError } = useErrorContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onDiscard = useCallback(() => {
    resetAllState();
    setIsOpen(false);
    resetError();
  }, [resetAllState, resetError]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    onDiscard,
    handleOpen,
    handleClose,
  };
};
