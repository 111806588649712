import { ConfirmModal } from '../../../components';
import { useRemoveAccessory } from '../mutations/use-remove-accessory';

type Props = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  onSucess?: () => void;
};

const DeleteAccessoryModal: React.FC<Props> = ({ id, isOpen, onClose, onSucess }) => {
  const { handleRemoveAccessory, isLoading } = useRemoveAccessory();

  const onConfirm = async () => {
    if (!id) return;

    await handleRemoveAccessory({
      id,
      onSuccess: () => {
        onSucess?.();
        onClose();
      },
    });
  };

  return (
    <ConfirmModal isOpen={isOpen} onClose={onClose} onCofirm={onConfirm} isProcessing={isLoading} />
  );
};

export default DeleteAccessoryModal;
