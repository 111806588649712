import { zodResolver } from '@hookform/resolvers/zod';
import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  ComposerFooterButtons,
  KQNTextInputField,
  TextInputAndormnet,
} from '../../../../components';
import { PageableUnit } from '../../../types';
import { UnitSchemaType, upsertUnitSchema } from './schema';

type Props = {
  unit?: PageableUnit;
  onSubmit: (data: UnitSchemaType) => void;
  onClose: () => void;
  isSubmitting?: boolean;
};

const UpsertUnitForm: React.FC<Props> = ({ unit, onSubmit, onClose, isSubmitting }) => {
  const { control, handleSubmit } = useForm<UnitSchemaType>({
    resolver: zodResolver(upsertUnitSchema),
    defaultValues: {
      name: unit?.name || '',
      nameDe: unit?.nameDe || '',
      namePlural: unit?.namePlural || '',
      namePluralDe: unit?.nameDePlural || '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack pt={2} gap={4}>
        <Stack gap={2}>
          <Stack gap={1}>
            <Typography variant='body1'>Singular Name</Typography>
            <Stack
              direction='row'
              justifyContent='space-between'
              gap={2}
              alignContent='center'
              alignItems='center'
            >
              <KQNTextInputField
                name='name'
                control={control}
                size='small'
                fullWidth
                placeholder='Unit (EN)'
                InputProps={{
                  startAdornment: <TextInputAndormnet text='EN' position='start' />,
                }}
              />
              <KQNTextInputField
                placeholder='Unit (DE)'
                name='nameDe'
                control={control}
                InputProps={{
                  startAdornment: <TextInputAndormnet text='DE' position='start' />,
                }}
              />
            </Stack>
          </Stack>
          <Stack gap={1}>
            <Typography variant='body1'>Plural Name</Typography>
            <Stack
              direction='row'
              justifyContent='space-between'
              gap={2}
              alignContent='center'
              alignItems='center'
            >
              <KQNTextInputField
                placeholder='Unit Plural (EN)'
                name='namePlural'
                InputProps={{
                  startAdornment: <TextInputAndormnet text='EN' position='start' />,
                }}
                control={control}
              />
              <KQNTextInputField
                size='small'
                placeholder='Unit Plural (DE)'
                name='namePluralDe'
                InputProps={{
                  startAdornment: <TextInputAndormnet text='DE' position='start' />,
                }}
                control={control}
              />
            </Stack>
          </Stack>
        </Stack>
        <ComposerFooterButtons onClose={onClose} isLoading={isSubmitting} />
      </Stack>
    </form>
  );
};

export default UpsertUnitForm;
