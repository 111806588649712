import { koqoonApi } from '../../../config';
import { Burner, MethodType, ModeType } from '../../types';

const burnersPath = '/burners';

const getBurnerModes = async (): Promise<ModeType[]> => {
  try {
    const response = await koqoonApi.get(`${burnersPath}/modes`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getBurnerMethods = async (): Promise<MethodType[]> => {
  try {
    const response = await koqoonApi.get(`${burnersPath}/methods`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getBurners = async (): Promise<Burner[]> => {
  try {
    const response = await koqoonApi.get(`${burnersPath}`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const burnerService = {
  getBurners,
  getBurnerModes,
  getBurnerMethods,
};
