import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useTableClick = () => {
  const [selected, setSelected] = useState<string[]>([]);
  const [params] = useSearchParams();
  const itemId = params.get('id');

  useEffect(() => {
    if (itemId) {
      setSelected([itemId]);
    }
  }, [itemId]);

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  return { selected, setSelected, handleClick };
};

export default useTableClick;
