import { Box, MenuItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { CreateButton, Dropdown, Optional, SearchFilter } from '../../../../components';
import { Role } from '../../../../constants';
import { useAuthentication } from '../../../../context';
import { PageableUser } from '../../../types';
import { userService } from '../../services/user-service';
import {
  ActivateUserModal,
  CreateUserModal,
  DeactivateUsersModal,
  DeleteUserModal,
  UpdateUserModal,
} from '../modals';

type Props = {
  user?: PageableUser;
  readonly selected: string[];
  setSelected: (selected: string[]) => void;
};

export const UserEnhancedTableActions: React.FC<Props> = ({ user, selected, setSelected }) => {
  const [params, setSearchParams] = useSearchParams();
  const isCreateModalOpen = params.get('modal') === 'true';
  const id = params.get('id');
  const { user: authUser } = useAuthentication();
  const [openDelete, setOpenDeleteModal] = useState<boolean>(false);
  const [openUser, setOpenUserModal] = useState<boolean>(isCreateModalOpen || false);
  const [openEdit, setOpenEditModal] = useState<boolean>(false);
  const [openDeactivate, setOpenDeactivateModal] = useState<boolean>(false);
  const [openActivation, setOpenActivation] = useState<boolean>(false);
  const isAdmin = authUser?.role === Role.ADMIN;

  useEffect(() => {
    if (id && !!selected.length) {
      setOpenEditModal(true);
    }
  }, [id, selected.length]);

  const handleOnDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleActivation = () => {
    setOpenActivation(true);
  };

  const handleOnEdit = () => {
    setOpenEditModal(true);
  };

  const handleOnEditClose = () => {
    params.delete('id');
    setSearchParams(params);
    setOpenEditModal(false);
  };

  const handleUserCreateModal = () => {
    setOpenUserModal(true);
  };

  const handleResendEmail = async () => {
    try {
      if (!user) return;
      const response = await userService.resendEmail(user.email);
      if (response.status === 200) {
        toast.success(response.data.message);
      }
    } catch (e: any) {
      toast.error(e);
    }
  };

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
        justifyItems='center'
        data-testid='enchanched-table-actions'
        sx={{ pt: 1 }}
      >
        <SearchFilter />
        <Stack direction='row' gap={2}>
          <Optional condition={!!selected.length && authUser?.role === 'ADMIN'}>
            <Dropdown label='ACTION'>
              {selected.length === 1 && (
                <MenuItem
                  onClick={handleOnEdit}
                  data-testid='edit-user'
                  sx={{ borderBottom: '1px solid #AF6B48' }}
                >
                  Edit
                </MenuItem>
              )}
              {selected.length === 1 && (
                <MenuItem
                  onClick={handleResendEmail}
                  data-testid='resend-email'
                  sx={{ borderBottom: '1px solid #AF6B48' }}
                >
                  Resend Email
                </MenuItem>
              )}
              <MenuItem
                onClick={handleActivation}
                data-testid='activate-user'
                sx={{ borderBottom: '1px solid #AF6B48' }}
              >
                Activate
              </MenuItem>
              <MenuItem
                onClick={() => setOpenDeactivateModal(true)}
                data-testid='deactivate-user'
                sx={{ borderBottom: '1px solid #AF6B48' }}
              >
                Deactivate
              </MenuItem>
              {selected.length === 1 && (
                <MenuItem
                  onClick={handleOnDelete}
                  sx={{ color: 'kqn.coral' }}
                  data-testid='delete-user'
                >
                  Delete
                </MenuItem>
              )}
            </Dropdown>
          </Optional>
          {isAdmin && <CreateButton text='ADD' onClick={handleUserCreateModal} />}
        </Stack>
      </Box>
      <Optional condition={openDelete}>
        <DeleteUserModal
          userId={user?.id}
          isOpen={openDelete}
          onClose={() => setOpenDeleteModal(false)}
          onSucess={() => setSelected([])}
        />
      </Optional>
      <Optional condition={openDeactivate}>
        <DeactivateUsersModal
          selected={selected}
          isOpen={openDeactivate}
          onClose={() => setOpenDeactivateModal(false)}
        />
      </Optional>
      <Optional condition={openUser}>
        <CreateUserModal isOpen={openUser} onClose={() => setOpenUserModal(false)} />
      </Optional>
      <Optional condition={openActivation}>
        <ActivateUserModal
          selected={selected}
          isOpen={openActivation}
          onClose={() => setOpenActivation(false)}
        />
      </Optional>
      <Optional condition={openEdit && user}>
        <UpdateUserModal user={user!} isOpen={openEdit} onClose={handleOnEditClose} />
      </Optional>
    </>
  );
};
