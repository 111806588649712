import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { queries } from '../../../queries';
import { CategoryModel, MealTypeModel, RecipeComplexityModel } from '../../../types';
import { CategoriesPicker, ComplexityPicker, MealTypesPicker } from '../pickers';
import RecipeInfoSkeleton from './recipe-info-skeleton';

interface Props {
  complexity: RecipeComplexityModel;
  mealTypes: MealTypeModel[];
  categories: CategoryModel[];
  onUpdateComplexity?: (complexity: RecipeComplexityModel) => void;
  onUpdateMealTypes?: (mealTypes: MealTypeModel) => void;
  onUpdateCategories?: (categories: CategoryModel) => void;
}

const RecipeInfoFilters: React.FC<Props> = ({
  categories,
  complexity,
  mealTypes,
  onUpdateCategories,
  onUpdateComplexity,
  onUpdateMealTypes,
}) => {
  const { data, isLoading } = useQuery(queries.filters.all());

  if (isLoading) return <RecipeInfoSkeleton />;

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      data-testid='recipe-info-filters'
      gap={2}
      sx={{ width: '100%' }}
    >
      <ComplexityPicker
        pickedComplexity={complexity}
        complexities={data?.complexities}
        updateComplexity={onUpdateComplexity}
      />
      <MealTypesPicker
        pickedMealTypes={mealTypes}
        mealTypes={data?.mealTypes}
        updateMealType={onUpdateMealTypes}
      />
      <CategoriesPicker
        pickedCategories={categories}
        categories={data?.categories}
        updateCategory={onUpdateCategories}
      />
    </Stack>
  );
};

export default RecipeInfoFilters;
