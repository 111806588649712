import { Stack, SxProps, Theme } from '@mui/material';
import { KQNTextField, TextInputAndormnet } from '../../components';

type Props = {
  value?: any;
  placeholder?: string;
  adornment?: string;
  inputProps?: SxProps<Theme>;
};

export const InputView = ({ value, adornment, inputProps, placeholder }: Props) => {
  return (
    <Stack
      sx={{
        minHeight: '40px',
        minWidth: '120px',
      }}
      direction='row'
      alignItems='center'
      justifyContent='start'
    >
      <KQNTextField
        multiline
        size='small'
        name='name'
        fullWidth
        value={value}
        placeholder={placeholder}
        InputProps={{
          sx: { bgcolor: 'kqn.white', minHeight: '40px', ...inputProps },
          startAdornment: adornment ? <TextInputAndormnet text={adornment || ''} /> : null,
        }}
        disabled
      />
    </Stack>
  );
};
