import { ChartStep } from '../chart-types';

export function getDuration(step: ChartStep) {
  if (step.type === 'info') return step?.durations?.[0].duration || 0;

  const durationPriority = ['Medium', 'Duration', 'Est. Duration', ''];
  const duration = (step?.durations || []).find((d) => durationPriority.includes(d.name));

  return duration?.duration || 0;
}
