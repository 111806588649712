import {
  Box,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { createId } from '@paralleldrive/cuid2';
import { TableWrapper, WrapperDiv } from '..';
import { MainLayout } from '../layout';

type Props = {
  numberOfColumns?: number;
  numberOfRows?: number;
};

const TableSkeleton: React.FC<Props> = ({ numberOfColumns = 10, numberOfRows = 10 }) => {
  return (
    <TableWrapper data-testid='loading-skeleton'>
      <Box sx={{ width: '100%' }}>
        <MainLayout>
          <WrapperDiv>
            <Stack>
              <Skeleton variant='text' width='200px' height={50} />
              <Skeleton variant='text' width='150px' height={50} />
            </Stack>
            <Stack direction='row' gap={2}>
              <Skeleton
                variant='rectangular'
                width='150px'
                height={40}
                sx={{ borderRadius: '8px' }}
              />
              <Skeleton
                variant='rectangular'
                width='150px'
                height={40}
                sx={{ borderRadius: '8px' }}
              />
            </Stack>
          </WrapperDiv>

          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
                <TableBody>
                  {Array.from(new Array(numberOfColumns)).map((_, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={idx}>
                      {Array.from(new Array(numberOfRows)).map(() => (
                        <TableCell key={createId()}>
                          <Skeleton
                            variant='rectangular'
                            width='100%'
                            height={30}
                            sx={{
                              '&:nth-of-type(odd)': {
                                backgroundColor: '#f2f2f2',
                              },
                              '&&:nth-of-type(odd):hover > *': {
                                backgroundColor: '#f2f2f2',
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </MainLayout>
      </Box>
    </TableWrapper>
  );
};

export default TableSkeleton;
