import { Node } from '../chart-types';

/**
 * This function is used to check if the two nodes are overlapping with each other.
 * Previous (Existing) node can be before or after the current node.
 */
export function isOverlapping(currNode: Node, prevNode?: Node) {
  if (!prevNode) return false;
  return prevNode && (prevNode?.endOffset || 0) > (currNode?.startOffset || 0);
}
