import { Box, MenuItem, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CreateButton, Dropdown, Optional, SearchFilter } from '../../../components';
import { Role } from '../../../constants';
import { useAuthentication } from '../../../context';
import { AccessoryModel } from '../../types';
import CreateAccessoryModal from './create-accessory-modal';
import DeleteAccessoryModal from './delete-accessory-modal';
import UpdateAccessoryModal from './update-accessory-modal';

type Props = {
  accessory?: AccessoryModel;
  readonly selected: string[];
};

const AccessoriesEnhancedTableActions: React.FC<Props> = ({ accessory, selected }) => {
  const [params, setSearchParams] = useSearchParams();
  const isCreateModalOpen = params.get('modal') === 'true';
  const { user: authUser } = useAuthentication();
  const [deletePrompt, setDeletePrompt] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(isCreateModalOpen || false);
  const isTester = authUser?.role === Role.TESTER;

  const handleOnDelete = async () => {
    setDeletePrompt(true);
  };

  const handleOpenEdit = async () => {
    setEditOpen(true);
  };

  const handleOnCreate = async () => {
    setOpenCreate(true);
  };

  const handleOnClose = () => {
    params.delete('modal');
    setSearchParams(params);
    setOpenCreate(false);
  };

  return (
    <Stack direction='column' gap={2} width='100%'>
      <Stack direction='row' justifyContent='space-between' sx={{ pt: 1 }}>
        <SearchFilter />
        <Box
          display='flex'
          width='100%'
          flexDirection='row'
          alignItems='center'
          gap={4}
          justifyContent='flex-end'
        >
          <Stack direction='row' gap={1.5}>
            <Optional condition={selected.length === 1 && !isTester}>
              <Dropdown label='ACTION'>
                <MenuItem
                  onClick={handleOpenEdit}
                  data-testid='edit-button'
                  sx={{ borderBottom: '1px solid #AF6B48' }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={handleOnDelete}
                  data-testid='delete-button'
                  sx={{ color: 'kqn.coral' }}
                >
                  Delete
                </MenuItem>
              </Dropdown>
            </Optional>
            {!isTester && <CreateButton text='ADD' onClick={handleOnCreate} />}
          </Stack>
        </Box>
        <Optional condition={deletePrompt}>
          <DeleteAccessoryModal
            isOpen={deletePrompt}
            onClose={() => setDeletePrompt(false)}
            id={accessory?.id}
          />
        </Optional>
        <Optional condition={editOpen && accessory}>
          <UpdateAccessoryModal
            isOpen={editOpen}
            onClose={() => setEditOpen(false)}
            accessory={accessory!}
          />
        </Optional>
      </Stack>
      <Optional condition={openCreate}>
        <CreateAccessoryModal isOpen={openCreate} onClose={handleOnClose} />
      </Optional>
    </Stack>
  );
};

export default AccessoriesEnhancedTableActions;
