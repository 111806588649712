import { HeadCell } from '../../../../types';
import { IngredientHeadCells } from '../../../types';

const ingredientHeadCells: readonly HeadCell<IngredientHeadCells>[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'EN',
  },
  {
    id: 'nameDe',
    numeric: false,
    disablePadding: false,
    label: 'DE',
  },
  {
    id: 'recipesCount',
    numeric: true,
    disablePadding: false,
    label: 'ASSOCIATED RECIPES',
  },
];

export default ingredientHeadCells;
