import AddIcon from '@mui/icons-material/Add';
import { ModalComposer } from '../../../../components';
import { useCreateUnit } from '../../mutations';
import { UnitSchemaType, UpsertUnitForm } from '../forms';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateUnitModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { hnadleCreateUnit, isLoading } = useCreateUnit();

  const handleOnSubmit = async (data: UnitSchemaType) => {
    await hnadleCreateUnit({ data, onSuccess: onClose });
  };

  return (
    <ModalComposer
      title='ADD UNIT'
      isOpen={isOpen}
      onClose={onClose}
      icon={<AddIcon className='icon' />}
    >
      <UpsertUnitForm onSubmit={handleOnSubmit} onClose={onClose} isSubmitting={isLoading} />
    </ModalComposer>
  );
};

export default CreateUnitModal;
