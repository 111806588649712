import { zodResolver } from '@hookform/resolvers/zod';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { queries } from '../../modules/queries';
import { PageableUser } from '../../modules/types';
import { KQNTextField, KQNTextInputField } from '../text-input-field';
import { useUpdateUserAccount } from './mutations';
import { updateUserSchema, UserUpdateSchemaType } from './schema';

type Props = {
  user: PageableUser;
  isProfile?: boolean;
  onClose: () => void;
};

const UpdateUserForm: React.FC<Props> = ({ user, isProfile, onClose }) => {
  const { handleUpdateUserAccount, isLoading } = useUpdateUserAccount({
    userId: user.id,
    onSuccess: onClose,
  });
  const { control, handleSubmit } = useForm<UserUpdateSchemaType>({
    resolver: zodResolver(updateUserSchema),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role as any,
    },
  });
  const { data: roles } = useQuery({
    ...queries.users.roles(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return (
    <form onSubmit={handleSubmit(handleUpdateUserAccount)}>
      <Stack pt={3} gap={2}>
        <Stack
          direction='row'
          justifyContent='space-between'
          gap={2}
          alignContent='center'
          alignItems='center'
        >
          <Stack width='100%' gap={1}>
            <InputLabel htmlFor='firstName' sx={{ color: 'kqn.darkerGray' }}>
              First Name
            </InputLabel>
            <KQNTextInputField name='firstName' control={control} placeholder='First Name' />
          </Stack>
          <Stack width='100%' gap={1}>
            <InputLabel htmlFor='lastName' sx={{ color: 'kqn.darkerGray' }}>
              Last Name
            </InputLabel>
            <KQNTextInputField name='lastName' control={control} placeholder='Last Name' />
          </Stack>
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          gap={2}
          alignContent='center'
          alignItems='center'
        >
          <Stack width='100%' gap={1}>
            <InputLabel htmlFor='email' sx={{ color: 'kqn.darkerGray' }}>
              Email
            </InputLabel>
            <KQNTextInputField
              name='email'
              control={control}
              type='email'
              placeholder='test@koqoon.com'
            />
          </Stack>
          {!isProfile && (
            <Stack width='100%' gap={1}>
              <InputLabel htmlFor='role' sx={{ color: 'kqn.darkerGray' }}>
                Role
              </InputLabel>
              <Controller
                name='role'
                control={control}
                defaultValue={user.role as any}
                render={({ field: { ref, ...field } }) => (
                  <FormControl fullWidth>
                    <KQNTextField select size='small' variant='outlined' inputRef={ref} {...field}>
                      {roles?.map((role) => (
                        <MenuItem key={role.id} value={role.name}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </KQNTextField>
                  </FormControl>
                )}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack direction='row' gap={3} pt={3} alignContent='center' justifyContent='flex-end'>
        <Button type='button' variant='text' onClick={onClose} sx={{ color: 'kqn.darkerGray' }}>
          CLOSE
        </Button>
        <Button
          type='submit'
          variant='contained'
          startIcon={isLoading && <CircularProgress size={15} sx={{ color: 'kqn.white' }} />}
          sx={{
            px: 3,
            py: 1,
            color: '#fff',
            bgcolor: 'kqn.cooper',
            borderRadius: 1,
            ':hover': { backgroundColor: 'kqn.cooper' },
          }}
        >
          SAVE
        </Button>
      </Stack>
    </form>
  );
};

export default UpdateUserForm;
