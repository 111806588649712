export enum Paths {
  AUTH = '/auth',
  USERS = '/users',
  RECIPES = '/recipes',
  RECIPES_CREATE = '/recipes/create',
  PREPARATIONS = '/preparations',
  NOTIFICATIONS = '/notifications',
  MEAL_GROUPS = '/meal-groups',
  MEAL_TYPES = '/meal-types',
  UNITS = '/units',
  NUTRIENTS = '/nutrients',
  INGREDIENTS = '/ingredients',
  CATEGORIES = '/categories',
  MEALS = '/meals',
  PRESETS = '/presets',
  LOGIN = '/login',
  TOKEN_VERIFICATION = '/token-verification',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  EMAIL_CHANGED = '/email-changed',
  SETTINGS = '/admin/settings',
  ADMIN_USERS = '/admin/users',
  BASE = '/',
  ME = '/auth/me',
  REGISTER = '/register',
}
