import { Box, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import {
  EnhancedTableHead,
  MainLayout,
  Navigation,
  Pagination,
  TableWrapper,
  useTableClick,
  useTableSort,
} from '../../../../components';
import { PaginatedResult } from '../../../../types';
import { CategoriesHeadCells, CategoryResponse } from '../../../types';
import { CategoriesEnhancedTableActions } from './categories-enhanced-table-actions';
import categoriesHeadCells from './categories-head-cells';
import CategoriesTableBody from './categories-table-body';

interface Props {
  data: PaginatedResult<CategoryResponse>;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CategoriesEnhancedTable: React.FC<Props> = ({ data, onPageChange, onRowsPerPageChange }) => {
  const { data: categories, meta } = data;
  const { selected, setSelected, handleClick } = useTableClick();
  const { handleRequestSort, order, orderBy } = useTableSort<CategoriesHeadCells>();
  const { currentPage, perPage } = meta;
  const page = currentPage > 0 ? currentPage - 1 : 0;

  return (
    <TableWrapper>
      <Box sx={{ width: '100%' }}>
        <Navigation>
          <CategoriesEnhancedTableActions
            category={categories.find((category) => category.id === selected[0])}
            selected={selected}
            setSelected={setSelected}
          />
        </Navigation>
        <MainLayout>
          <TableContainer
            sx={{
              maxHeight: 'calc(100vh - 250px)',
              overflow: 'auto',
              borderRadius: '2px',
              width: 'fit-content',
            }}
          >
            <Table sx={{ minWidth: 750 }} stickyHeader aria-labelledby='tableTitle' size='medium'>
              <EnhancedTableHead
                headCells={categoriesHeadCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              >
                <TableRow>
                  <TableCell align='center' colSpan={3} sx={{ fontWeight: 'bold' }}>
                    CATEGORY NAME
                  </TableCell>
                  <TableCell align='center' colSpan={3} sx={{ fontWeight: 'bold' }}>
                    DETAILS
                  </TableCell>
                </TableRow>
              </EnhancedTableHead>
              <CategoriesTableBody
                data={data}
                perPage={perPage}
                order={order}
                orderBy={orderBy}
                handleOnSelect={handleClick}
                selected={selected}
              />
            </Table>
            <Pagination
              total={meta.total}
              perPage={perPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </TableContainer>
        </MainLayout>
      </Box>
    </TableWrapper>
  );
};

export default CategoriesEnhancedTable;
