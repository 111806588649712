import { Node } from '../chart-types';

export function getZoneNodes(zone: Node[]) {
  const lastCreatedNode = zone[zone.length - 1];

  const prevCreatedNode = zone.find(
    (z) => (z?.startOffset || 0) > (lastCreatedNode?.endOffset || 0),
  );

  return {
    lastCreatedNode,
    prevCreatedNode,
  };
}
