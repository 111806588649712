import { styled } from '@mui/material/styles';
import React from 'react';

type Props = {
  open: boolean;
  children: React.ReactNode;
};

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const SidebarMain: React.FC<Props> = ({ children, open }) => {
  return <Main open={open}>{children}</Main>;
};

export default SidebarMain;
