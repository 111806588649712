import { koqoonApi } from '../../../config';
import { Event } from '../../types';

const getAllEvents = async () => {
  try {
    const response = await koqoonApi.get('/events');
    return response.data as Event[];
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const eventService = {
  getAllEvents,
};
