import { CircularProgress, Stack, Typography } from '@mui/material';
import { Optional } from '../optional';

type Props = {
  isMutating: boolean;
  message?: string;
};

const MutationIndicator: React.FC<Props> = ({ message, isMutating }) => {
  return (
    <Optional condition={isMutating}>
      <Stack direction='row' gap={2} alignItems='center'>
        <Typography variant='body2'>{message || 'Updating..'}</Typography>
        <CircularProgress size='20px' sx={{ color: 'kqn.darkerGray' }} />
      </Stack>
    </Optional>
  );
};

export default MutationIndicator;
