import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  DiscardChangesModal,
  KQNTextInputField,
  Title,
} from '../../../../../../../../../../../components';
import { queries } from '../../../../../../../../../../queries';
import { Meal, MealTypeModel } from '../../../../../../../../../../types';
import { MealTypesPicker } from '../../../../../../../../pickers';
import { useCreateMealTypeStep, useRemoveMealTypeStep } from '../mutations';
import { mealTypeStepSchema, MealTypeStepSchemaType } from './schema';

type Props = {
  recipeId: string;
  mealTypeStep?: Meal;
  onClose: () => void;
};

const CreateMealForm: React.FC<Props> = ({ recipeId, mealTypeStep, onClose }) => {
  const [selectedMealType, setSelectedMealType] = useState<MealTypeModel | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const { handleCreateMealTypeStep, isLoading } = useCreateMealTypeStep();
  const { handleRemoveMealTypeStep } = useRemoveMealTypeStep();

  const { control, setValue, handleSubmit, getValues } = useForm<MealTypeStepSchemaType>({
    defaultValues: {
      title: mealTypeStep?.title || '',
      titleDe: mealTypeStep?.titleDe || '',
      description: mealTypeStep?.description || '',
      descriptionDe: mealTypeStep?.descriptionDe || '',
      timeOffset: String(mealTypeStep?.timeOffset) || '0',
      mealTypeId: mealTypeStep?.mealType.id || '',
    },
    resolver: zodResolver(mealTypeStepSchema),
  });

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const { data: filters } = useQuery({
    ...queries.mealTypes.all(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const onSubmit = async (data: MealTypeStepSchemaType) => {
    const formData = {
      ...data,
      id: mealTypeStep?.id,
      recipeId,
    };

    await handleCreateMealTypeStep({ data: formData, onSuccess: onClose });
  };

  const handleRemoveStep = async () => {
    if (mealTypeStep) {
      await handleRemoveMealTypeStep({ id: mealTypeStep.id, onSuccess: onClose });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction='column' gap={2} pt={2}>
        <KQNTextInputField
          control={control}
          name='title'
          data-testid='meal-type-step-title'
          label='Meal Name (EN)'
        />
        <KQNTextInputField
          data-testid='meal-type-step-title-de'
          size='small'
          label='Meal Name (DE)'
          control={control}
          name='titleDe'
        />
        <KQNTextInputField
          data-testid='meal-type-step-about'
          multiline
          rows={3}
          label='Summary (EN)'
          control={control}
          name='description'
          InputProps={{
            sx: {
              '& .MuiInputBase-input': {
                px: 2,
                py: 1,
              },
            },
          }}
        />
        <KQNTextInputField
          multiline
          rows={3}
          data-testid='meal-type-step-about-de'
          label='Summary (DE)'
          control={control}
          name='descriptionDe'
          InputProps={{
            sx: {
              '& .MuiInputBase-input': {
                px: 2,
                py: 1,
              },
            },
          }}
        />
        <Stack direction='row' justifyContent='flex-start' alignItems='baseline'>
          <Stack width='40%'>
            <Title title='Start time offset' />
          </Stack>
          <KQNTextInputField
            data-testid='meal-type-step-time-offset'
            label='Offset, s'
            type='number'
            sx={{ width: '140px' }}
            control={control}
            name='timeOffset'
          />
        </Stack>
        <MealTypesPicker
          pickedMealTypes={[
            selectedMealType || { id: getValues('mealTypeId'), name: '', iconUri: '' },
          ]}
          mealTypes={filters}
          updateMealType={(mealType) => {
            setSelectedMealType(mealType);
            setValue('mealTypeId', mealType.id);
          }}
        />
        <Stack direction='row' gap={2} width='100%' justifyContent='space-between'>
          {mealTypeStep && (
            <Box>
              <Button type='button' variant='outlined' color='error' onClick={handleOpen}>
                DELETE
              </Button>
            </Box>
          )}
          <Stack direction='row' gap='2' justifyContent='flex-end' width='100%'>
            <Button type='button' onClick={onClose}>
              CANCEL
            </Button>
            <Button
              type='submit'
              variant='contained'
              data-testid='save-button'
              startIcon={isLoading && <CircularProgress size={15} sx={{ color: 'kqn.white' }} />}
            >
              SAVE
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <DiscardChangesModal
        isOpen={isOpen}
        onClose={handleClose}
        title='Are you sure you want to remove this meal?'
        content='This meal will be removed permanently.'
        onConfirm={handleRemoveStep}
      />
    </form>
  );
};
export default CreateMealForm;
