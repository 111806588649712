import { Checkbox, checkboxClasses } from '@mui/material';

interface Props {
  checked?: boolean;
  labelId?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const KQNCheckbox: React.FC<Props> = ({ labelId, checked = false, onClick }) => {
  return (
    <Checkbox
      data-testid='recipe-checkbox'
      onClick={onClick}
      checked={checked}
      inputProps={{ 'aria-labelledby': labelId }}
      sx={{
        color: 'kqn.cooper',
        [`&, &.${checkboxClasses.checked}`]: {
          color: 'kqn.cooper',
        },
      }}
    />
  );
};

export default KQNCheckbox;
