import { Node } from '../chart-types';
import { calculateNodePercentages } from '../helpers/calculate-percentages';

/**
 * This function is used to map the chart values to the percentage values.
 * @param originalMap - The original map of the chart values
 * @param totalDuration - The total duration of the chart
 * @returns updatedMap - The updated map of the chart values
 */
export function mapChartValues(originalMap: Map<string, Node[]>, totalDuration: number) {
  const updatedMap = new Map<string, Node[]>();

  originalMap.forEach((value, key) => {
    const updatedValue = value.map((node) => {
      return {
        ...node,
        ...calculateNodePercentages(
          node.startOffset || 0,
          node.prepInterval || 0,
          node.duration || 0,
          node.cookingTime || 0,
          totalDuration,
        ),
      };
    });

    updatedMap.set(key, updatedValue);
  });

  return {
    updatedMap,
  };
}
