import { Container, styled } from '@mui/material';

interface Props {
  component: string;
  maxWidth: string;
  bgColor?: string;
}

export const MainWrapper = styled(Container)<Props>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || 'inherit'};
`;
