import { Stack } from '@mui/material';
import React from 'react';
import { ImagePlaceholder } from '../../../../shared';
import { RecipeBaseModel } from '../../../types';

interface Props {
  recipe: RecipeBaseModel;
  children: React.ReactNode;
}

export const RECIPE_DEFAULT_WIDTH = 200;
export const RECIPE_DEFAULT_HEIGHT = 250;

const RecipeCardBase: React.FC<Props> = ({ recipe, children }) => {
  return (
    <Stack
      direction='column'
      justifyContent='center'
      gap={1}
      sx={{
        width: `${RECIPE_DEFAULT_WIDTH}px`,
        height: `${RECIPE_DEFAULT_HEIGHT}px`,
        borderRadius: '8px',
        position: 'relative',
        overflow: 'hidden',

        '&:hover': {
          cursor: 'pointer',
          bgcolor: 'kqn.grayDark',
        },

        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          height: '40%',
          zIndex: 2,
        },
        '&::before': {
          top: 0,
          background: 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
        },
        '&::after': {
          bottom: 0,
          background: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
        },
      }}
    >
      {children}
      {recipe.imageUrl ? (
        <img
          src={recipe.imageUrl}
          alt={recipe.name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            zIndex: 1,
            opacity: recipe.isRemoved ? '0.5' : '1',
          }}
        />
      ) : (
        <ImagePlaceholder
          style={{
            width: RECIPE_DEFAULT_WIDTH,
            height: RECIPE_DEFAULT_HEIGHT,
            position: 'absolute',
            opacity: recipe.isRemoved ? '0.5' : '1',
          }}
        />
      )}
    </Stack>
  );
};

export default RecipeCardBase;
