import { AuthUser, Credentials } from '..';
import { koqoonApi } from '../../../config';
import { NavigationPaths, Paths } from '../../../constants';

const authPath = `${Paths.AUTH}`;

async function login({ email, password }: Credentials) {
  const path = `${authPath}${NavigationPaths.LOGIN}`;
  const body = { email, password };

  try {
    const response = await koqoonApi.post(path, body);
    const { payload } = response.data as { payload: AuthUser };
    if (!payload) return undefined;

    return payload;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

async function logout() {
  const path = `${authPath}/logout`;
  try {
    await koqoonApi.post(path);
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

export const authenticationService = {
  login,
  logout,
};
