import { Node } from '../chart-types';

export const sortChartData = (data: Map<string, Node[]>) => {
  return new Map(
    [...data].sort((a, b) => {
      const keyA = a[0];
      const keyB = b[0];

      const order: Record<string, number> = {
        'Burner 1': 1,
        'Burner 2': 2,
        'Burner 3': 3,
        'Burner 4': 4,
        Chamber: 5,
        User: 6,
      };

      const [prefixA, suffixA] = keyA.split('-');
      const [prefixB, suffixB] = keyB.split('-');

      const numA = order[prefixA] || Infinity;
      const numB = order[prefixB] || Infinity;

      if (numA !== numB) {
        return numA - numB;
      }

      if (suffixA && suffixB) {
        const numSuffixA = parseInt(suffixA, 10);
        const numSuffixB = parseInt(suffixB, 10);
        return numSuffixA - numSuffixB;
      }
      if (!suffixA && !suffixB) {
        return keyA.localeCompare(keyB);
      }
      return suffixA ? 1 : -1;
    }),
  );
};
