import { GrillStep, Trigger } from '../../../../../../../../types';

interface Props {
  preset: GrillStep;
}

export const useGenerateTriggers = ({ preset }: Props): Trigger[] => {
  if (!preset.method) return [];

  if (preset.method.name === 'Probe') {
    return (
      preset.probes?.map((probe) => ({
        id: probe.burnerId!,
        name: probe.name,
        value: probe.temperature,
        type: 'probe',
      })) || []
    );
  }

  return (
    preset.durations?.map((duration) => ({
      id: duration.id,
      name: duration.name,
      value: duration.duration,
      type: 'time',
    })) || []
  );
};
