import { koqoonApi } from '../../../config';
import { Paths } from '../../../constants';
import { Nutrient } from '../../types';

const basePath = Paths.NUTRIENTS;

const getNutrients = async () => {
  try {
    const response = await koqoonApi.get(basePath);

    return response.data as Nutrient[];
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const nutrientService = {
  getNutrients,
};
