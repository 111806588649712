import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  ComposerFooterButtons,
  KQNTextInputField,
  TextInputAndormnet,
} from '../../../../components';
import { CategoryResponse } from '../../../types';
import { updateCategorySchema, UpdateCategorySchemaType } from './schema';

type Props = {
  category?: CategoryResponse;
  onSubmit: (data: UpdateCategorySchemaType) => void;
  isSubmitting?: boolean;
  onClose: () => void;
};

const UpsertCategoryForm: React.FC<Props> = ({ category, onSubmit, isSubmitting, onClose }) => {
  const { control, handleSubmit } = useForm<UpdateCategorySchemaType>({
    resolver: zodResolver(updateCategorySchema),
    defaultValues: {
      name: category?.name || '',
      nameDe: category?.nameDe || '',
      description: category?.description || '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4}>
        <Stack pt={2} gap={2}>
          <Stack
            direction='row'
            justifyContent='space-between'
            gap={2}
            alignContent='center'
            alignItems='center'
          >
            <KQNTextInputField
              name='name'
              control={control}
              placeholder='Category (EN)'
              InputProps={{ startAdornment: <TextInputAndormnet text='EN' position='start' /> }}
            />
            <KQNTextInputField
              name='nameDe'
              control={control}
              placeholder='Category (DE)'
              InputProps={{ startAdornment: <TextInputAndormnet text='DE' position='start' /> }}
            />
          </Stack>
          <KQNTextInputField
            multiline
            fullWidth
            rows={4}
            control={control}
            name='description'
            placeholder='Description (EN)'
            InputProps={{
              startAdornment: <TextInputAndormnet text='EN' position='start' />,
              sx: { '& .MuiInputBase-input': { px: 2, py: 1 } },
            }}
          />
        </Stack>
        <ComposerFooterButtons onClose={onClose} isLoading={isSubmitting} />
      </Stack>
    </form>
  );
};

export default UpsertCategoryForm;
