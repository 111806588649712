import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { CreateUserRequest, UserResponse } from '../../types';
import { userService } from '../services';

interface Props {
  data: CreateUserRequest;
  onSuccess?: () => void;
}

export const useCreateUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<UserResponse, unknown, { data: CreateUserRequest }>(({ data }) =>
    userService.createUserAccount(data),
  );

  const handleCreateUser = async ({ data, onSuccess }: Props) => {
    try {
      mutation.mutate(
        { data },
        {
          onSuccess: () => {
            queryClient.refetchQueries(queries.users.filter._def);
            toast.success('User has been successfully added.');
            if (onSuccess) {
              onSuccess();
            }
          },
          onError: (err: any) => {
            toast.error(err || 'Something went wrong.');
          },
        },
      );
    } catch (err: any) {
      toast.error(err || 'Something went wrong.');
    }
  };

  return {
    handleCreateUser,
    isLoading: mutation.isLoading,
    mutation,
  };
};
