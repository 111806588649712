export const QueryKeys = {
  user: 'user',
  units: 'units',
  users: 'users',
  roles: 'roles',
  meals: 'meals',
  recipe: 'recipe',
  events: 'events',
  filters: 'filters',
  recipes: 'recipes',
  burners: 'burners',
  nutrients: 'nutrients',
  dashboard: 'dashboard',
  mealTypes: 'meal-types',
  ingredient: 'ingredient',
  categories: 'categories',
  recipeInfo: 'recipe-info',
  ingredients: 'ingredients',
  accessories: 'accessories',
  burnerModes: 'burner-modes',
  preparations: 'preparations',
  burnerMethods: 'burner-methods',
  nameValidation: 'name-validation',
  recipeCategory: 'recipe-category',
  tokenVerification: 'token-verification',
  associatedRecipes: 'associated-recipes',
  recipesByCategory: 'recipes-by-category',
  dashboardCategories: 'dasboard-categories',
} as const;
