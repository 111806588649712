import { ConfirmModal } from '../../../../components';
import { useDeactivateUser } from '../../mutations/use-deactivate-user';

interface DeactivateUsersModalProps {
  selected?: string[];
  isOpen: boolean;
  onClose: () => void;
}

const DeactivateUsersModal = ({ selected, isOpen, onClose }: DeactivateUsersModalProps) => {
  const { handleDeactivateUser, isLoading } = useDeactivateUser();

  const handleDeactivate = async () => {
    if (!selected) return;
    await handleDeactivateUser({ ids: selected, onSuccess: onClose });
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCofirm={handleDeactivate}
      isProcessing={isLoading}
    />
  );
};

export default DeactivateUsersModal;
