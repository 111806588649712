export enum ErrorKeys {
  NAME = 'name',
  NAMEDE = 'nameDe',
  DESCRIPTION = 'description',
  DESCRIPTION_DE = 'descriptionDe',
  COMPLEXITY = 'complexity',
  CATEGORIES = 'categories',
  MEAL_TYPES = 'mealTypes',
  COOKING_TIME = 'cookingTime',
  USER_INSTRUCTION = 'userInstruction',
  USER_INSTRUCTION_DE = 'userInstructionDe',
  HINT = 'hint',
  HINT_DE = 'hintDe',
  PREP_INTERVAL = 'prepInterval',
  METHOD = 'method',
  MODE = 'mode',
  PRESET = 'preset',
  UNITS = 'units',
}
