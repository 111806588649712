import { GrillStep } from '../../../../../../../../../../types';

export const calculateTotalDuration = (steps: Partial<GrillStep[]>) => {
  let totalDuration = 0;

  if (steps) {
    steps.forEach((step) => {
      if (!step?.durations) return;

      totalDuration += step.durations
        .filter((d) => d.name.toLocaleLowerCase() === 'medium' || d.name === '')
        .reduce((acc, curr) => acc + curr.duration, 0);
    });
  }

  return totalDuration;
};
