import { useGeneralPhaseSubmit } from '../components';
import { useIngredientsSubmit } from './use-ingredients-submit';

export const useRecipeHeaderActions = (value: number) => {
  const handleGeneralSubmit = useGeneralPhaseSubmit();
  const handleGroupsSubmit = useIngredientsSubmit();

  if (value === 0) return handleGeneralSubmit;
  if (value === 1) return handleGroupsSubmit;

  return undefined;
};
