import { z } from 'zod';

export const resetPasswordSchema = z.object({
  password: z
    .string()
    .min(8, 'Password must have at least 8 characters.')
    .refine(
      (password) =>
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!”§$%&\\/()=?_@#;:+*-]).{8,32}$/.test(password),
      'Password must have at least 8 characters, one uppercase, one lowercase, one number and one special character.',
    ),
  confirmPassword: z
    .string()
    .min(8, 'Password must have at least 8 characters.')
    .refine(
      (password) =>
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!”§$%&\\/()=?_@#;:+*-]).{8,32}$/.test(password),

      'Password must have at least 8 characters, one uppercase, one lowercase, one number and one special character.',
    ),
});

export type ForgotPasswordSchemaType = z.infer<typeof resetPasswordSchema>;
