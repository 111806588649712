import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Stack } from '@mui/material';
import React from 'react';
import { KQNIconButton, MutationIndicator, Optional } from '../../../../../../../../../components';
import { useMealActions } from '../../../../../../../../../store/meal-store';
import { GrillStep } from '../../../../../../../../types';
import { useUpsertPreset } from '../../../mutations';
import { ForceStop } from './force-stop';
import { InstantStart } from './instant-start';

interface Props {
  preset: GrillStep;
  isLoading: boolean;
  mealId: string;
  onOpen: () => void;
  onClose?: () => void;
}

export const PresetActions: React.FC<Props> = ({ isLoading, mealId, onClose, onOpen, preset }) => {
  const { updatePresets } = useMealActions();
  const { handleUpsertGrillStep, isLoading: isSaving } = useUpsertPreset();

  const handleSaveStep = async () => {
    if (isLoading || isSaving) return;
    await handleUpsertGrillStep({
      mealId,
      preset,
      onUpdate: updatePresets,
      onCleanup: onClose,
    });
  };

  return (
    <Stack direction='row' gap={4}>
      <MutationIndicator isMutating={isLoading || isSaving} />
      <InstantStart isInstant={preset.instant} mealId={mealId} stepId={preset.id} />
      <ForceStop forceStop={preset.forceStop} mealId={mealId} stepId={preset.id} />
      <Stack direction='row' gap={3}>
        <KQNIconButton onClick={handleSaveStep} isDisabled={isLoading}>
          <CheckIcon sx={{ color: 'kqn.green' }} />
        </KQNIconButton>
        <KQNIconButton onClick={onOpen} isDisabled={isLoading}>
          <DeleteOutlineIcon sx={{ color: 'kqn.coral' }} />
        </KQNIconButton>
        <Optional condition={!preset.isDraft}>
          <KQNIconButton onClick={onClose} isDisabled={isLoading}>
            <CloseIcon sx={{ color: 'kqn.darkGray' }} />
          </KQNIconButton>
        </Optional>
      </Stack>
    </Stack>
  );
};
