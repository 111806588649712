import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { queries } from '../../queries';
import { recipeService } from '../services/recipe-service';

interface Props {
  recipeId: string;
  onSuccess?: () => void;
}

export const useUpdateRecipeState = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, { recipeId: string }>(({ recipeId }) =>
    recipeService.enableRecipe(recipeId),
  );

  const handleUpdateRecipeState = async ({ recipeId, onSuccess }: Props) => {
    mutation.mutate(
      { recipeId },
      {
        onSuccess: () => {
          queryClient.refetchQueries(queries.recipes.filter._def);
          queryClient.refetchQueries(queries.recipes.infinityFilter._def);

          toast.success(`Recipe restored successfully and added back to your recipe list.`);
          onSuccess?.();
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    handleUpdateRecipeState,
    isLoading: mutation.isLoading,
    mutation,
  };
};
