import { Box, Container, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import {
  EnhancedTableHead,
  MainLayout,
  Navigation,
  Pagination,
  TableWrapper,
  useTableClick,
  useTableSort,
} from '../../../../components';
import { PaginatedResult } from '../../../../types';
import { PageableUnit, UnitHeadCells } from '../../../types';
import { UnitEnhancedTableActions } from './unit-enhanced-table-actions';
import unitHeadCells from './unit-head-cells';
import UnitTableBody from './unit-table-body';

interface Props {
  data: PaginatedResult<PageableUnit>;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UnitEnhancedTable: React.FC<Props> = ({ data, onPageChange, onRowsPerPageChange }) => {
  const { data: units, meta } = data;
  const { currentPage, perPage } = meta;
  const { selected, setSelected, handleClick } = useTableClick();
  const { handleRequestSort, order, orderBy } = useTableSort<UnitHeadCells>();
  const page = currentPage > 0 ? currentPage - 1 : 0;

  return (
    <TableWrapper>
      <Box sx={{ width: '100%' }}>
        <Navigation>
          <UnitEnhancedTableActions
            unit={units.find((unit) => unit.id === selected[0])}
            selected={selected}
            setSelected={setSelected}
          />
        </Navigation>
        <MainLayout>
          <Container maxWidth='md' sx={{ ml: 0, pl: '0 !important' }}>
            <TableContainer
              sx={{ maxHeight: 'calc(100vh - 225px)', overflow: 'auto', borderRadius: '2px' }}
            >
              <Table sx={{ minWidth: 750 }} stickyHeader aria-labelledby='tableTitle' size='medium'>
                <EnhancedTableHead
                  headCells={unitHeadCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                >
                  <TableRow>
                    <TableCell align='center' colSpan={3} sx={{ fontWeight: 'bold' }}>
                      SINGULAR UNIT NAME
                    </TableCell>
                    <TableCell align='center' colSpan={3} sx={{ fontWeight: 'bold' }}>
                      PLURAL UNIT NAME
                    </TableCell>
                  </TableRow>
                </EnhancedTableHead>
                <UnitTableBody
                  data={data}
                  perPage={perPage}
                  order={order}
                  orderBy={orderBy}
                  handleOnSelect={handleClick}
                  selected={selected}
                />
              </Table>
            </TableContainer>
            <Pagination
              total={meta.total}
              perPage={perPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </Container>
        </MainLayout>
      </Box>
    </TableWrapper>
  );
};

export default UnitEnhancedTable;
