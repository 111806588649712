import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { TimeToSeconds } from '../../../../constants';
import { queries } from '../../../queries';
import { DashboardCardsSkeleton } from '../../skeleton';
import QuickActionItem from './quick-action-item';

const QuickAction: React.FC = () => {
  const { data, isLoading, isFetching } = useQuery({
    ...queries.dashboard.all(),
    staleTime: TimeToSeconds.ONE_DAY,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (isLoading || isFetching) return <DashboardCardsSkeleton />;
  if (!data) return null;

  return (
    <Stack direction='row' width='100%' justifyContent='flex-start' pt={3} gap={6}>
      {data?.map(({ count, name }, idx) => {
        return <QuickActionItem key={name} count={count} title={name} idx={idx} />;
      })}
    </Stack>
  );
};

export default QuickAction;
