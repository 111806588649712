import { useMemo } from 'react';
import { useRecipeStore } from '../../../../../../../store';
import { hasTextChanged } from '../../../../../helpers';

export const useGeneralValidation = () => {
  const recipe = useRecipeStore.use.recipe();
  const initialData = useRecipeStore.use.initialData();

  const hasGeneralChanges = useMemo(() => {
    const { name, nameDe, cookingTime, description, descriptionDe } = recipe;
    if (!initialData) {
      return false;
    }

    if (
      hasTextChanged(name, initialData.name) ||
      hasTextChanged(nameDe, initialData.nameDe) ||
      hasTextChanged(description, initialData.description) ||
      hasTextChanged(descriptionDe, initialData.descriptionDe) ||
      cookingTime !== initialData.cookingTime ||
      recipe.mealTypes.map((mealType) => mealType.id).join() !==
        initialData.mealTypes.map((mealType) => mealType.id).join() ||
      recipe.complexity.id !== initialData.complexity.id ||
      recipe.categories.map((category) => category.id).join() !==
        initialData.categories.map((category) => category.id).join()
    ) {
      return true;
    }

    return false;
  }, [recipe, initialData]);

  return {
    hasGeneralChanges,
  };
};
