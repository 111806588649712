import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import ExportRecipeModal from './export-recipe-modal';

type Props = {
  recipeId: string;
};

const ExportRecipe: React.FC<Props> = ({ recipeId }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        startIcon={<FileUploadIcon />}
        onClick={() => setIsOpen(true)}
        sx={{
          bgcolor: 'kqn.white',
          color: 'kqn.green',
          borderColor: 'kqn.lightGreen',
          ':hover': { bgcolor: 'kqn.gray', borderColor: 'kqn.green', color: 'kqn.green' },
        }}
        variant='outlined'
      >
        EXPORT
      </Button>
      <ExportRecipeModal isOpen={isOpen} onClose={() => setIsOpen(false)} recipeId={recipeId} />
    </>
  );
};

export default ExportRecipe;
