import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../../../../../queries';
import { preparationService } from '../service';

interface Data {
  stepId: string;
}

interface Props {
  onSucess?: () => void;
}

export const useRemovePreparationStep = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, Data>(({ stepId }) =>
    preparationService.removePreparationStep(stepId),
  );

  const handleRemovePreparation = async ({ stepId, onSucess }: Data & Props) => {
    mutation.mutate(
      { stepId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queries.preparations.all._def);
          queryClient.invalidateQueries(queries.recipes.info._def);
          toast.success('Step removed successfully');
          onSucess?.();
        },
        onError: () => {
          toast.error('Error removing step');
        },
      },
    );
  };

  return { handleRemovePreparation, isLoading: mutation.isLoading, mutation };
};
