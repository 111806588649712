import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useMealStore } from '../../../../../../../../../../../store/meal-store';
import { queries } from '../../../../../../../../../../queries';
import { Duration } from '../../../../../../../../../../types';
import { notificationService } from '../../../service';

interface Data {
  stepId: string;
  notificationId: string;
}

interface Props {
  mealId: string;
}

export const useRemoveNotification = () => {
  const queryClient = useQueryClient();
  const { updateDurations } = useMealStore.use.actions();

  const mutation = useMutation<Duration[] | undefined, unknown, Data>(
    ({ notificationId, stepId }) => notificationService.removeNotification(stepId, notificationId),
  );

  const handleOnRemove = async ({ notificationId, stepId, mealId }: Data & Props) => {
    mutation.mutate(
      { notificationId, stepId },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries(queries.meals.filter._def);
          toast.success('Notification removed successfully');
          if (data) {
            updateDurations(mealId, stepId, data);
          }
        },
        onError: () => {
          toast.error('Failed to remove notification');
        },
      },
    );
  };

  return {
    handleOnRemove,
    isLoading: mutation.isLoading,
    mutation,
  };
};
