import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { MainLayout } from '../../../../../../components';
import { useMealActions } from '../../../../../../store/meal-store';
import { queries } from '../../../../../queries';
import { FlowChart } from '../flow-chart';
import { Meals } from './components';

interface Props {
  recipeId: string | null;
}

const GrillComposer: React.FC<Props> = ({ recipeId }) => {
  const { setData } = useMealActions();

  const { data, isFetching } = useQuery({
    ...queries.meals.filter(recipeId!),
    enabled: !!recipeId,
  });

  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [data, setData, recipeId]);

  if (!recipeId) return null;

  if (!data && !isFetching) return <div>Recipe not found</div>;

  return (
    <MainLayout>
      <FlowChart />
      <Meals recipeId={recipeId} />
    </MainLayout>
  );
};

export default GrillComposer;
