import { Box, CircularProgress } from '@mui/material';

const LoadingOverlay = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        backgroundColor: 'kqn.gray',
      }}
    >
      <CircularProgress size={30} sx={{ color: '#000' }} />
    </Box>
  );
};

export default LoadingOverlay;
