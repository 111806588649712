import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, TableSkeleton } from '../../../components';
import { UsersTable } from '../components/table';

const UsersPage = () => {
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <Suspense fallback={<TableSkeleton />}>
        <UsersTable />
      </Suspense>
    </ErrorBoundary>
  );
};

export default UsersPage;
