import { useQuery } from '@tanstack/react-query';
import { queries } from '../../../../../../../../queries';
import { ModeType } from '../../../../../../../../types';

export interface PresetValues<T> {
  data: T[] | undefined;
  isLoading: boolean;
  isError: boolean;
}

export const useGetValidPresetValues = () => {
  const {
    data: modes,
    isLoading: isLoadingBurnerModes,
    isError: isErrorBurnerModes,
  } = useQuery({ ...queries.modes.all(), cacheTime: Infinity, staleTime: Infinity });

  const {
    data: grillMethods,
    isLoading: isLoadingMethods,
    isError: isErrorMethods,
  } = useQuery({ ...queries.methods.all(), cacheTime: Infinity, staleTime: Infinity });

  const burnerModes: PresetValues<ModeType> = {
    data: modes,
    isLoading: isLoadingBurnerModes,
    isError: isErrorBurnerModes,
  };

  const methods: PresetValues<ModeType> = {
    data: grillMethods,
    isLoading: isLoadingMethods,
    isError: isErrorMethods,
  };

  return {
    methods,
    burnerModes,
  };
};
