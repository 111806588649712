import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { CreateAcessoryRequest } from '../../types';
import { accessoriesService } from '../services';

interface Data {
  data: CreateAcessoryRequest;
}

interface Props {
  onSuccess?: () => void;
}

export const useUpsertAccessory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, Data>(({ data }) =>
    accessoriesService.upsertAccessory(data),
  );

  const handleUpsertAccessory = async ({ data, onSuccess }: Data & Props) => {
    mutation.mutate(
      { data },
      {
        onSuccess: () => {
          queryClient.refetchQueries(queries.accessories.filter._def);
          queryClient.invalidateQueries(queries.accessories.details._def);
          toast.success('Accessory is successfully saved.', { duration: 2000 });
          onSuccess?.();
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    mutation,
    handleUpsertAccessory,
    isLoading: mutation.isLoading,
  };
};
