import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../../../../../../../../../queries';
import { Meal, MealRequest } from '../../../../../../../../../../types';
import { mealTypeStepService } from '../services';

interface Data {
  data: MealRequest;
}

interface Props {
  onSuccess?: () => void;
}

export const useCreateMealTypeStep = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Meal, unknown, Data>(({ data }) =>
    mealTypeStepService.createMealTypeStep(data),
  );

  const handleCreateMealTypeStep = async ({ data, onSuccess }: Data & Props) => {
    mutation.mutate(
      { data },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queries.meals.filter._def);
          queryClient.invalidateQueries(queries.recipes.info._def);
          toast.success('Meal type step created successfully');
          onSuccess?.();
        },
        onError: (err: any) => {
          toast.error(err || 'Failed to create meal type step');
        },
      },
    );
  };

  return { handleCreateMealTypeStep, mutation, isLoading: mutation.isLoading };
};
