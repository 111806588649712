import CircleIcon from '@mui/icons-material/Circle';
import { Box, Typography } from '@mui/material';
import { bytesToSize } from '../../../modules/images/helpers';
import { ImageFile } from '../../../types';

export const FileDetails: React.FC<{ file?: ImageFile; status?: string }> = ({ file, status }) => (
  <Box
    display='flex'
    flexDirection='row'
    gap={1}
    alignItems='center'
    justifyContent='center'
    sx={{ textTransform: 'capitalize', color: 'kqn.darkGray' }}
  >
    <Typography sx={{ m: 0 }}>{bytesToSize(file?.size || 0)}</Typography>
    <CircleIcon sx={{ width: '6px' }} />
    <Typography sx={{ m: 0 }}>{status}</Typography>
  </Box>
);
