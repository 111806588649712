import { lazy } from 'react';

const UsersPage = lazy(() => import('../user/pages/users-page'));
const UnitsPage = lazy(() => import('../units/pages/units-page'));
const SettingsPage = lazy(() => import('../settings/pages/settings-page'));
const IngredientsPage = lazy(() => import('../ingredients/pages/ingredients-page'));
const IngredientsDetails = lazy(() => import('../ingredients/pages/ingredient-details'));
const CategoriesPage = lazy(() => import('../categories/pages/categories-page'));
const RecipesCreatePage = lazy(() => import('../recipes/pages/recipes-create-page'));
const CategoriesDetails = lazy(() => import('../categories/pages/categories-details'));
const AccessoriesPage = lazy(() => import('../accessories/accessories-page'));
const AccessoriesDetails = lazy(() => import('../accessories/components/accessories-details'));
const UnitDetails = lazy(() => import('../units/pages/unit-details'));
const RegisterPage = lazy(() => import('../authentication/pages/register-page'));

export {
  AccessoriesDetails,
  AccessoriesPage,
  CategoriesDetails,
  CategoriesPage,
  IngredientsDetails,
  IngredientsPage,
  RecipesCreatePage,
  RegisterPage,
  SettingsPage,
  UnitDetails,
  UnitsPage,
  UsersPage,
};
