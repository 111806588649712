import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearch } from '../../../../context';
import { fetchConfig, useDebounceValue, useHandleFilterParams } from '../../../../shared';
import { queries } from '../../../queries';
import CategoriesEnhancedTable from './categories-enhanced-table';

const CategoriesTable: React.FC = () => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { page, perPage, orderBy } = useHandleFilterParams();
  const { query } = useSearch();
  const debouncedQuery = useDebounceValue(query);

  const { data } = useQuery({
    ...queries.categories.filter({
      query: debouncedQuery,
      perPage,
      page,
      orderBy,
    }),
    keepPreviousData: true,
    suspense: true,
  });

  useEffect(() => {
    if (debouncedQuery === '') return;

    setSearchParams({ page: String(1), perPage: perPage.toString() });
  }, [debouncedQuery, perPage, setSearchParams]);

  if (!data) return null;

  const handleChangePage = (_: unknown, newPage: number) => {
    setSearchParams({ page: String(newPage + 1), perPage: perPage.toString(), orderBy });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fetchConfig.rowsPerPageOptions.includes(Number(event.target.value))) {
      setSearchParams({ page: '1', perPage: event.target.value, orderBy });
      return;
    }

    setSearchParams({ page: '1', perPage: '50', orderBy });
  };

  return (
    <CategoriesEnhancedTable
      data={data}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default CategoriesTable;
