import AddCircleRoundedIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { KQNTextField, Optional } from '../../../../../../../../../../components';
import { useGroupStore } from '../../../../../../../../../../store';
import { CreateIngredientModal } from '../../../../../../../../../ingredients';
import { Ingredient, RecipeGroupModel, RecipeIngredient } from '../../../../../../../../../types';

interface Props {
  group: RecipeGroupModel;
  ordinal: number;
  initialState: RecipeIngredient;
  ingredients: Ingredient[];
}

const sortOrder: { [key: string]: number } = {
  Energy: 1,
  Carbs: 2,
  Protein: 3,
  Fat: 4,
};

const GroupIngredient: React.FC<Props> = ({ group, ordinal, initialState, ingredients }) => {
  const { units } = initialState;
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [addIngredient, setAddIngredient] = useState<boolean>(false);
  const { updateIngredient, updateIngredientQuantity, updateIngredientUnit, removeIngredient } =
    useGroupStore.use.actions();

  const handleUpdateQuantity = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = Number(e.target.value);
      if (value < 0) return;

      updateIngredientQuantity(group.id, initialState, value);
    },
    [group.id, initialState, updateIngredientQuantity],
  );

  const handleUpdateIngredient = useCallback(
    (_: any, value: string | null) => {
      const ingredient = ingredients.find((i) => i.name === value);

      if (!ingredient) return;

      if (group.ingredients.some((ing) => ing.id === ingredient.id)) return;

      updateIngredient(group.id, ingredient, initialState.id || '');
    },
    [group.id, group.ingredients, ingredients, initialState.id, updateIngredient],
  );

  const handleUpdateUnit = useCallback(
    (e: SelectChangeEvent<string>) => {
      const unit = units?.find((u) => u.id === e.target.value);

      if (!unit) return;

      if (unit.name === 'None') {
        setIsBlocked(true);
        updateIngredientQuantity(group.id, initialState, 0);
        updateIngredientUnit(group.id, initialState, unit);
        return;
      }

      setIsBlocked(false);
      updateIngredientUnit(group.id, initialState, unit);
    },
    [group.id, initialState, units, updateIngredientQuantity, updateIngredientUnit],
  );

  const handleRemoveIngredient = useCallback(() => {
    removeIngredient(group.id, initialState.id || '');
  }, [group.id, initialState.id, removeIngredient]);

  return (
    <TableRow sx={{ color: 'kqn.darkGray', height: '45px' }}>
      <TableCell sx={{ padding: '0px 6px' }}>
        <Typography>{ordinal}</Typography>
      </TableCell>
      <TableCell sx={{ width: '50%', padding: '0px 6px' }}>
        <FormControl variant='standard' sx={{ width: '100%' }}>
          <Autocomplete
            disableCloseOnSelect
            defaultValue='add'
            isOptionEqualToValue={(option, value) => option === value}
            noOptionsText={
              <MenuItem onClick={() => setAddIngredient(true)}>
                <AddCircleRoundedIcon sx={{ color: 'kqn.darkGray', height: '2rem', mr: 1 }} />
                <Typography>Add</Typography>
              </MenuItem>
            }
            id='ingredient-select'
            disablePortal
            data-testid='ingredient-select'
            value={ingredients.find((ing) => ing.name === initialState.name)?.name || ''}
            options={ingredients
              .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
              .map((option) => option.name)}
            onChange={handleUpdateIngredient}
            renderInput={(params) => (
              <KQNTextField
                key={params.id}
                {...params}
                variant='standard'
                InputProps={{ ...params.InputProps, disableUnderline: true }}
              />
            )}
          />
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: '0px 6px' }}>
        <FormControl variant='standard' sx={{ textAlign: 'center', width: '100%' }}>
          <KQNTextField
            id='ingredient-amount'
            data-testid='ingredient-amount'
            variant='standard'
            type='number'
            disabled={isBlocked || initialState.unit === 'None'}
            value={initialState.quantity ? initialState.quantity.toString() : ''}
            onChange={(e) => handleUpdateQuantity(e)}
            InputProps={{
              inputMode: 'numeric',
              disableUnderline: true,
              sx: {
                '& .MuiInputBase-input': {
                  textAlign: 'center',
                },
              },
            }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          />
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: '0px 6px' }}>
        <FormControl variant='standard' sx={{ textAlign: 'left', minWidth: '100%' }}>
          <Select
            id='units-select'
            disableUnderline
            defaultValue=''
            data-testid='ingredient-unit-select'
            value={initialState.unitId || ''}
            onChange={handleUpdateUnit}
            sx={{ textAlign: 'center' }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
          >
            {(initialState?.units || []).map((unit) => (
              <MenuItem key={unit.id} value={unit.id} data-testid='ingredient-unit-menu-item'>
                {unit.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      {initialState.nutrients
        ?.slice()
        ?.sort((a, b) => sortOrder[a.name] - sortOrder[b.name])
        ?.map((nutrient) => (
          <TableCell key={nutrient.id} sx={{ padding: '0px 6px' }}>
            <KQNTextField
              id='nutrient-amount'
              size='small'
              variant='standard'
              value={nutrient.amount.toString().replace('.', ',') || '0'}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  '& .MuiInputBase-input': {
                    textAlign: 'center',
                  },
                },
              }}
              aria-readonly
            />
          </TableCell>
        ))}
      <TableCell sx={{ padding: '0px 6px' }}>
        <DeleteOutlineIcon
          onClick={handleRemoveIngredient}
          sx={{ color: 'kqn.coral', cursor: 'pointer' }}
        />
      </TableCell>
      <Optional condition={addIngredient}>
        <CreateIngredientModal isOpen={addIngredient} onClose={() => setAddIngredient(false)} />
      </Optional>
    </TableRow>
  );
};

export default memo(GroupIngredient);
