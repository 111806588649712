import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { ConfirmModal } from '../../../../components';
import { queries } from '../../../queries';
import { userService } from '../../services/user-service';

interface Props {
  selected: string[];
  isOpen: boolean;
  onClose: () => void;
}

const ActivateUserModal: React.FC<Props> = ({ selected, isOpen, onClose }) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(userService.activateAccounts, {
    onSuccess: () => {
      toast.success('Users account activated successfully');
      queryClient.refetchQueries(queries.users.filter._def);
      onClose();
    },
    onError: (e: any) => {
      toast.error(e.message);
    },
  });

  return <ConfirmModal isOpen={isOpen} onClose={onClose} onCofirm={() => mutate(selected)} />;
};

export default ActivateUserModal;
