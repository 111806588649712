import { Typography } from '@mui/material';
import React from 'react';
import { ConfirmModal } from '../../../../components';
import { useExportRecipeFile } from './hooks';

type Props = {
  isOpen: boolean;
  onClose: (e: any) => void;
  recipeId?: string;
  title?: string;
  message?: string;
};

const ExportRecipeModal: React.FC<Props> = ({
  recipeId,
  isOpen,
  onClose,
  title = 'EXPORT RECIPE',
  message = 'This will export the recipe in a JSON format. Confirm to begin the export process.',
}) => {
  const { handleFetchRecipeMetadata, isLoading } = useExportRecipeFile();

  const handleOnExport = async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!recipeId) return;
    event?.stopPropagation();

    handleFetchRecipeMetadata({ recipeId, onSuccess: () => onClose(event) });
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCofirm={handleOnExport}
      title={title}
      isProcessing={isLoading}
    >
      <Typography sx={{ pb: 2 }}>
        {isLoading ? 'Exporting recipe.. This may take a few moments.' : message}
      </Typography>
    </ConfirmModal>
  );
};

export default ExportRecipeModal;
