import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet } from 'react-router-dom';
import { ErrorFallback, LoadingOverlay, Sidebar, TableSkeleton } from '../../../components';
import { NavigationPaths } from '../../../constants';
import {
  ErrorContextProvider,
  FilterContextProvider,
  SearchContextProvider,
  useAuthentication,
} from '../../../context';

const DashboardOutlet: React.FC = () => {
  const { user, isLoading } = useAuthentication();

  if (isLoading) return <LoadingOverlay />;

  if (!user && !isLoading) {
    return <Navigate to={NavigationPaths.LOGIN} replace />;
  }

  return (
    <SearchContextProvider>
      <FilterContextProvider>
        <ErrorContextProvider>
          <Sidebar>
            <ErrorBoundary fallback={<ErrorFallback />}>
              <Suspense fallback={<TableSkeleton />}>
                <Outlet />
              </Suspense>
            </ErrorBoundary>
          </Sidebar>
        </ErrorContextProvider>
      </FilterContextProvider>
    </SearchContextProvider>
  );
};

export default DashboardOutlet;
