export const burnersSortingOrders: { [key: string]: number } = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  Chamber: 5,
  Rare: 6,
  'Med Rare': 7,
  Medium: 8,
  'Med Well': 9,
  'Well Done': 10,
  Default: 11,
};
