import { Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { EventListSkeleton } from '../../components';
import { TimeToSeconds } from '../../constants';
import { queries } from '../queries';
import { EventItem } from './components';

const Events = () => {
  const { data, isFetching, isLoading } = useQuery({
    ...queries.dashboard.events(),
    staleTime: TimeToSeconds.ONE_HOUR,
    refetchOnReconnect: true,
    refetchOnMount: true,
    cacheTime: 0,
  });

  if (isLoading || isFetching) return <EventListSkeleton count={10} />;
  if (!data) return null;

  return (
    <Stack direction='column' gap={2} width='66.66%'>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
          Recent activities
        </Typography>
        <Typography>Last {data?.length} activitites</Typography>
      </Stack>
      {data.length === 0 && <Typography>No activities</Typography>}
      {data?.map((e) => (
        <EventItem key={e.id} event={e} />
      ))}
    </Stack>
  );
};

export default Events;
