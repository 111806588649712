export enum NavigationPaths {
  DASHBOARD = '/',
  OVERVIEW = '/recipes/overview',
  CATEGORIES = '/recipes/categories',
  INGREDIENTS = '/recipes/ingredients',
  UNITS = '/recipes/units',
  USERS = '/admin/users',
  SETTINGS = '/admin/settings',
  LOGIN = '/login',
  DETAILS = '/recipes/details',
  ACCESSORIES = '/recipes/accessories',
}
