import { Stack, Typography } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import { FacebookIcon, InstagramIcon, Linkedin } from '../../shared';
import grillBackground from '../../shared/assets/grill-background.png';

type Props = {
  language?: string;
};

const KoqoonFooter: React.FC<Props> = ({ language }) => {
  return (
    <>
      <Stack direction='column' justifyContent='center' alignItems='center' sx={{ width: '100%' }}>
        <img
          src={grillBackground}
          alt='Koqoon Grill'
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Stack>
      <Stack direction='column' gap={2} justifyContent='center' alignItems='center' py={3}>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
          KOQOON GmbH & Co. KG
        </Typography>
        <Stack direction='column' alignItems='center' sx={{ color: '#aaa' }}>
          <Typography variant='caption'>Dieselstraße 4</Typography>
          <Typography variant='caption'>73278 Schlierbach</Typography>
          <Typography variant='caption'>{language === 'en' ? 'Germany' : 'Deutschland'}</Typography>
        </Stack>
        <Stack direction='column' alignItems='center' sx={{ color: '#aaa' }}>
          <Typography variant='caption'>{`${
            language === 'en' ? 'Phone' : 'Telephone'
          }: +49 7021 736646-0`}</Typography>
          <Typography variant='caption'>{`${
            language === 'en' ? 'Mail' : 'E-Mail'
          }: info@koqoon.com`}</Typography>
          <Typography variant='caption'>
            Web: <Link to='https://koqoon.com'>https://koqoon.com</Link>
          </Typography>
        </Stack>
        <Stack direction='column' alignItems='center' sx={{ color: '#aaa' }} gap={2}>
          <Typography variant='body1' sx={{ color: 'kqn.white', fontWeight: 'bold' }}>
            KOQOON {language === 'en' ? 'in' : 'im'} Social Web
          </Typography>
          <Stack direction='row' gap={2} alignItems='center' justifyContent='center'>
            <NavLink
              to='https://www.linkedin.com/company/koqoon-official'
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: 'none', color: '#aaa', fontSize: '12px' }}
            >
              <Linkedin className='icon' />
            </NavLink>
            <NavLink
              to='https://www.instagram.com/koqoon.official'
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: 'none', color: '#aaa', fontSize: '12px' }}
            >
              <InstagramIcon className='icon' />
            </NavLink>
            <NavLink
              to='https://www.facebook.com/koqoon.official'
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: 'none', color: '#aaa', fontSize: '12px' }}
            >
              <FacebookIcon className='icon' />
            </NavLink>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default KoqoonFooter;
