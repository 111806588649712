import { Box } from '@mui/material';
import { RecipesEnhancedTableActions, RecipeTableHeaderActions } from '.';
import { MainLayout, Navigation, TableWrapper } from '../../../../components';
import { CategoryResponse } from '../../../types';
import { RecipeInfinityDataset } from './recipe-infinity-dataset';

type Props = {
  category?: CategoryResponse;
  setCategory: (category?: CategoryResponse) => void;
};

export const RecipeInfinityView: React.FC<Props> = ({ category, setCategory }) => {
  return (
    <>
      <Navigation>
        <RecipesEnhancedTableActions />
      </Navigation>
      <TableWrapper>
        <Box sx={{ width: '100%' }}>
          <MainLayout>
            <RecipeTableHeaderActions category={category} setCategory={setCategory} />
            <RecipeInfinityDataset category={category} />
          </MainLayout>
        </Box>
      </TableWrapper>
    </>
  );
};
