import { ChartData } from '../chart-types';

/**
 * If first step has a positive prepInterval, we need to calculate the startOffsetCorrection
 * so that every other step is adjusted accordingly.
 * @param data - The data to parse
 * @returns startOffsetCorrection - The start offset correction
 */
export function calculateStartOffsetCorrection(data: ChartData[]) {
  const firstStep = data?.[0]?.steps?.[0];
  const hasNegativeValue = data && (firstStep?.prepInterval || 0) > 0;
  const startOffsetCorrection = hasNegativeValue ? firstStep?.prepInterval || 0 : 0;

  return startOffsetCorrection;
}
