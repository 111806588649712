import { Autocomplete, Chip, FormControl, Stack, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { Optional, Title } from '../../../components';
import { useRecipeActions, useRecipeStore } from '../../../store';
import { queries } from '../../queries';
import CreateAccessoryModal from './create-accessory-modal';

const AccessoriesPicker = () => {
  const { updateAccessories } = useRecipeActions();
  const accessories = useRecipeStore.use.accessories();
  const [openCreate, setOpenCreate] = useState(false);

  const { data } = useQuery({
    ...queries.accessories.filter({}),
  });

  const handleChange = useCallback(
    (value: { name: string; id: string }[]) => {
      const values = data?.data.filter((d) => value.map((v) => v.id).includes(d.id));
      updateAccessories(values || []);
    },
    [data, updateAccessories],
  );

  return (
    <Stack direction='column' flexWrap='wrap'>
      <Title title='Accessories' />
      <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
        <FormControl size='small'>
          <Autocomplete
            disableCloseOnSelect
            value={accessories.map((a) => ({ id: a.id, name: a.name }))}
            inputValue=''
            isOptionEqualToValue={(option, value) => value === undefined || option.id === value.id}
            multiple
            id='tags-standard'
            options={(data?.data || []).map((d) => ({ id: d.id, name: d.name }))}
            getOptionLabel={(option) => option.name}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  data-testid='accessory-chip'
                  key={option.id}
                  label={option.name}
                  sx={{
                    color: 'kqn.cooper',
                    borderRadius: '30px',
                    bgcolor: 'kqn.ultraLightCooper',
                    height: '24px',
                    '& .MuiChip-deleteIcon': {
                      color: 'kqn.cooper',
                    },
                  }}
                />
              ))
            }
            defaultValue={accessories.map((a) => ({ id: a.id, name: a.name }))}
            onChange={(_, value) => handleChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                sx={{
                  '& .MuiAutocomplete-inputRoot': {
                    py: 0,
                    px: 1,
                    minWidth: '200px',
                    '& fieldset': { borderColor: 'kqn.darkerGray' },
                    '&.Mui-focused fieldset': { borderColor: 'kqn.cooper' },
                  },
                }}
              />
            )}
          />
        </FormControl>
      </Stack>
      <Optional condition={openCreate}>
        <CreateAccessoryModal isOpen={openCreate} onClose={() => setOpenCreate(false)} />
      </Optional>
    </Stack>
  );
};

export default AccessoriesPicker;
