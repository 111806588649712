import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ApronIcon, GrillLogo, IngredientsIcon } from '../../../../../shared';

export const tabs: {
  label: string;
  icon: JSX.Element;
  testid?: string;
}[] = [
  {
    label: 'General',
    icon: <ErrorOutlineIcon />,
    testid: 'general-tab',
  },
  {
    label: 'Ingredients',
    icon: <IngredientsIcon className='icon' />,
    testid: 'ingredients-tab',
  },
  {
    label: 'Preparation',
    icon: <ApronIcon className='icon' />,
    testid: 'preparation-tab',
  },
  {
    label: 'Grilling',
    icon: <GrillLogo className='icon' />,
    testid: 'grill-tab',
  },
];
