import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { KQNTextInputField } from '../text-input-field';
import { useResetPassword } from './mutations/use-reset-password';
import { ForgotPasswordSchemaType, resetPasswordSchema } from './schema';

type Props = {
  token?: string;
  onClose?: () => void;
};

const ResetPasswordForm: React.FC<Props> = ({ token, onClose }) => {
  const mutation = useResetPassword();

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: { password: '', confirmPassword: '' },
  });

  const handleOnSubmit = async (data: ForgotPasswordSchemaType) => {
    const { password, confirmPassword } = data;

    if (!password || !confirmPassword) {
      toast.error('Password and confirm password are required');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    mutation.mutate({ password, token });
  };

  return (
    <Box component='form' onSubmit={handleSubmit(handleOnSubmit)}>
      <Stack direction='column' alignItems='center' gap={1}>
        <KQNTextInputField
          type='password'
          name='password'
          control={control}
          label='New Password'
          required
          sx={{ input: { WebkitBoxShadow: '0 0 0 30px white inset !important' } }}
        />
        <KQNTextInputField
          type='password'
          name='confirmPassword'
          control={control}
          label='Confirm Password'
          required
          sx={{ input: { WebkitBoxShadow: '0 0 0 30px white inset !important' } }}
        />
      </Stack>
      <Stack justifyContent='flex-end' alignContent='center' direction='row' gap={2} mt={2}>
        <Button type='button' variant='text' onClick={onClose}>
          CANCEL
        </Button>
        <Button type='submit' variant='contained'>
          SAVE
        </Button>
      </Stack>
    </Box>
  );
};

export default ResetPasswordForm;
