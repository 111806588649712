import { useMutation } from '@tanstack/react-query';
import { koqoonApi } from '../../../config';
import { Paths } from '../../../constants';
import { useAuthentication } from '../../../context';

export const useResetPassword = () => {
  const { signOut } = useAuthentication();

  const mutation = useMutation(
    ({ password, token }: { password: string; token?: string }) =>
      koqoonApi.post('/auth/reset-password', {
        password,
        token,
      }),
    {
      onSuccess: () => signOut({ redirectPath: Paths.LOGIN }),
    },
  );

  return mutation;
};
