import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Stack, Typography } from '@mui/material';

type Props = {
  text?: string;
  label?: string;
};

export const SelectView = ({ text, label }: Props) => {
  return (
    <Stack
      direction='column'
      gap={1}
      sx={{
        width: '100%',
      }}
    >
      <Typography>{label}</Typography>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          border: '1px solid #B9B9B9',
          borderRadius: '4px',
          p: 1,
          width: '100%',
          height: '40px',
        }}
      >
        <Typography>{text}</Typography>
        <ArrowDropDownIcon />
      </Stack>
    </Stack>
  );
};
