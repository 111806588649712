import { PaginatedResult } from '../../../types';
import { AccessoryModel } from '../../types';

export const mapAccessoryRepsonseToObject = (data: PaginatedResult<AccessoryModel>) => {
  return {
    meta: data.meta,
    data: data.data.map((ac) => ({
      ...ac,
      recipeCount: ac.recipes?.map((a) => a.id).length || 0,
    })),
  };
};
