import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { colors } from '../../../../../../../shared';
import { NodeType } from '../chart-types';

type Props = {
  step: Partial<NodeType>;
  isActive?: boolean;
};

const FlowDuration: React.FC<Props> = ({ step, isActive }) => {
  const color = `${colors[step.currentOrdinal || 0]}`;

  return (
    <Tooltip
      open={isActive}
      title={
        <Stack alignItems='center' direction='row' gap={2}>
          {!step.isInfo && (
            <Stack direction='row' alignItems='center' data-testid='flow-temperature'>
              {step.temperature}°C
            </Stack>
          )}
          <Stack direction='row' alignItems='center' data-testid='flow-cookingTime'>
            <AccessTimeIcon className='icon' />
            {step.cookingTime}s
          </Stack>
        </Stack>
      }
      placement='top'
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            bgcolor: '#e4e4e4',
            color: 'kqn.darkerGray',
            fontSize: '14px',
            lineHeight: '20px',
            py: '8px',
            px: '12px',
            borderRadius: '8px',
            maxWidth: '500px',
          },
        },
      }}
      sx={{ bgcolor: 'kqn.gray' }}
    >
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor: color,
          filter: isActive ? 'contrast(150%)' : 'none',
          borderRadius: '4px',
          height: '100%',
          width: `${step.cookingWidth}px`,
          position: 'relative',
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          px={1}
          alignItems='center'
          width='100%'
          sx={{ fontSize: '12px' }}
        >
          <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            <Typography sx={{ fontSize: '12px' }}>{step.name}</Typography>
            {(step.width || 0) - (step?.preheatingWidth || 0) > 70 && !step.isInfo && (
              <Stack direction='row' alignItems='center' data-testid='flow-temperature'>
                {step.temperature}°C
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>
    </Tooltip>
  );
};

export default FlowDuration;
