import { Box, Button, Typography } from '@mui/material';
import { errorAnimation } from '../../shared/assets';
import { drawerWidth } from '../sidebar/sidebar-user-actions';

const ErrorFallback: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: `calc(100vh - ${drawerWidth}px)`,
        width: '100%',
      }}
      gap={2}
    >
      <img
        src={errorAnimation}
        alt='error-animation'
        style={{
          height: 'auto',
          marginBottom: '2rem',
        }}
      />
      <Typography variant='body1' gutterBottom>
        Please try again, or if the issue persists, please contact the technical support team
      </Typography>
      <Button
        variant='contained'
        onClick={() => {
          window.location.reload();
        }}
      >
        Try again
      </Button>
    </Box>
  );
};

export default ErrorFallback;
