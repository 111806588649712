import { useMemo } from 'react';
import { useGroupStore, useRecipeStore } from '../../../../../../../store';
import { useAccessoriesValidation } from './use-accessories-validation';
import { useNutrientValidation } from './use-nutrients-validation';

export const useIngredientsValidation = () => {
  const hasAccessoriesChanged = useAccessoriesValidation();
  const hasNutrientChanged = useNutrientValidation();
  const groups = useGroupStore.use.groups();
  const initialGroupsData = useGroupStore.use.initialGroups();
  const recipe = useRecipeStore.use.recipe();

  const initRecipe = useRecipeStore.use.initialData();

  const hasIngredientsChanges = useMemo(() => {
    if (hasAccessoriesChanged) return true;
    if (hasNutrientChanged) return true;
    const nutritionProperties = ['fat', 'protein', 'carbs', 'energy'];

    if (!initialGroupsData || groups.length !== initialGroupsData.length) return true;
    if (recipe.maxNumberOfPeople !== initRecipe.maxNumberOfPeople) return true;
    if (
      nutritionProperties.some(
        (prop) =>
          recipe[prop as keyof typeof recipe] !== initRecipe[prop as keyof typeof initRecipe],
      )
    ) {
      return true;
    }

    const hasValueChanged = groups.some((group) => {
      const initial = initialGroupsData.find((ig) => ig.id === group.id);

      if (!initial || group.name !== initial.name || group.nameDe !== initial.nameDe) return true;
      if (group.ingredients.length !== initial.ingredients.length) return true;

      const hasIngredientValueChanged = group.ingredients.some((ingredient) => {
        const initIngredient = initial.ingredients.find((ii) => ii.id === ingredient.id);

        if (
          !initIngredient ||
          ingredient.quantity !== initIngredient.quantity ||
          ingredient.unit !== initIngredient.unit ||
          ingredient.id !== initIngredient.id
        ) {
          return true;
        }

        return false;
      });

      return hasIngredientValueChanged;
    });

    return hasValueChanged;
  }, [hasAccessoriesChanged, hasNutrientChanged, initialGroupsData, groups, recipe, initRecipe]);

  return {
    hasIngredientsChanges,
  };
};
