import { Stack } from '@mui/material';
import { KQNTextField, TextInputAndormnet } from '../../../../../../../components';
import { ErrorKeys } from '../../../../../../../constants';
import { useErrorContext } from '../../../../../../../context';
import { RecipePreparation } from '../../../../../../types';

type Props = {
  preparation: RecipePreparation;
  onChange: (field: keyof RecipePreparation, value: string) => void;
};

const PreparationStepInfo: React.FC<Props> = ({ preparation, onChange }) => {
  const { error } = useErrorContext();

  return (
    <Stack direction='row' gap={2} width='100%'>
      <KQNTextField
        size='small'
        placeholder='Description (EN)'
        name='descriptionEn'
        multiline
        minRows={1}
        maxRows={3}
        type='text'
        fullWidth
        InputProps={{
          sx: {
            bgcolor: 'kqn.white',
            minHeight: '40px',
            '& .MuiInputBase-input': { pr: 1, py: 1 },
          },
          startAdornment: <TextInputAndormnet text='EN' />,
        }}
        value={preparation.description}
        onChange={(e) => onChange('description', e.target.value)}
        error={!!error.get(`${preparation.id}-${ErrorKeys.DESCRIPTION}`)}
        helperText={error.get(`${preparation.id}-${ErrorKeys.DESCRIPTION}`)}
      />
      <KQNTextField
        size='small'
        type='text'
        multiline
        minRows={1}
        maxRows={3}
        name='descriptionDe'
        placeholder='Description (DE)'
        fullWidth
        value={preparation.descriptionDe}
        InputProps={{
          sx: {
            bgcolor: 'kqn.white',
            minHeight: '40px',
            '& .MuiInputBase-input': { pr: 1, py: 1 },
          },
          startAdornment: <TextInputAndormnet text='DE' />,
        }}
        onChange={(e) => onChange('descriptionDe', e.target.value)}
        error={!!error.get(`${preparation.id}-${ErrorKeys.DESCRIPTION_DE}`)}
        helperText={error.get(`${preparation.id}-${ErrorKeys.DESCRIPTION_DE}`)}
      />
    </Stack>
  );
};
export default PreparationStepInfo;
