import React from 'react';
import { GrillStep, GrillStepType } from '../../../../../../../../../../types';
import { createStep } from '../helpers';
import AddStepMenu from './add-step-menu';

interface Props {
  mealId: string;
  presets: GrillStep[];
  onAddStep: (mealId: string, step: GrillStep) => void;
}

const AddStep: React.FC<Props> = ({ mealId, presets, onAddStep }) => {
  const handleAddStep = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, type: GrillStepType) => {
    e.stopPropagation();

    if (!mealId) return;

    onAddStep(mealId, createStep(presets, type));
  };

  return (
    <AddStepMenu handleAddStep={handleAddStep} disabled={presets.some((step) => step.isDraft)} />
  );
};

export default AddStep;
