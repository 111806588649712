import { Stack } from '@mui/system';
import React from 'react';
import { SelectView } from '../../../../../../../../../shared/components';
import { MethodType, ModeType } from '../../../../../../../../types';

type Props = {
  preset: string;
  method: MethodType;
  mode: ModeType;
};

const PresetsView: React.FC<Props> = ({ preset, mode, method }) => {
  return (
    <Stack direction='column' gap={2} sx={{ width: '100%' }} justifyContent='space-between'>
      <Stack direction='column' gap={1} sx={{ width: '100%' }}>
        <Stack direction='row' gap={3} alignItems='center'>
          <SelectView text={preset} label='Doneness Level' />
          <SelectView text={mode.name} label='Grill Mode' />
          <SelectView text={method.name} label='Grilling Method' />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PresetsView;
