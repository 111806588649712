import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Navigation, TabPanel } from '../../../../components';
import { useErrorContext } from '../../../../context';
import { useRecipeStore } from '../../../../store';
import { RecipeInfo } from '../recipe-info';
import {
  GeneralComposer,
  GrillComposer,
  IngredientsComposer,
  PreparationComposer,
} from './components';
import { HeaderTabs } from './tabs';

interface Props {
  isLoading?: boolean;
}

const RecipeComposer: React.FC<Props> = ({ isLoading }) => {
  const recipe = useRecipeStore.use.recipe();
  const { resetError } = useErrorContext();
  const [value, setValue] = useState(0);
  const [params] = useSearchParams();
  const recipeId = params.get('recipeId');

  useEffect(() => {
    resetError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipeId]);

  return (
    <Stack width='100%' direction='column'>
      <Navigation>
        <RecipeInfo recipe={recipe} />
      </Navigation>
      <HeaderTabs value={value} onChange={setValue} isDisabled={!recipeId} recipe={recipe} />
      <TabPanel value={value} index={0}>
        <GeneralComposer recipeId={recipeId} isLoading={isLoading} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <IngredientsComposer recipeId={recipeId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PreparationComposer recipeId={recipeId} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GrillComposer recipeId={recipeId} />
      </TabPanel>
    </Stack>
  );
};

export default RecipeComposer;
