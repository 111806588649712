import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useGroupStore } from '../../../../../../../../store';
import { queries } from '../../../../../../../queries';
import Groups from './groups';

interface Props {
  recipeId: string;
}

const GroupsComposer: React.FC<Props> = ({ recipeId }) => {
  const { setGroups } = useGroupStore.use.actions();

  const { data, isFetching } = useQuery({
    ...queries.groups.details(recipeId || undefined),
    enabled: !!recipeId,
  });

  useEffect(() => {
    if (data) {
      setGroups(data);
    }
  }, [data, setGroups]);

  if (isFetching) return <div>Loading...</div>;
  if (!data) return <div>Recipe not found</div>;

  return <Groups />;
};

export default GroupsComposer;
