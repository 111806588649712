import {
  closestCenter,
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, SortingStrategy } from '@dnd-kit/sortable';

interface Props<T> {
  children: React.ReactNode;
  items: T[];
  onDragEnd: (event: DragEndEvent) => void;
  collisionDetection?: CollisionDetection;
  strategy: SortingStrategy;
  onDragStart?: (event: DragStartEvent) => void;
  isDisabled?: boolean;
}

const DragNDropContext = <T extends { id?: string }>({
  children,
  onDragEnd,
  onDragStart,
  collisionDetection = closestCenter,
  items,
  strategy,
  isDisabled,
}: Props<T>) => {
  const sensors = useSensors(useSensor(PointerSensor));
  const mappedItems = items.map((item) => item.id || '');

  return (
    <DndContext
      collisionDetection={collisionDetection}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      sensors={sensors}
    >
      <SortableContext disabled={isDisabled} items={mappedItems} strategy={strategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default DragNDropContext;
