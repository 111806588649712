import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useErrorContext } from '../../../../../../../../../context';
import { createErrorMap } from '../../../../../../../../../shared';
import { queries } from '../../../../../../../../queries';
import { InfoGrillStep } from '../../../../../../../../types';
import { upsertInfoGrillStepSchema } from '../../../schema/upsert-grill-step-schema';
import { grillService } from '../../../service';

interface Props {
  mealId: string;
  preset: InfoGrillStep;
  onUpdate?: (mealId: string, presetId: string, preset: InfoGrillStep) => void;
}

export const useUpsertInfoStep = () => {
  const queryClient = useQueryClient();
  const { setError, resetError } = useErrorContext();

  const mutation = useMutation<InfoGrillStep, unknown, { mealId: string; preset: InfoGrillStep }>(
    ({ mealId, preset }) => grillService.upsertInfoGrillStep(mealId, preset),
  );

  const handleUpsertInfoGrillStep = async ({ mealId, preset, onUpdate }: Props) => {
    try {
      const isValid = upsertInfoGrillStepSchema.safeParse(preset);

      if (!isValid.success) {
        const errors = createErrorMap(isValid.error, preset.id);
        setError(errors);
        return;
      }

      resetError();

      mutation.mutate(
        { mealId, preset },
        {
          onSuccess(data) {
            if (onUpdate) {
              onUpdate(mealId, preset.id, data);
            }
            queryClient.invalidateQueries(queries.meals.filter._def);
            toast.success('Step saved successfully');
          },
          onError(err: any) {
            toast.error(err || 'Failed to save non-grill step');
          },
        },
      );
    } catch (err: any) {
      /* empty */
    }
  };

  return { handleUpsertInfoGrillStep, isLoading: mutation.isLoading };
};
