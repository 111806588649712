import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { Ingredient } from '../../types';
import { ingredientsService } from '../services/ingredients-service';

interface Data {
  ingredientIds?: string[];
}
interface Props {
  names: string;
  ingredientsWithGroups: Ingredient[];
  ingredientsWithoutGroups: Ingredient[];
  onSucess?: () => void;
}

export const useRemoveIngredients = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<unknown, unknown, Data>(({ ingredientIds }) =>
    ingredientsService.removeIngredients(ingredientIds),
  );

  const handleRemoveIngredients = async ({
    ingredientIds,
    onSucess,
    ingredientsWithGroups,
    ingredientsWithoutGroups,
    names,
  }: Data & Props) => {
    mutation.mutate(
      { ingredientIds },
      {
        onSuccess: () => {
          const deletedNames = ingredientsWithoutGroups
            .map((ingredient) => ingredient.name)
            .join(', ');

          if (ingredientsWithGroups.length > 0) {
            toast.error(`The following ingredients cannot be deleted: ${names}.`);
          }

          queryClient.refetchQueries(queries.ingredients.filter._def);
          toast.success(`The following ingredients have been deleted: ${deletedNames}.`);
          onSucess?.();
        },
        onError: (err: any) => {
          toast.error(err.message || 'Something went wrong');
        },
      },
    );
  };

  return {
    handleRemoveIngredients,
    isLoading: mutation.isLoading,
    mutation,
  };
};
