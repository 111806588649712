import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { CreateIngredientRequest } from '../../types';
import { ingredientsService } from '../services/ingredients-service';

interface Data {
  ingredientId: string;
  data: CreateIngredientRequest;
}

interface Props {
  onSuccess?: () => void;
}

export const useUpgradeIngredient = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, Data>(({ ingredientId, data }) =>
    ingredientsService.updateIngredient(ingredientId, data),
  );

  const handleUpdateIngredient = async ({ ingredientId, data, onSuccess }: Data & Props) => {
    mutation.mutate(
      { ingredientId, data },
      {
        onSuccess: () => {
          queryClient.refetchQueries(queries.ingredients.filter._def);
          queryClient.invalidateQueries(queries.ingredients.details._def);
          toast.success('Ingredient is successfully updated.', { duration: 3000 });
          onSuccess?.();
        },
        onError: (e: any) => {
          toast.error(e.message || 'Something went wrong!');
        },
      },
    );
  };

  return {
    mutation,
    handleUpdateIngredient,
    isLoading: mutation.isLoading,
  };
};
