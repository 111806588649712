import { ZodError } from 'zod';

function getPathKey(arr: any[]) {
  return `${arr[arr.length - 1]}`;
}

export function createErrorMap(err: ZodError, prefix?: string) {
  const errMap = new Map();

  err.errors.forEach((error) => {
    const path = prefix ? `${prefix}-${getPathKey(error.path)}` : getPathKey(error.path);

    if (errMap.has(path)) {
      errMap.get(path).push(error.message);
    } else {
      errMap.set(path, error.message);
    }
  });

  return errMap;
}
