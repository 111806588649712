import { ConfirmModal } from '../../../../components';
import { PageableUnit } from '../../../types';
import { useRemoveUnit } from '../../mutations';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selected?: PageableUnit;
  onSucess?: () => void;
}

const RemoveUnitModal: React.FC<Props> = ({ isOpen, onClose, selected, onSucess }) => {
  const { handleRemoveUnit } = useRemoveUnit();
  const handleDelete = async () => {
    if (!selected) return;

    await handleRemoveUnit({
      unitId: selected.id,
      onSuccess: () => {
        onSucess?.();
        onClose();
      },
    });
  };

  return <ConfirmModal isOpen={isOpen} onClose={onClose} onCofirm={handleDelete} />;
};

export default RemoveUnitModal;
