import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../../../../../queries';
import { GrillStep } from '../../../../../../types';
import { grillService } from '../service';

interface Data {
  steps: GrillStep[];
}

export const useUpdatePresetOrdinals = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<{ recipeId: string }, unknown, Data>(({ steps }) =>
    grillService.updateGrillOrdinals(steps),
  );

  const handleUpdatePresetOrdinals = async ({ steps }: Data) => {
    mutation.mutate(
      { steps },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queries.meals.filter._def);
          toast.success('Step oridnals updated successfully');
        },
        onError: (error) => {
          toast.error(error as string);
        },
      },
    );
  };

  return { mutation, handleUpdatePresetOrdinals, isLoading: mutation.isLoading };
};
