import { InputAdornment } from '@mui/material';

type Props = {
  text: string;
  position?: 'start' | 'end';
  bgcolor?: string;
  color?: string;
};

const TextInputAndormnet: React.FC<Props> = ({
  text,
  position = 'start',
  color = '#201D1D',
  bgcolor = 'inherit',
  ...rest
}) => {
  return (
    <InputAdornment
      position={position}
      sx={{
        px: '7px',
        bgcolor,
        borderRight: '2px solid #AF6B48',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: position === 'start' ? '2px 0 0 2px' : '0 2px 2px 0',
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: 300,
        letterSpacing: '0.5px',
        height: '100%',
        m: 0,

        '& p': {
          color,
        },

        ...rest,
      }}
    >
      {text}
    </InputAdornment>
  );
};

export default TextInputAndormnet;
