import { Burner } from '../../../../../../types';
import { Node } from '../chart-types';

export function generateBurnerNodes(burners: Burner[], node: Node) {
  const burnersWithNodesArr: { zone: string; node: Node }[] = [];

  burners.forEach((burner) => {
    const burnerNode = {
      zone: burner.name || '',
      node: {
        ...node,
        temperature: burner.temperature,
      },
    };

    burnersWithNodesArr.push(burnerNode);
  });

  return burnersWithNodesArr;
}
