import { Box, Stack, Typography } from '@mui/material';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import ImageIcon from './image-icon';

type Props = {
  isDragActive?: boolean;
  acceptedExtensions: string[];
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
};

const DropzoneArea: React.FC<Props> = ({
  getInputProps,
  acceptedExtensions,
  getRootProps,
  isDragActive,
}) => {
  return (
    <Stack
      sx={{ border: '1px dashed #B9B9B9', width: '50%', py: 2 }}
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} data-testid='dropzone-input' />
        <Stack direction='column' gap={1} justifyContent='center' alignItems='center'>
          {isDragActive ? (
            <p>Drop the file here.</p>
          ) : (
            <>
              <Typography variant='body1'>
                <Box
                  component='span'
                  sx={{
                    color: 'kqn.green',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Click to upload
                </Box>{' '}
                or drag and drop
              </Typography>
              <Stack direction='row' gap={1} justifyContent='center' alignItems='center'>
                <ImageIcon />
                <Typography variant='caption' sx={{ color: 'kqn.darkGray' }}>
                  {acceptedExtensions.join(', ')} (max 5MB)
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </div>
    </Stack>
  );
};

export default DropzoneArea;
