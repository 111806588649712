import { Stack } from '@mui/material';
import React from 'react';
import { KQNTextField, TextInputAndormnet, Title } from '../../../../../../../../../../components';
import { useMealActions } from '../../../../../../../../../../store/meal-store';
import { Burner } from '../../../../../../../../../types';
import { burnersSortingOrders } from '../../../../../../../../helpers';
import { shorterBurnerName } from '../burner-timer/helpers/shorter-burner-name';

type Props = {
  mealId: string;
  stepId: string;
  title: string;
  burners: Burner[];
  minTemperature: number;
  maxTemperature: number;
  tooltipText?: string;
  type?: 'probes' | 'burners';
};

const Burners: React.FC<Props> = ({
  mealId,
  stepId,
  title,
  burners,
  minTemperature,
  maxTemperature,
  tooltipText,
  type = 'burners',
}) => {
  const { updateBurners } = useMealActions();

  if (!burners.length) return null;

  const handleUpdateBurnerTemperature = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof Burner,
    identifier: string,
  ) => {
    if (Number(e.target.value) > maxTemperature || Number(e.target.value) < 0) {
      return;
    }
    const newBurners = burners?.map((burner) => {
      if (burner.burnerId === identifier) {
        return {
          ...burner,
          [field]: Number(e.target.value),
        };
      }

      return burner;
    });

    if (!newBurners) return;

    updateBurners(mealId, stepId, newBurners, type);
  };

  return (
    <Stack direction='column' gap={1}>
      <Title title={title} tooltipText={tooltipText} />
      <Stack direction='row' gap={2}>
        {burners
          .slice()
          ?.sort((a, b) => burnersSortingOrders[a.name] - burnersSortingOrders[b.name])
          .map((burner) => (
            <KQNTextField
              key={burner.burnerId}
              id={burner.name}
              size='small'
              type='number'
              variant='outlined'
              placeholder='0'
              data-testid='burner-temperature'
              value={burner.temperature || ''}
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              InputProps={{
                inputProps: { min: minTemperature, max: maxTemperature },
                startAdornment: (
                  <TextInputAndormnet
                    text={shorterBurnerName(burner.name as keyof typeof shorterBurnerName)}
                    position='start'
                  />
                ),
                sx: { minWidth: '120px' },
              }}
              onChange={(e) =>
                handleUpdateBurnerTemperature(e, 'temperature', burner.burnerId || '')
              }
            />
          ))}
      </Stack>
    </Stack>
  );
};
export default Burners;
