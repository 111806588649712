import { Box, MenuItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CreateButton, Dropdown, Optional, SearchFilter } from '../../../../components';
import { Role } from '../../../../constants';
import { useAuthentication } from '../../../../context';
import { CategoryResponse } from '../../../types';
import { CreateCategoriesModal, DeleteCategoryModal, UpdateCategoriesModal } from '../modals';

type Props = {
  category?: CategoryResponse;
  readonly selected: string[];
  setSelected: (selected: string[]) => void;
};

export const CategoriesEnhancedTableActions: React.FC<Props> = ({
  category,
  selected,
  setSelected,
}) => {
  const [params, setSearchParams] = useSearchParams();
  const isCreateModalOpen = params.get('modal') === 'true';
  const id = params.get('id');
  const { user: authUser } = useAuthentication();
  const [openCreate, setOpenCreate] = useState<boolean>(isCreateModalOpen || false);
  const [openEdit, setOpenEditModal] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const isTester = authUser?.role === Role.TESTER;

  useEffect(() => {
    if (id && category) {
      setOpenEditModal(true);
    }
  }, [id, category, setSelected]);

  const handleOnEdit = () => {
    setOpenEditModal(true);
  };

  const handleOnEditClose = () => {
    params.delete('id');
    setSearchParams(params);
    setOpenEditModal(false);
  };

  const handleIngredientCreateModal = () => {
    setOpenCreate(true);
  };

  const handleOnDelete = () => {
    setOpenDelete(true);
  };

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pt: 1 }}
      >
        <SearchFilter />
        <Stack direction='row' gap={2}>
          <Optional condition={selected.length === 1 && !isTester}>
            <Dropdown label='ACTION'>
              {selected.length === 1 && (
                <MenuItem
                  onClick={handleOnEdit}
                  data-testid='edit-button'
                  sx={{ borderBottom: '1px solid #AF6B48' }}
                >
                  Edit
                </MenuItem>
              )}
              {selected.length === 1 && (
                <MenuItem
                  onClick={handleOnDelete}
                  sx={{ color: 'kqn.coral' }}
                  data-testid='delete-button'
                >
                  Delete
                </MenuItem>
              )}
            </Dropdown>
          </Optional>
          {!isTester && <CreateButton text='ADD' onClick={handleIngredientCreateModal} />}
        </Stack>
      </Box>
      <Optional condition={openCreate}>
        <CreateCategoriesModal isOpen={openCreate} onClose={() => setOpenCreate(false)} />
      </Optional>
      <Optional condition={openEdit && category}>
        <UpdateCategoriesModal category={category!} isOpen={openEdit} onClose={handleOnEditClose} />
      </Optional>
      <Optional condition={openDelete}>
        <DeleteCategoryModal
          categoryId={category?.id}
          isOpen={openDelete}
          onClose={() => setOpenDelete(false)}
          onSucess={() => setSelected([])}
        />
      </Optional>
    </>
  );
};
