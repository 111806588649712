import { useState } from 'react';

const useActionStates = () => {
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEditModal] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleOnEdit = () => {
    setOpenEditModal(true);
  };

  const handleOpenCreateModal = () => {
    setOpenCreate(true);
  };

  const handleOnActionBtnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = () => {
    setOpenFilter((prev) => !prev);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return {
    openCreate,
    openEdit,
    openFilter,
    anchorEl,
    isMenuOpen,
    handleOnEdit,
    handleOpenCreateModal,
    handleOnActionBtnClick,
    handleOnMenuClose,
    handleFilter,
    handleMenuClose,
  };
};

export default useActionStates;
