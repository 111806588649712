import { Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { EventListSkeleton } from '../../../../components';
import { TimeToSeconds } from '../../../../constants';
import { queries } from '../../../queries';
import CategoriesListItem from './categories-list-item';

const CategoriesList: React.FC = () => {
  const { data, isLoading, isFetching } = useQuery({
    ...queries.dashboard.categories(),
    staleTime: TimeToSeconds.ONE_DAY,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (isLoading || isFetching) return <EventListSkeleton />;
  if (!data) return null;

  return (
    <Stack direction='column' gap={2} width='33.33%'>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
          Popular recipe categories
        </Typography>
        <Typography>{data?.length} most popular</Typography>
      </Stack>
      {data?.map((cat) => (
        <CategoriesListItem key={cat.id} category={cat} />
      ))}
    </Stack>
  );
};

export default CategoriesList;
