import { createId } from '@paralleldrive/cuid2';
import { Notification, Trigger } from '../../../../../../../../../../types';

export function createNotification(
  notifications: Notification[],
  triggers: Trigger[],
): Notification {
  return {
    id: createId(),
    ordinal:
      (notifications || []).length < 1
        ? 1
        : Math.max(...(notifications || []).map((n) => n.ordinal || 0)) + 1,
    title: '',
    titleDe: '',
    message: '',
    messageDe: '',
    inAppMessage: '',
    inAppMessageDe: '',
    triggers: triggers || [],
    isDraft: true,
  };
}
