import { koqoonApi } from '../../../../../../../../../config';
import { Paths } from '../../../../../../../../../constants';
import { RecipeGroupModel } from '../../../../../../../../types';

const getRecipeGroups = async (recipeId?: string) => {
  if (!recipeId) return Promise.resolve([]);

  return koqoonApi.get(`${Paths.RECIPES}/${recipeId}${Paths.MEAL_GROUPS}`).then((response) => {
    return response.data as RecipeGroupModel[];
  });
};

export const groupService = {
  getRecipeGroups,
};
