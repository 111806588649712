import { Button, CircularProgress, Stack } from '@mui/material';

type Props = {
  submitText?: string;
  cancelText?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClose: () => void;
};

const ComposerFooterButtons: React.FC<Props> = ({
  submitText = 'SAVE',
  cancelText = 'CLOSE',
  isLoading,
  onClose,
  disabled,
}) => {
  return (
    <Stack direction='row' gap={3} alignContent='center' justifyContent='flex-end'>
      <Button type='submit' variant='text' onClick={onClose} data-testid='close-button'>
        {cancelText}
      </Button>
      <Button
        disabled={disabled}
        type='submit'
        variant='contained'
        data-testid='save-button'
        startIcon={isLoading && <CircularProgress size={15} sx={{ color: 'kqn.white' }} />}
      >
        {submitText}
      </Button>
    </Stack>
  );
};

export default ComposerFooterButtons;
