import AddIcon from '@mui/icons-material/Add';
import { ModalComposer } from '../../../../components';
import { useCreateCategory } from '../../mutations/use-create-category';
import { UpdateCategorySchemaType, UpsertCategoryForm } from '../forms';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateCategoriesModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { handleCreateCategory, isLoading } = useCreateCategory();

  const handleOnSubmit = async (data: UpdateCategorySchemaType) => {
    await handleCreateCategory({
      ...data,
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <ModalComposer
      icon={<AddIcon className='icon' />}
      title='ADD CATEGORY'
      isOpen={isOpen}
      onClose={onClose}
    >
      <UpsertCategoryForm onClose={onClose} onSubmit={handleOnSubmit} isSubmitting={isLoading} />
    </ModalComposer>
  );
};

export default CreateCategoriesModal;
