import { PageableRecipe, RecipeHeadCells } from '../../../../types';

export const mapRecipesToCells = (recipes: PageableRecipe[]): RecipeHeadCells[] => {
  return recipes.map((recipe) => {
    return {
      id: recipe.id,
      name: recipe.name,
      maxNumberOfPeople: recipe.maxNumberOfPeople.toString(),
      complexity: recipe.complexity.name,
      categories: recipe.categories.map((cat) => cat.name).join(', '),
      isPublished: recipe.isPublished ? 'true' : 'false',
      completion: recipe.isDraft ? 'Draft' : 'Done',
      isRemoved: recipe.isRemoved ? 'true' : 'false',
      thumbnailUrl: recipe.thumbnailUrl || '',
    };
  });
};
