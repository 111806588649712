import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { CreateUnitRequest } from '../../types';
import { unitService } from '../services';

interface Props {
  unitId: string;
  data: CreateUnitRequest;
  onSuccess?: () => void;
}

export const useUpdateUnit = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, Props>(({ unitId, data }) =>
    unitService.updateUnit(unitId, data),
  );

  const handleUpdateUnit = async ({ unitId, data, onSuccess }: Props) => {
    mutation.mutate(
      { unitId, data },
      {
        onSuccess: () => {
          queryClient.refetchQueries(queries.units.filter._def);
          queryClient.refetchQueries(queries.units.details._def);
          toast.success('Unit is successfully updated.');
          onSuccess?.();
        },
        onError: () => {
          toast.error('Something went wrong');
        },
      },
    );
  };

  return {
    handleUpdateUnit,
    isLoading: mutation.isLoading,
    mutation,
  };
};
