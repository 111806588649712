import { koqoonApi } from '../../../../../../../config';
import { AccessoryModel, RecipeGroupModel } from '../../../../../../types';

const recipesPath = '/groups';

const updateGroupIngredients = async (
  recipeId: string,
  maxNumberOfPeople: number,
  nutrients: { energy: number; carbs: number; protein: number; fat: number },
  groups: RecipeGroupModel[],
  accessories?: AccessoryModel[],
) => {
  try {
    const response = await koqoonApi.post(`${recipesPath}`, {
      recipeId,
      nutrients,
      maxNumberOfPeople,
      groups,
      accessories,
    });

    return response.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const groupService = {
  updateGroupIngredients,
};
