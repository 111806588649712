import { z } from 'zod';

export const upsertUnitSchema = z.object({
  name: z.string().min(1, 'Unit name must be at least 2 characters.'),
  nameDe: z.string().min(1, 'Unit name must be at least 2 characters.'),
  namePluralDe: z.string().min(1, 'Unit name must be at least 2 characters.'),
  namePlural: z.string().min(1, 'Unit name must be at least 2 characters.'),
});

export type UnitSchemaType = z.infer<typeof upsertUnitSchema>;
