import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { userService } from '../services';

interface Props {
  userId: string;
  onSuccess?: () => void;
}

export const useRemoveUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, { userId: string }>(userService.deleteAccount);

  const handleRemoveUser = async ({ userId, onSuccess }: Props) => {
    mutation.mutate(
      { userId },
      {
        onSuccess: () => {
          toast.success('Users account removed successfully');
          queryClient.refetchQueries(queries.users.filter._def);
          onSuccess?.();
        },
        onError: (e: any) => {
          toast.error(e.message);
        },
      },
    );
  };

  return { handleRemoveUser, isLoading: mutation.isLoading, mutation };
};
