/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { Box, Stack, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useTranslations } from '../../../../shared';
import { ResetPasswordForm } from './reset-password-form';
import ResetSuccessfulPage from './reset-successful-page';

interface Props {
  language?: string;
}

const ResetPasswordPage: React.FC<Props> = ({ language = 'en' }) => {
  const [params] = useSearchParams();
  const token = params.get('token');
  const isSuccessful = params.get('isResetSuccesful');
  const translations = useTranslations({ language });

  if (isSuccessful) {
    return <ResetSuccessfulPage language={language} />;
  }

  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: 'sm',
        }}
      >
        <Typography variant='h6' fontWeight='bold'>
          {translations.passwordResetTitle}
        </Typography>
        <Stack direction='column' gap={2}>
          <Typography variant='h6'>{translations.passwordResetText}</Typography>
          <ul style={{ textAlign: 'left', paddingInlineStart: '15px' }}>
            <li>{translations.firstPasswordRule}</li>
            <li>{translations.secondPasswordRule}</li>
            <li>{translations.thirdPasswordRule}</li>
            <li>{translations.fourthPasswordRule}</li>
          </ul>
        </Stack>
      </Box>
      <ResetPasswordForm token={token} language={language} />
    </>
  );
};

export default ResetPasswordPage;
