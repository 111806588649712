import { Box, Stack } from '@mui/material';

type Props = {
  time: number;
};

const Timestamp: React.FC<Props> = ({ time }) => {
  return (
    <Stack
      direction='column'
      alignItems='start'
      position='absolute'
      sx={{ width: '100%', bottom: 0 }}
    >
      <Box sx={{ border: '1px #c3c3c3 dashed', height: '500px' }} />
      <p>{time}</p>
    </Stack>
  );
};
export default Timestamp;
