import { Stack } from '@mui/material';
import React from 'react';
import { grillConfig } from '../../../../../../../../../shared';
import { Burner, GrillStep } from '../../../../../../../../types';
import BurnersView from './burners-view';
import PreparationIntervalView from './preparation-interval-view';

interface Props {
  step: GrillStep;
  burners?: Burner[];
  probes?: Burner[];
}

const BurnerTemperatureView: React.FC<Props> = ({ burners, probes, step }) => {
  return (
    <Stack direction='column' alignContent='space-between' sx={{ width: '100%' }}>
      <Stack direction='row' gap={2} sx={{ width: '100%' }} justifyContent='space-between'>
        <Stack justifyContent='start' width='100%' direction='row' gap={4}>
          <BurnersView
            title='Zone Temperature °C'
            burners={burners || []}
            tooltipText={`Temparature range for zone is ${grillConfig.MIN_ZONE_TEMP} - ${grillConfig.MAX_ZONE_TEMP}°C`}
          />
          <PreparationIntervalView prepInterval={step.prepInterval} />
        </Stack>
        <Stack justifyContent='start' width='100%'>
          <BurnersView
            title='Probe Temperature °C'
            burners={probes || []}
            tooltipText={`Max temperature for probe is ${grillConfig.MAX_PROBE_TEMP}°C`}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BurnerTemperatureView;
