interface HeaderConfig {
  hasSave: boolean;
  hasPublish: boolean;
  hasDuplicate: boolean;
  hasExport: boolean;
}

const config: Record<number, HeaderConfig> = {
  0: {
    hasSave: true,
    hasPublish: true,
    hasDuplicate: true,
    hasExport: true,
  },
  1: {
    hasSave: true,
    hasPublish: false,
    hasDuplicate: false,
    hasExport: false,
  },
  2: {
    hasSave: false,
    hasPublish: false,
    hasDuplicate: false,
    hasExport: false,
  },
  3: {
    hasSave: false,
    hasPublish: false,
    hasDuplicate: false,
    hasExport: false,
  },
};

export const useHeaderConfig = (value: number) => {
  return config[value as keyof typeof config];
};
