import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoRow, KQNIconButton, Optional } from '../../../components';
import { NavigationPaths } from '../../../constants';
import { PageableUnit } from '../../types';
import { RemoveUnitModal, UpdateUnitModal } from './modals';

type Props = {
  unit: PageableUnit;
};

const UnitSummary: React.FC<Props> = ({ unit }) => {
  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = useState(false);
  const { name, nameDe, namePlural, nameDePlural } = unit;
  const [openDelete, setOpenDelete] = useState(false);

  const handleOnEditClose = () => {
    setOpenEdit(false);
  };
  return (
    <Paper sx={{ maxWidth: '500px', p: 3, height: 'fit-content' }}>
      <Stack direction='column' gap={3} width='100%'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography>UNIT PROFILE</Typography>
          <Stack direction='row' justifyContent='flex-end' justifyItems='center' gap={2}>
            <KQNIconButton onClick={() => setOpenEdit(true)}>
              <EditOutlinedIcon sx={{ color: 'kqn.darkGray', fontSize: '27px' }} />
            </KQNIconButton>
            <KQNIconButton onClick={() => setOpenDelete(true)}>
              <DeleteOutlineIcon sx={{ color: 'kqn.coral', fontSize: '27px' }} />
            </KQNIconButton>
          </Stack>
        </Stack>
        <Stack direction='column' gap={3} sx={{ color: 'kqn.darkGray' }}>
          <InfoRow label='Singular Name (EN)' value={name} />
          <InfoRow label='Singular Name (DE)' value={nameDe} />
          <InfoRow label='Name Plural (EN)' value={namePlural} />
          <InfoRow label='Name Plural (DE)' value={nameDePlural} />
        </Stack>
      </Stack>
      <Optional condition={openEdit}>
        <UpdateUnitModal unit={unit} isOpen={openEdit} onClose={handleOnEditClose} />
      </Optional>
      <Optional condition={openDelete}>
        <RemoveUnitModal
          selected={unit}
          isOpen={openDelete}
          onClose={() => setOpenDelete(false)}
          onSucess={() => {
            navigate(NavigationPaths.UNITS);
          }}
        />
      </Optional>
    </Paper>
  );
};

export default UnitSummary;
