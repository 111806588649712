import { Node } from '../chart-types';

/**
 * This function is used to check if the curren node and the latest node are from the same meal.
 */
export function isSameMeal(currNode: Node, latestNode?: Node) {
  if (!latestNode) return false;

  return latestNode && currNode.mealId === latestNode.mealId;
}
