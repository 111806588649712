import { PaginatedResult } from '../../../types';
import { PageableUnit, PageableUnitResponse } from '../../types';

export const mapUnitsResponseToObject = (
  units?: PaginatedResult<PageableUnitResponse>,
): PaginatedResult<PageableUnit> => {
  const defaultMeta = {
    total: 10,
    lastPage: 1,
    currentPage: 1,
    perPage: 10,
    prev: null,
    next: null,
  };

  const mapped = (units?.data || []).map((unit) => ({
    id: unit.id,
    isActive: unit.isActive ? 'active' : 'inactive',
    shortName: unit.shortName || '-',
    name: unit.name || '-',
    namePlural: unit.namePlural || '-',
    nameDe: unit.nameDe || '-',
    nameDePlural: unit.nameDePlural || '-',
  }));

  return {
    data: mapped,
    meta: units?.meta || defaultMeta,
  };
};
