import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { MainLayout, Navigation, TableWrapper } from '../../../../components';
import { CategoryResponse, RecipeBaseModel } from '../../../types';
import RecipeEnhancedTableDataset from './recipe-enhanced-table-dataset';
import RecipeTableHeaderActions from './recipe-table-header-actions';
import { RecipesEnhancedTableActions } from './recipes-ehanched-table-actions';

interface Props {
  category?: CategoryResponse;
  selected: string[];
  setCategory: (category?: CategoryResponse) => void;
  handleOnSelect: (event: React.MouseEvent<unknown>, name: string) => void;
}

export const RecipesEnhancedTableView: React.FC<Props> = ({
  category,
  selected,
  setCategory,
  handleOnSelect,
}) => {
  const [recipe, setRecipe] = useState<RecipeBaseModel | undefined>(undefined);

  const handleOnRecipeSelect = useCallback(
    (pr?: RecipeBaseModel) => {
      if (selected.length > 1) {
        setRecipe(undefined);
        return;
      }

      if (pr) {
        setRecipe(pr);
      }
    },
    [selected.length],
  );

  return (
    <>
      <Navigation>
        <RecipesEnhancedTableActions recipe={recipe} selected={selected} />
      </Navigation>
      <TableWrapper>
        <Box sx={{ width: '100%' }}>
          <MainLayout>
            <RecipeTableHeaderActions category={category} setCategory={setCategory} />
            <RecipeEnhancedTableDataset
              category={category}
              selected={selected}
              handleOnSelect={handleOnSelect}
              onRecipeSelect={handleOnRecipeSelect}
            />
          </MainLayout>
        </Box>
      </TableWrapper>
    </>
  );
};
