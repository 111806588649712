import { Box, MenuItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CreateButton, Dropdown, Optional, SearchFilter } from '../../../../components';
import { Role } from '../../../../constants';
import { useAuthentication } from '../../../../context';
import { PageableIngredient } from '../../../types';
import { CreateIngredientModal, DeleteIngredientModal, UpdateIngredientModal } from '../modals';

type Props = {
  ingredients?: PageableIngredient[];
  readonly selected: string[];
  setSelected: (selected: string[]) => void;
};

export const IngredientsEnhancedTableActions: React.FC<Props> = ({
  ingredients,
  selected,
  setSelected,
}) => {
  const [params, setSearchParams] = useSearchParams();
  const isCreateModalOpen = params.get('modal') === 'true';
  const id = params.get('id');
  const { user: authUser } = useAuthentication();
  const [openCreate, setOpenCreate] = useState<boolean>(isCreateModalOpen || false);
  const [openEdit, setOpenEditModal] = useState<boolean>(false);
  const [openDelete, setOpenDeleteModal] = useState<boolean>(false);
  const isTester = authUser?.role === Role.TESTER;
  const ingredient = ingredients?.find((i) => i.id === selected[0]);

  useEffect(() => {
    if (id && !!selected.length) {
      setOpenEditModal(true);
    }
  }, [id, selected]);

  const handleOnEdit = () => {
    setOpenEditModal(true);
  };

  const handleOnEditClose = () => {
    params.delete('id');
    setSearchParams(params);
    setOpenEditModal(false);
  };

  const handleIngredientCreateModal = () => {
    setOpenCreate(true);
  };

  const handleOnDelete = () => {
    setOpenDeleteModal(true);
  };

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pt: 1 }}
      >
        <SearchFilter />
        <Stack direction='row' gap={2}>
          <Optional condition={selected.length >= 1 && !isTester}>
            <Dropdown label='ACTION'>
              {selected.length === 1 && (
                <MenuItem
                  onClick={handleOnEdit}
                  data-testid='edit-ingredient-button'
                  sx={{ borderBottom: '1px solid #AF6B48' }}
                >
                  Edit
                </MenuItem>
              )}
              <MenuItem
                data-testid='delete-ingredient-button'
                onClick={handleOnDelete}
                sx={{ color: 'kqn.coral' }}
              >
                Delete
              </MenuItem>
            </Dropdown>
          </Optional>
          {!isTester && <CreateButton text='ADD' onClick={handleIngredientCreateModal} />}
        </Stack>
      </Box>
      <Optional condition={openCreate}>
        <CreateIngredientModal isOpen={openCreate} onClose={() => setOpenCreate(false)} />
      </Optional>
      <Optional condition={openEdit && ingredient}>
        <UpdateIngredientModal
          ingredient={ingredient!}
          isOpen={openEdit}
          onClose={handleOnEditClose}
        />
      </Optional>
      <Optional condition={openDelete}>
        <DeleteIngredientModal
          ingredients={ingredients?.filter((i) => selected.includes(i.id)) || []}
          isOpen={openDelete}
          onClose={() => setOpenDeleteModal(false)}
          onSucess={() => setSelected([])}
        />
      </Optional>
    </>
  );
};
