import { SelectChangeEvent } from '@mui/material';
import { Nutrient } from '../../../../types';

interface Props {
  setSelectedUnits: (value: React.SetStateAction<string[]>) => void;
  setNutrientValues: (value: React.SetStateAction<Nutrient[]>) => void;
}

export const useFormChangeActions = ({
  setSelectedUnits,
  setNutrientValues: setNutrientsValues,
}: Props) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedUnits(typeof value === 'string' ? value.split(',') : value);
  };

  const handleNutrientChange = (nutrient: Nutrient, idx: number, value: string) => {
    setNutrientsValues((prev) => {
      const newNutrientValues = [...prev];

      newNutrientValues[idx] = {
        ...nutrient,
        amount: Number(value),
      };
      return newNutrientValues;
    });
  };

  return {
    handleChange,
    handleNutrientChange,
  };
};
