import { Duration } from '../../../../../../../../types';

export const initialDurations: Duration[] = [
  {
    id: '1',
    name: 'Rare',
    duration: 0,
  },
  {
    id: '2',
    name: 'Med Rare',
    duration: 0,
  },
  {
    id: '3',
    name: 'Medium',
    duration: 0,
  },
  {
    id: '4',
    name: 'Med Well',
    duration: 0,
  },
  {
    id: '5',
    name: 'Well Done',
    duration: 0,
  },
];

export const singleDuration: Duration[] = [
  {
    id: '1',
    name: '',
    duration: 0,
  },
];
