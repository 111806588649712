import { IconButton } from '@mui/material';
import React from 'react';

interface Props {
  isDisabled?: boolean;
  children: React.ReactNode;
  onClick?: (args?: any) => void;
}

const KQNIconButton: React.FC<Props> = ({ onClick, children, isDisabled }) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={isDisabled}
      aria-disabled={isDisabled}
      disableRipple
      sx={{ p: 0 }}
    >
      {children}
    </IconButton>
  );
};

export default KQNIconButton;
