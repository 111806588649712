import { ConfirmModal } from '../../../../components';
import { useRemoveCategory } from '../../mutations';

type Props = {
  categoryId?: string;
  isOpen: boolean;
  onClose: () => void;
  onSucess?: () => void;
};

const DeleteCategoryModal: React.FC<Props> = ({ categoryId, isOpen, onClose, onSucess }) => {
  const { handleRemoveCategory, isLoading } = useRemoveCategory();

  const onConfirm = async () => {
    if (!categoryId) return;

    await handleRemoveCategory({
      categoryId,
      onSuccess: () => {
        onSucess?.();
        onClose();
      },
    });
  };

  return (
    <ConfirmModal isOpen={isOpen} onClose={onClose} onCofirm={onConfirm} isProcessing={isLoading} />
  );
};

export default DeleteCategoryModal;
