import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Box, Button, InputAdornment, ListItemText, MenuItem, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateButton, Dropdown, KQNTextField, Optional } from '../../../../components';
import { Role } from '../../../../constants';
import { useAuthentication, useRecipesFilter } from '../../../../context';
import { RecipeBaseModel } from '../../../types';
import ExportRecipeModal from '../export-recipe/export-recipe-modal';
import { RecipeFilterAction } from '../filter';
import { ImportRecipe } from '../import-recipe';
import { DeleteRecipeModal, DuplicateRecipeModal, RestoreRecipeModal } from '../modals';

type Props = {
  recipe?: RecipeBaseModel;
  readonly selected?: string[];
};

export const RecipesEnhancedTableActions: React.FC<Props> = ({ recipe, selected }) => {
  const { query, setQuery, resetFilter } = useRecipesFilter();
  const navigate = useNavigate();
  const { user: authUser } = useAuthentication();
  const [deletePrompt, setDeletePrompt] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openRestore, setOpenRestore] = useState<boolean>(false);
  const [openDuplicate, setOpenDuplicate] = useState<boolean>(false);
  const isTester = authUser?.role === Role.TESTER;
  const [exportOpen, setExportOpen] = useState<boolean>(false);

  const handleFilter = () => {
    setOpenFilter((prev) => !prev);
    if (openFilter) {
      resetFilter();
    }
  };

  const handleOnDelete = async () => {
    setDeletePrompt(true);
  };

  const handleOpenDuplicate = () => {
    setOpenDuplicate(true);
  };

  const handleOpenRestore = () => {
    setOpenRestore(true);
  };

  return (
    <Stack direction='column' gap={2} width='100%'>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        gap={2}
        sx={{ pt: 1 }}
      >
        <Button
          variant='contained'
          color='info'
          startIcon={<FilterListIcon />}
          data-testid='filter-button'
          endIcon={openFilter ? <CheckIcon /> : null}
          sx={{
            bgcolor: openFilter ? 'kqn.green' : 'kqn.lightGray',
            color: openFilter ? '#fff' : 'kqn.darkerGray',
            minWidth: 'unset',
            ':hover': { bgcolor: openFilter ? 'kqn.green' : 'kqn.gray' },
          }}
          onClick={handleFilter}
        >
          FILTER
        </Button>
        <KQNTextField
          size='small'
          borderRadius='8px'
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          sx={{ width: '250px', bgcolor: 'kqn.gray', color: 'kqn.darkGray' }}
          InputProps={{
            sx: { color: 'kqn.darkerGray' },
            startAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder='Search'
          type='search'
        />
        <Box
          display='flex'
          width='100%'
          flexDirection='row'
          alignItems='center'
          gap={4}
          justifyContent='flex-end'
        >
          <Stack direction='row' gap={1.5}>
            <ImportRecipe />

            <Optional condition={selected?.length === 1 && !isTester}>
              <Dropdown label='ACTION'>
                <MenuItem
                  onClick={() => navigate(`/recipes/create?recipeId=${recipe?.id}`)}
                  data-testid='edit-button'
                  sx={{ pb: 1, borderBottom: '1px solid #AF6B48' }}
                >
                  <Stack direction='row' gap={1}>
                    <EditIcon />
                    <ListItemText primary='Edit' />
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={handleOpenDuplicate}
                  data-testid='duplicate-recipe-button'
                  sx={{ py: 1, borderBottom: '1px solid #AF6B48' }}
                >
                  <Stack direction='row' gap={1}>
                    <ContentCopyIcon />
                    <ListItemText primary='Duplicate' />
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={() => setExportOpen(true)}
                  data-testid='export-recipe-button'
                  sx={{ py: 1, borderBottom: '1px solid #AF6B48' }}
                >
                  <Stack direction='row' gap={1}>
                    <FileUploadIcon />
                    <ListItemText primary='Export' />
                  </Stack>
                </MenuItem>
                {recipe?.isRemoved && (
                  <MenuItem
                    onClick={handleOpenRestore}
                    data-testid='restore-button'
                    sx={{ color: 'kqn.green', py: 1, borderBottom: '1px solid #AF6B48' }}
                  >
                    <Stack direction='row' gap={1}>
                      <SettingsBackupRestoreIcon sx={{ color: 'kqn.green' }} />
                      <ListItemText primary='Restore' />
                    </Stack>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={handleOnDelete}
                  data-testid='delete-button'
                  sx={{ color: 'kqn.coral', pt: 1 }}
                >
                  <Stack direction='row' gap={1} alignItems='center'>
                    <DeleteOutlineIcon sx={{ color: 'kqn.coral' }} />
                    <ListItemText
                      primary={recipe?.isRemoved ? 'Delete Permanently' : 'Delete'}
                      primaryTypographyProps={{ whiteSpace: 'normal', lineHeight: '1' }}
                    />
                  </Stack>
                </MenuItem>
              </Dropdown>
            </Optional>
            {!isTester && <CreateButton text='ADD' onClick={() => navigate('/recipes/create')} />}
          </Stack>
        </Box>
        <Optional condition={openDuplicate}>
          <DuplicateRecipeModal
            recipe={recipe}
            isOpen={openDuplicate}
            onClose={() => setOpenDuplicate(false)}
          />
        </Optional>
        {openRestore && recipe && (
          <RestoreRecipeModal
            recipeId={recipe.id}
            isOpen={openRestore}
            onClose={() => setOpenRestore(false)}
          />
        )}
        <Optional condition={deletePrompt}>
          <DeleteRecipeModal
            isPermanent={recipe?.isRemoved}
            isOpen={deletePrompt}
            onClose={() => setDeletePrompt(false)}
            recipeId={recipe?.id}
          />
        </Optional>
        <Optional condition={exportOpen}>
          <ExportRecipeModal
            isOpen={exportOpen}
            onClose={() => setExportOpen(false)}
            recipeId={recipe?.id}
          />
        </Optional>
      </Stack>
      <Optional condition={openFilter}>
        <RecipeFilterAction />
      </Optional>
    </Stack>
  );
};
