import { Box, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import ModalPopup from '../modal-popup/modal-popup';

type Props = {
  title?: string;
  isProcessing?: boolean;
  isOpen: boolean;
  disabled?: boolean;
  processTitle?: string;
  onClose: (event: any) => void;
  onCofirm?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
};

const ConfirmModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onCofirm,
  disabled,
  title,
  isProcessing,
  children,
}) => {
  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <Box border={0} sx={{ bgcolor: 'background.paper', p: 3, boxShadow: 24, width: '100%' }}>
        <Stack sx={{ color: 'kqn.cooper', pb: 2 }} gap={2}>
          <Typography component='h1' variant='h5' sx={{ textTransform: 'uppercase' }}>
            {title || 'Are you sure you want to proceed?'}
          </Typography>
          <Divider sx={{ borderColor: 'kqn.cooper' }} />
        </Stack>
        {children}
        <Stack alignItems='center' direction='row' gap={3} justifyContent='flex-end'>
          <Button data-testid='cancel-button' variant='text' onClick={onClose}>
            CANCEL
          </Button>
          {onCofirm && (
            <Button
              data-testid='confirm-button'
              variant='contained'
              disabled={isProcessing || disabled}
              onClick={(e) => onCofirm(e)}
              startIcon={isProcessing && <CircularProgress sx={{ color: 'kqn.white' }} size={16} />}
            >
              CONFIRM
            </Button>
          )}
        </Stack>
      </Box>
    </ModalPopup>
  );
};

export default ConfirmModal;
