import { AccessoryHeadCells, AccessoryModel } from '../../types';

export const mapAccessoriesToCells = (accessories: AccessoryModel[]): AccessoryHeadCells[] => {
  return accessories.map((acc) => ({
    id: acc.id,
    name: acc.name,
    nameDe: acc.nameDe,
    recipeCount: acc.recipeCount,
  }));
};
