import { Box, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Optional } from '../../../../components';
import { RecipeBaseModel } from '../../../types';
import RecipeActions from './recipe-actions';
import RecipeCardBase from './recipe-card-base';

type Props = {
  recipe: RecipeBaseModel;
};

const RecipeCard: React.FC<Props> = ({ recipe }) => {
  const navigate = useNavigate();
  const { id } = recipe;

  const handleNavigate = useCallback(() => {
    navigate(`/recipes/details?recipeId=${id}`);
  }, [id, navigate]);

  return (
    <RecipeCardBase recipe={recipe}>
      <Box
        sx={{
          position: 'relative',
          zIndex: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '.5rem',
          color: 'kqn.white',
          height: '100%',
        }}
        onClick={handleNavigate}
      >
        <Optional condition={recipe.isRemoved}>
          <Stack
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              px: 3,
              py: 1,
              bgcolor: 'kqn.white',
              border: '2px solid red',
              opacity: '0.5',
              color: 'red',
              borderRadius: '2px',
            }}
          >
            DELETED
          </Stack>
        </Optional>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack direction='row' alignItems='center' gap={0.5}>
            <Typography variant='body1'>{recipe.cookingTime} min</Typography>
          </Stack>
          <Typography variant='body1'>{recipe.complexity.name}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography className='line-clamp' sx={{ maxWidth: '85%' }}>
            {recipe.name}
          </Typography>
          <RecipeActions recipe={recipe} />
        </Stack>
      </Box>
    </RecipeCardBase>
  );
};

export default RecipeCard;
