import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ConfirmModal, KQNTextField, TextInputAndormnet, Title } from '../../../../components';
import { useDebounceValue } from '../../../../shared';
import { useDuplicateRecipe } from '../../mutations';
import { recipeService } from '../../services';

type Props = {
  recipe?: { id: string; name: string };
  isOpen: boolean;
  onClose: (event?: any) => void;
};

const DuplicateRecipeModal: React.FC<Props> = ({ isOpen, onClose, recipe }) => {
  const initialName = `${recipe?.name} - copy`;
  const { handleDuplicateRecipe, isLoading: isDuplicating } = useDuplicateRecipe();
  const [isSuccess, setIsSuccess] = useState(false);
  const [recipeName, setRecipeName] = useState(initialName);
  const debouncedValue = useDebounceValue(recipeName, 500);

  const { data, isFetching, isLoading } = useQuery({
    queryKey: [debouncedValue],
    queryFn: () => recipeService.validateRecipeName([debouncedValue]),
    enabled: !!debouncedValue,
  });

  const onConfirm = async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    if (!recipe?.id) return;
    event?.stopPropagation();

    await handleDuplicateRecipe({
      recipeId: recipe?.id,
      recipeName,
      onSuccess: () => setIsSuccess(true),
    });
  };

  if (!recipe) return null;

  const renderBody = () => {
    if (isDuplicating)
      return <Typography>Duplicating recipe... This may take a few moments.</Typography>;
    if (!isDuplicating && isSuccess)
      return (
        <Typography>
          Recipe duplicated successfully and added to your recipe list. Please review and edit the
          new recipe as needed.
        </Typography>
      );

    return (
      <>
        <Typography variant='body1' color='kqn.darkerGray'>
          Duplicating this recipe will create a new recipe with identical data.
        </Typography>
        <Typography variant='body1' color='kqn.darkerGray'>
          Please provide a unique name in both language fields for the new recipe.
        </Typography>
      </>
    );
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCofirm={!isSuccess ? onConfirm : undefined}
      disabled={!recipeName || !data || isFetching || isLoading}
      isProcessing={isDuplicating}
      title='DUPLICATE RECIPE'
    >
      <Stack pb={3} gap={2}>
        <Stack direction='column'>{renderBody()}</Stack>
        {!isDuplicating && !isSuccess && (
          <Stack gap={1}>
            <Title title='Recipe Name' />
            <KQNTextField
              size='small'
              fullWidth
              variant='outlined'
              value={recipeName}
              onChange={(e) => setRecipeName(e.target.value)}
              error={data === false}
              helperText={data === false ? 'Recipe name already exists.' : ''}
              InputProps={{
                startAdornment: <TextInputAndormnet text='EN' position='start' />,
              }}
            />
            <KQNTextField
              size='small'
              fullWidth
              variant='outlined'
              value={recipeName}
              onChange={(e) => setRecipeName(e.target.value)}
              error={data === false}
              helperText={data === false ? 'Recipe name already exists.' : ''}
              InputProps={{
                startAdornment: <TextInputAndormnet text='DE' position='start' />,
              }}
            />
          </Stack>
        )}
      </Stack>
    </ConfirmModal>
  );
};

export default DuplicateRecipeModal;
