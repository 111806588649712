export const colors = [
  '#07BEB8',
  '#EFD6AC',
  '#DAD641',
  '#219ebc',
  '#fefae0',
  '#9a8c98',
  '#c8b6ff',
  '#cbf3f0',
  '#eb5e28',
  '#f5ebe0',
  '#98c1d9',
];
