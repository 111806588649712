import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useErrorContext } from '../../../context';
import { useGroupStore, useRecipeStore } from '../../../store';
import { useCreateGroupIngredients } from '../mutations';

const ingredientsSchema = z.object({
  maxNumberOfPeople: z.number().min(0, { message: 'Max number of people must be provided' }),
  nutrients: z.object({
    energy: z.number().min(0, { message: 'Energy value must be provided' }),
    carbs: z.number().min(0, { message: 'Carbs value must be provided' }),
    protein: z.number().min(0, { message: 'Protein value must be provided' }),
    fat: z.number().min(0, { message: 'Fat value must be provided' }),
  }),
  groups: z.array(
    z.object({
      id: z.string().min(1, { message: 'Group must be created' }),
      name: z.string().optional(),
      ingredients: z.array(
        z.object({
          id: z.string(),
          name: z.string().min(1, { message: 'Ingredient name must be provided' }),
          quantity: z.number().min(0, { message: 'Quantity must be provided' }),
          unitId: z.string().min(1, { message: 'Unit must be provided' }),
        }),
      ),
    }),
  ),
  recipeId: z.string().min(1, { message: 'Recipe id must be valid' }),
});

export const useIngredientsSubmit = () => {
  const { resetError } = useErrorContext();
  const groups = useGroupStore.use.groups();
  const { maxNumberOfPeople } = useRecipeStore.use.recipe();
  const { carbs, energy, fat, protein } = useRecipeStore.use.nutrients();
  const accessories = useRecipeStore.use.accessories();
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get('recipeId');
  const { handleSubmit, isLoading } = useCreateGroupIngredients();

  const handleSubmitIngredients = async () => {
    if (!recipeId) return;

    const groupIngredients = {
      maxNumberOfPeople,
      nutrients: {
        energy: energy || 0,
        carbs: carbs || 0,
        protein: protein || 0,
        fat: fat || 0,
      },
      groups,
      recipeId,
      accessories,
    };

    const validation = ingredientsSchema.safeParse(groupIngredients);
    if (!validation.success) {
      toast.error('Please update ingredient values. Some ingredients are missing');
      return;
    }

    await handleSubmit(groupIngredients);
    resetError();
  };

  return {
    handler: handleSubmitIngredients,
    isLoading,
  };
};
