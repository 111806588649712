/* eslint-disable no-return-assign */
import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Stack, Typography } from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';
import { DragNDropContext, Optional } from '../../../../../../../../../../components';
import { useHandleDragEnd, useScrollIntoView } from '../../../../../../../../../../shared';
import { useMealActions, usePresets } from '../../../../../../../../../../store/meal-store';
import { GrillStep, Meal } from '../../../../../../../../../types';
import { useUpdatePresetOrdinals } from '../../../../mutations';
import { TemperaturePresetView } from '../../../preset-view';
import { useGetBurners } from '../../hooks';
import { AddStep } from './components';

interface Props {
  meal: Meal;
}

const MealSteps: React.FC<Props> = ({ meal }) => {
  const { addPreset, setPresets } = useMealActions();
  const presets = usePresets(meal.id);
  const [dragged, setDragged] = useState<GrillStep | null>(null);
  const { handleUpdatePresetOrdinals } = useUpdatePresetOrdinals();
  const { probes } = useGetBurners();
  const hasDraft = useMemo(() => meal?.steps?.some((step) => step.isDraft), [meal?.steps]);
  const { ref, scrollIntoView } = useScrollIntoView({ behavior: 'smooth', block: 'end' });

  const handleOnUpdate = async (updated: GrillStep[]) => {
    if (meal.id) {
      setPresets(meal.id, updated);
      await handleUpdatePresetOrdinals({ steps: updated });
    }
  };

  useEffect(() => {
    if (hasDraft) {
      scrollIntoView();
    }
  }, [hasDraft, scrollIntoView]);

  const handleDragEnd = useHandleDragEnd<GrillStep>({
    data: meal?.steps || [],
    onUpdate: handleOnUpdate,
  });

  const handleOnDragEnd = (event: DragEndEvent) => {
    setDragged(null);
    handleDragEnd(event);
  };

  if (!meal) return null;

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const step = presets?.find((g) => g.id === active.id);
    if (step) setDragged(step);
  };

  return (
    <Stack gap={2} direction='column' justifyContent='flex-start' my={2}>
      <Stack direction='column' gap={2}>
        <Optional condition={!meal.steps}>
          <Typography variant='h6' align='center'>
            Please select a meal type step to add grill steps to it. You can add multiple grill
            steps to a meal type step.
          </Typography>
        </Optional>
        <Optional condition={meal}>
          <AddStep mealId={meal.id} onAddStep={addPreset} presets={meal.steps} />
        </Optional>
        <DragNDropContext
          items={presets}
          onDragEnd={handleOnDragEnd}
          onDragStart={handleDragStart}
          strategy={verticalListSortingStrategy}
          isDisabled={hasDraft}
        >
          {presets?.map((preset, idx) => {
            return (
              <div key={preset.id}>
                <TemperaturePresetView
                  preset={preset}
                  probes={probes}
                  mealId={meal.id}
                  ordinal={idx + 1}
                  isDragging={!!dragged}
                />
                <div ref={ref} />
              </div>
            );
          })}
        </DragNDropContext>
      </Stack>
    </Stack>
  );
};
export default memo(MealSteps);
