import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { recipeService } from '../services/recipe-service';

export const useRecipePublishing = () => {
  const mutation = useMutation<{ id: string; isPublished: boolean }, unknown, { id: string }>(
    ({ id }) => recipeService.publishRecipe(id),
  );

  const handlePublish = async (id: string, onUpdate?: (isPublished: boolean) => void) => {
    mutation.mutate(
      { id },
      {
        onSuccess: ({ isPublished }) => {
          const message = !isPublished
            ? 'Recipe unpublished successfully'
            : 'Recipe published successfully';

          toast.success(message);
          if (onUpdate) {
            onUpdate(isPublished);
          }
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    handlePublish,
    isLoading: mutation.isLoading,
    mutation,
  };
};
