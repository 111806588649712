import { ModalComposer } from '../../../../components';
import { PageableUnit } from '../../../types';
import { useUpdateUnit } from '../../mutations';
import { UnitSchemaType, UpsertUnitForm } from '../forms';

interface Props {
  unit: PageableUnit;
  isOpen: boolean;
  onClose: () => void;
}

const UpdateUnitModal: React.FC<Props> = ({ unit, isOpen, onClose }) => {
  const { handleUpdateUnit, isLoading } = useUpdateUnit();

  const handleOnSubmit = async (data: UnitSchemaType) => {
    await handleUpdateUnit({ unitId: unit.id, data, onSuccess: onClose });
  };

  return (
    <ModalComposer title='EDIT UNIT' isOpen={isOpen} onClose={onClose}>
      <UpsertUnitForm
        unit={unit}
        onSubmit={handleOnSubmit}
        onClose={onClose}
        isSubmitting={isLoading}
      />
    </ModalComposer>
  );
};

export default UpdateUnitModal;
