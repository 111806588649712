import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { Stack, Typography } from '@mui/material';
import { Optional, Title } from '../../../../../../../components';
import { useErrorContext } from '../../../../../../../context';
import { useRecipeActions, useRecipeStore } from '../../../../../../../store';

const NumberOfPeople = () => {
  const { increaseNumberOfPeople, decreaseNumberOfPeople } = useRecipeActions();
  const recipe = useRecipeStore.use.recipe();
  const { error } = useErrorContext();

  return (
    <Stack direction='column' gap={1}>
      <Title title='Serving' />
      <Stack direction='row' gap={1} alignItems='center' justifyContent='center'>
        <IndeterminateCheckBoxIcon
          onClick={decreaseNumberOfPeople}
          sx={{ cursor: 'pointer', color: '#000' }}
        />
        <Typography>{recipe?.maxNumberOfPeople}</Typography>
        <AddBoxIcon onClick={increaseNumberOfPeople} sx={{ cursor: 'pointer', color: '#000' }} />
      </Stack>
      <Optional condition={error.get('maxNumberOfPeople-maxNumberOfPeople')}>
        <Typography color='error'>{error.get('maxNumberOfPeople-maxNumberOfPeople')}</Typography>
      </Optional>
    </Stack>
  );
};

export default NumberOfPeople;
