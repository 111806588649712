import { Stack } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

const MealTypeCardBase: React.FC<Props> = ({ children }) => {
  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      sx={{
        border: '0.5px solid #000',
        width: '240px',
        height: '120px',
        cursor: 'pointer',
        borderRadius: '2px',
      }}
    >
      {children}
    </Stack>
  );
};
export default MealTypeCardBase;
