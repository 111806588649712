import { createId } from '@paralleldrive/cuid2';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useErrorContext } from '../../../../../../../context';
import { createErrorMap } from '../../../../../../../shared';
import { useRecipeStore } from '../../../../../../../store';
import { RecipeGeneralPhaseRequest } from '../../../../../../types';
import { useCreateGeneral } from '../mutations';

const generalStepSchema = z.object({
  name: z.string().min(3, { message: 'Name must be at least 3 characters long' }),
  nameDe: z.string().min(3, { message: 'Name must be at least 3 characters long' }),
  cookingTime: z.number().min(1, { message: 'Cooking time must be provided' }),
  imageUri: z.string().optional(),
  categories: z.array(z.string()).min(1, {
    message: 'At least one category must be set',
  }),
  complexity: z.string().min(1, { message: 'Complexity must be set' }),
  mealTypes: z.array(z.string()).min(1, {
    message: 'At least one meal type must be set',
  }),
});

export const useGeneralPhaseSubmit = () => {
  const { handleCreateGeneral, isLoading } = useCreateGeneral();
  const { setError, resetError } = useErrorContext();
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get('recipeId');
  const recipe = useRecipeStore.use.recipe();

  const handleSubmitGeneral = async () => {
    const data: RecipeGeneralPhaseRequest = {
      ...recipe,
      recipeId: recipeId || '',
      categories: recipe.categories.map((category) => category.id),
      complexity: recipe.complexity.id,
      mealTypes: recipe.mealTypes.map((meal) => meal.id),
    };
    const formData = new FormData();

    if (recipe.file) {
      const uniqueNameId = createId();
      formData.append('file', recipe.file, uniqueNameId);
    }
    formData.append('recipeId', data?.recipeId || '');
    formData.append('name', data.name);
    formData.append('nameDe', data.nameDe);
    formData.append('description', data.description);
    formData.append('descriptionDe', data.descriptionDe);
    formData.append('cookingTime', data.cookingTime.toString());
    formData.append('categories', JSON.stringify(data.categories));
    formData.append('complexity', data.complexity);
    formData.append('mealTypes', JSON.stringify(data.mealTypes));

    const validation = generalStepSchema.safeParse(data);

    if (!validation.success) {
      const errors = createErrorMap(validation.error);
      setError(errors);
      return;
    }

    await handleCreateGeneral(formData);
    resetError();
  };

  return {
    handler: handleSubmitGeneral,
    isLoading,
  };
};
