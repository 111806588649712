import { useRecipeStore } from '../../../../../../../store';

export const useNutrientValidation = () => {
  const nutrients = useRecipeStore.use.nutrients();
  const init = useRecipeStore.use.initialNutrients();

  return Object.keys(nutrients).some((nutrient) => {
    return nutrients[nutrient as keyof typeof nutrients] !== init[nutrient as keyof typeof init];
  });
};
