import { Stack } from '@mui/material';
import { KQNTextField, TextInputAndormnet, Title } from '../../../../../../../../../../components';
import { useMealActions } from '../../../../../../../../../../store/meal-store';
import { Duration } from '../../../../../../../../../types';
import { burnersSortingOrders } from '../../../../../../../../helpers';
import { shorterBurnerName } from './helpers/shorter-burner-name';

type Props = {
  title: string;
  mealId: string;
  stepId: string;
  durations?: Duration[];
  isDisabled?: boolean;
};

const BurnerTimer: React.FC<Props> = ({ title, mealId, stepId, durations, isDisabled }) => {
  const { updateDurations } = useMealActions();

  const handleUpdateDurationValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    identifier: string,
  ) => {
    if (e.target.value.startsWith('-') || Number(e.target.value) < 0) return;

    const newDurations = durations?.map((duration) => {
      if (duration.id === identifier) {
        return {
          ...duration,
          duration: Number(e.target.value),
        };
      }

      return duration;
    });

    if (!newDurations) return;

    updateDurations(mealId, stepId, newDurations);
  };

  if (!durations) return null;

  return (
    <Stack direction='row' gap={2} sx={{ width: '100%' }} justifyContent='start'>
      <Stack direction='column' gap={1}>
        <Title title={title} />
        <Stack direction='row' gap={2}>
          {durations
            .slice()
            ?.sort(
              (a, b) =>
                burnersSortingOrders[a.name as keyof typeof burnersSortingOrders] -
                burnersSortingOrders[b.name as keyof typeof burnersSortingOrders],
            )
            .map((duration) => (
              <KQNTextField
                disabled={isDisabled}
                key={duration.id}
                id={duration.name}
                type='number'
                size='small'
                variant='outlined'
                placeholder='0'
                data-testid='grilling-time-input'
                required={duration.name === 'Medium'}
                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                InputProps={{
                  startAdornment: (
                    <TextInputAndormnet
                      text={shorterBurnerName(duration.name as keyof typeof shorterBurnerName)}
                      position='start'
                    />
                  ),
                  sx: { maxWidth: '120px' },
                }}
                value={duration.duration || ''}
                onChange={(e) => handleUpdateDurationValue(e, duration.id)}
              />
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BurnerTimer;
