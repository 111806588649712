import { z } from 'zod';

export const updateUserSchema = z.object({
  firstName: z.string().min(2, 'First name must be at least 2 characters.'),
  lastName: z.string().min(2, 'Last name must be at least 2 characters.'),
  email: z.string().email('Invalid email address.'),
  role: z
    .enum(['ADMIN', 'USER', 'CUSTOMER', 'CONTENT MANAGER', 'TESTER', 'TECHNICIAN'])
    .optional()
    .or(z.literal('')),
});

export type UserUpdateSchemaType = z.infer<typeof updateUserSchema>;
