import EditIcon from '@mui/icons-material/Edit';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../../../../../../../../../../../shared';
import { Meal } from '../../../../../../../../../../../types';
import { calculateTotalDuration } from '../../helpers';
import MealComposer from '../../meal-composer';

interface Props {
  meal: Meal;
  recipeId: string;
  ordinal: number;
  isActive: boolean;
  onClick?: (stepId: string) => void;
}

// TODO: Fix propagation on composer
const MealCard: React.FC<Props> = ({ isActive, meal, ordinal, recipeId, onClick }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    id,
    steps,
    mealType: { iconUri },
    timeOffset,
    title,
  } = meal;

  const handleOpenComposer = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (isOpen) return;

    onClick?.(id);
  };

  return (
    <Stack
      data-testid='meal-type-step-card'
      direction='column'
      justifyContent='space-between'
      onClick={handleOnClick}
      sx={{
        width: '240px',
        height: '120px',
        cursor: 'pointer',
        boxShadow: isActive
          ? '0px 1px 3px 0px rgba(0, 0, 0, 0.12) inset, 0px 1px 1px 0px rgba(0, 0, 0, 0.14) inset,0px 2px 1px -1px rgba(0, 0, 0, 0.20) inset'
          : '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
      }}
    >
      <Stack direction='row' sx={{ p: 1 }} justifyContent='space-between'>
        <Stack direction='row' gap={1} justifyContent='flex-start'>
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{ bgcolor: 'kqn.gray', borderRadius: '4px', p: 0.5 }}
          >
            <img src={iconUri} alt={title} className='meal-icon' />
          </Stack>
          <Typography variant='body1'>{title}</Typography>
        </Stack>
        <EditIcon className='icon' onClick={handleOpenComposer} />
      </Stack>
      <Stack
        sx={{
          color: '#000',
          bgcolor: colors[ordinal],
        }}
        direction='row'
        justifyContent='space-evenly'
        alignItems='end'
      >
        <Stack>MEAL {ordinal + 1}</Stack>
        <Stack direction='column' alignItems='center'>
          <Typography variant='caption'>Start offset, s</Typography>
          {timeOffset}
        </Stack>
        <Stack direction='column' alignItems='center'>
          <Typography variant='caption'>Duration, s</Typography>
          {calculateTotalDuration(steps)}
        </Stack>
      </Stack>
      <MealComposer
        recipeId={recipeId}
        meal={meal}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </Stack>
  );
};

export default MealCard;
