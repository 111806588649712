import { z } from 'zod';

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.,]).{8,}$/;

export const resetPasswordSchema = z.object({
  password: z
    .string()
    .min(
      8,
      'Password must have at least 8 characters, one uppercase, one lowercase, one number and one special character.',
    )
    .regex(passwordRegex),
  confirmPassword: z
    .string()
    .min(
      8,
      'Password must have at least 8 characters, one uppercase, one lowercase, one number and one special character.',
    )
    .regex(passwordRegex),
});

export type ForgotPasswordSchemaType = z.infer<typeof resetPasswordSchema>;
